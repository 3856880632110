import SignupServiceProvider from 'components/Login/signupForm/SignupServiceProvider';
import OptionsMenu from 'GlobalComponents/Containers/OptionsMenu/OptionsMenu';
import * as React from 'react';
import ForumComments from '../ForumComments/ForumComments';

interface IProps {
    content: any
    addComment: Function
}

interface IState {
    picture: any
    showComments: boolean
}

class TextForumPost extends React.Component<IProps, IState> {

    commentRef: React.RefObject<HTMLTextAreaElement> | undefined;

    state = {
        picture: "",
        showComments: false
    }

    componentDidMount = () => {
        SignupServiceProvider.getPictureUser(1).then((e: any) => {
            if (e) {
                const buf = Buffer.from(e.data, 'binary').toString('base64');
                this.setState({ picture: buf })
            }
        })
    }

    getPicture = () => {
        return (
            <img src={"data:image/jpeg;base64," + this.state.picture} alt="No picture" className="PostPicture"></img>
        )
    }

    textChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.target.style.height = "auto";
        e.target.style.height = e.target.scrollHeight + "px";
    }

    renderContent = () => {
        return (
            <React.Fragment>
                {this.props.content.text}
            </React.Fragment>
        )
    }

    public render() {
        return (
            <div className="ForumPostContainer">
                <div className="ForumPostHeader">
                    <div>
                        {this.getPicture()}
                        <div>
                            <div className='name'>{this.props.content.username}</div>
                            <div className='time'>{this.props.content.time}</div>
                        </div>
                    </div>
                    <OptionsMenu></OptionsMenu>
                </div>
                <div className='ForumPostContent'>
                    {this.renderContent()}
                </div>
                <ForumComments {...this.props}></ForumComments>
            </div>
        )
    }
}

export default TextForumPost