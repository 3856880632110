import React from 'react'
import AvatarEditor from 'react-avatar-editor';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import "./ImageUploader.scss";

interface IProps {
    lastPic: any
}

interface IState {
    ref: AvatarEditor
    picture: File | string
    zoom: number
    picEdited: any
}

export default class ImageUploader extends React.Component<IProps, IState> {

    state = {
        picture: "",
        zoom: 100,
        picEdited: "",
        ref: new AvatarEditor({ image: "" })
    }

    private handleChange = (event: any) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value } as Pick<IState, keyof IState>);

        this.changeEditedPic();
    }

    componentDidMount = () => {
        if (this.props.lastPic !== "") {
            this.setState({ picEdited: this.props.lastPic, picture: this.props.lastPic });
        }
    }

    private changeEditedPic = () => {
        if (this.state.ref) {
            const canvasScaled = this.state.ref.getImageScaledToCanvas()
            let img = canvasScaled.toDataURL("image/jpeg")
            this.setState({ picEdited: img })
        }
    }

    private onImageChange = (e: any) => {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
            console.log('no files');
        }

        this.resizeImage(files[0], 100, 100).then((blob: Blob) => {
            this.setState({
                picture: files[0]
            });
        })

        this.changeEditedPic();
    };

    private resizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<Blob> => {
        return new Promise((resolve, reject) => {
            let image = new Image();
            image.src = URL.createObjectURL(file);
            image.onload = () => {
                let width = image.width;
                let height = image.height;

                if (width <= maxWidth && height <= maxHeight) {
                    resolve(file);
                }

                let newWidth;
                let newHeight;

                if (width > height) {
                    newHeight = height * (maxWidth / width);
                    newWidth = maxWidth;
                } else {
                    newWidth = width * (maxHeight / height);
                    newHeight = maxHeight;
                }

                let canvas = document.createElement('canvas');
                canvas.width = newWidth;
                canvas.height = newHeight;

                let context = canvas.getContext('2d');

                if (context != null) {
                    context.drawImage(image, 0, 0, newWidth, newHeight);
                    canvas.toBlob(resolve as BlobCallback, file.type);
                }
            };
            image.onerror = reject;
        });
    }

    public getImage: any = () => {
        return this.state.picEdited
    }

    setEditorRef = (editor: any) => this.setState({ ref: editor })

    public render() {
        return (
            <React.Fragment>
                <AvatarEditor ref={this.setEditorRef} className="image__editor" image={this.state.picture ? this.state.picture : ""} width={200} height={200} border={20} borderRadius={100} scale={this.state.zoom ? this.state.zoom / 100 : 1} onPositionChange={this.changeEditedPic}></AvatarEditor>
                <input type="range" min="100" max="200" value={this.state.zoom ? this.state.zoom : 100} name="zoom" className="slider" id="myRange" onChange={this.handleChange}></input>
                <label htmlFor="image" className="file__upload"><ImageLoader name="FolderIcon" className="file__upload__icon"></ImageLoader><Translations value={Dictionary.chooseAPicture}></Translations></label>
                <input type="file" accept=".png, .jpeg" id="image" className="file__upload__input" name="profilePic" onChange={this.onImageChange} />
            </React.Fragment>
        )
    }
}