/*eslint no-template-curly-in-string: "off"*/
let translationEN: any =
    [
        "Email",
        "Password",
        "Login",
        "Forgot account ?",
        "Language",
        "Syndicate",
        "Create a new co-owner syndicate",
        "Your syndicates",
        "News Feed",
        "Home",
        "Calendar",
        "Logout",
        "Creating your syndicate",
        "Cancel",
        "What's the syndicates name ?",
        "*Please not that once the syndicate will be created, you will become the super administrator",
        "Syndicate name",
        "Next step",
        "Please enter ${0} infos",
        "Back",
        "Enter your email here",
        "Confirmation sent !",
        "An e-mail has been sent to ${0}. Click on the link attached to confirm your identity",
        "Send again",
        "Do you want to complete your profile ?",
        "You can always do it later",
        "Finish",
        "Yes",
        "No",
        "Let's continue",
        "Please enter the syndicate adress",
        "Street address",
        "Apartment number",
        "City",
        "State",
        "Postal code",
        "Country",
        "Please drag a profile picture here or find it in your PC",
        "Add admins to the syndicate",
        "How many members are in the administration team excluding yourself ?",
        "Send invitations to the syndicate members by inserting their e-mail address here:",
        "Enter the number of members here",
        "Member number ${0} E-mail",
        "Introduce yourself",
        "What's your name ?",
        "First name",
        "Last name",
        "Hi ${0} ${1}",
        "Please enter your e-mail",
        "Please chose a password",
        "Between 8 and 16 character",
        "End",
        "To dashboard",
        "Please enter your phone number",
        "Enter your phone number here",
        "Almost over",
        "Please enter your address",
        "Management",
        "Accounting",
        "Communications",
        "Reservations",
        "Documents",
        "Invite users to join ${0} syndicate !",
        "Syndicate info",
        "Manage your employees",
        "Individual invite",
        "Group invite",
        "Share syndicate access key",
        "Manage list of co-owners",
        "Add employee",
        "Manage list of employees",
        "Edit administrators list",
        "Send",
        "Enter a group of emails here, separated by : ",
        "Create property",
        "The email/password you entered is invalid",
        "Emergency contact",
        "Please add a person we could contact in case of emergency",
        "Type of property",
        "Condo",
        "Apartment",
        "Share ${0} key",
        "Fetching the key...",
        "Locations",
        "Please select a location to the left to see its corresponding reservations !",
        "Description",
        "Name",
        "Location",
        "Create amenity",
        "Corporation number",
        "Sending...",
        "Done !",
        "Copy",
        "Copying...",
        "Copied !",
        "Email",
        "Password",
        "Person's name",
        "Person's phone number",
        "Creating your account ...",
        "Choose an image to upload",
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "save",
        "Edit reservation",
        "Delete",
        "Add recurring event",
        "Existing recurring events",
        "Dont have an account ?",
        "Get started fast and create one here",
        "Sign Up",
        "Join an already existing syndicate",
        "Create new unit",
        "Unit number",
        "Voting percentage (%)",
        "Create unit",
        "Sending",
        "Created",
        "Admin",
        "Owner",
        "Occupant",
        "Employee",
        "None",
        "User role",
        "Private",
        "Rental",
        "Drag files here to upload them",
        "Upload file(s)...",
        "First name",
        "Last name",
        "Unused",
        "Sent",
        "Error",
        "Not sent",
        "In use",
        "Voting percent",
        "Close",
        "Forgot your password ?",
        "Enter your email down below and we will send you a password reset link",
        "Reset password",
        "reset link sent successfully",
        "There was an error when sending the invite",
        "This email is already in use",
        "The invite was sent successfully",
        "Help desk",
        "low",
        "medium",
        "high",
        "urgent",
        "public",
        "priority",
        "all",
        "amenity",
        "open",
        "on hold",
        "in progress",
        "closed",
        "invalid",
        "status",
        "Recurring",
        "Exceptions"
    ]

export default translationEN