import iCondo from 'Models/Condo';
import * as React from 'react';
import Activities from 'Ressources/Activities';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

// import Swiper core and required modules
import SwiperCore, {
    Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination]);

interface IProps {
    changeActivity: Function
    condoList: iCondo[]
    yourSyndicateClicked: Function
    condoItem: Function
}

interface IState {
}

class BaseOptions_Phone extends React.Component<IProps, IState> {

    public render() {
        return (
            <div className="content">
                <Swiper slidesPerView={1} spaceBetween={30} loop={true} pagination={{
                    "clickable": true
                }} className="swiper">
                    <SwiperSlide>
                        <div className="Your_syndicates_container">
                            <h3><Translations value={Dictionary.yourSyndicates}></Translations></h3>
                            <hr className="divider_solid"></hr>
                            {this.props.condoList.map((condo: iCondo, index: number) => (
                                <div key={condo.id} className="condo_item_container" onClick={() => this.props.yourSyndicateClicked(condo)}>{this.props.condoItem(condo, index)}</div>
                            ))}
                        </div>
                    </SwiperSlide >
                    <SwiperSlide>
                        <div className="Create_syndicate_container">
                            <button className="Syndicate_button" onClick={() => this.props.changeActivity(Activities.CreateSyndicate)}>
                                <h3><Translations value={Dictionary.createNewCoownerSyndicate}></Translations></h3>
                                <hr className="divider_solid"></hr>
                                <div className="condo_add_container">
                                    <ImageLoader name="PlusIcon" className="icon_plus"></ImageLoader>
                                    <ImageLoader name="CondoIcon" className="icon_condo"></ImageLoader>
                                </div>
                            </button>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='Join_syndicate_container'>
                            <button className="Syndicate_button" onClick={() => this.props.changeActivity(Activities.CreateSyndicate)}>
                                <h3><Translations value={Dictionary.joinExistingSyndicate}></Translations></h3>
                                <hr className="divider_solid"></hr>
                                <div className="condo_add_container">
                                    <ImageLoader name="PlusIcon" className="icon_plus"></ImageLoader>
                                    <ImageLoader name="CondoIcon" className="icon_condo"></ImageLoader>
                                </div>
                            </button>
                        </div>
                    </SwiperSlide>
                </Swiper >
            </div>
        )
    }
}

export default BaseOptions_Phone