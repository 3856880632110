import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import "./DeleteButton.scss"

interface IProps {
    onClick: any
    width?: string
}

interface IState {
    buttonState: iButtonState
}

enum iButtonState {
    base,
    loading,
    done,
}

class DeleteButton extends React.Component<IProps, IState> {

    state = {
        buttonState: iButtonState.base
    }

    private onClick = () => {
        this.setState({buttonState: iButtonState.loading});
        this.props.onClick().then((res: any) => {
            this.setState({buttonState: iButtonState.done});
        })
    }

    private onHover = () => {
        if(this.state.buttonState === iButtonState.done){
            this.setState({buttonState: iButtonState.base});
        }
    }

    private buttonText = () => {
        // switch(this.state.buttonState){
        //     case iButtonState.base:
        //         return(
        //             <Translations value={this.props.basicText}></Translations>
        //         )
        //     case iButtonState.loading:
        //         return(
        //             <Translations value={this.props.loadingText}></Translations>
        //         )
        //     case iButtonState.done:
        //         return(
        //             <Translations value={this.props.doneText}></Translations>
        //         )            
        // }
    }

    public render() {
        let width = this.props.width ? this.props.width : "40%"
        return (
            <div className="deleteButton" onClick={this.onClick}>
                <span><Translations value={Dictionary.delete}/></span>
                <div className="icon">
                    <svg className="deleteIcon" viewBox="0 0 20 20">
                        <path fill="#000000" d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
                    </svg>
                    {/* <ImageLoader name="PlusIcon" className="fa fa-remove"/> */}
                    {/* <i className="fa fa-remove"></i> */}
                    <i className="fa fa-check"></i>
                </div>
            </div>
        )
    }
}

export default DeleteButton


{/* <div className="promiseBtn" onClick={this.onClick} style={{width:width}} onMouseEnter={this.onHover}><span>{this.buttonText()}</span></div> */}