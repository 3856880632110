import PromiseButton from 'GlobalComponents/Buttons/PromiseButton';
import DynamicInput from 'GlobalComponents/Inputs/DynamicInput';
import iCondo from 'Models/Condo';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from '../../../../Ressources/translations/Translations';
import { pageStates } from '../../SyndicatePage';

interface IProps {
    changeState: any
    handleChange: any
    currentCondo: iCondo
    email: string
    sendInvite: Function
}

interface IState {
}

class SyndicateAddEmployee_PC extends React.Component<IProps, IState> {

    public render() {
        return (
            <div className="subContainer">
                <div className="options big">
                    <span><Translations value={Dictionary.inviteUsersJoinSyndicate} var={[this.props.currentCondo.name]}></Translations></span>
                    <div className="horizontalDivider full"></div>
                    <div className="subOptions">
                        <div className="options input">
                            <div className="title">
                                <ImageLoader name="AddEmployeeIcon" className="icon"></ImageLoader>
                                <span><Translations value={Dictionary.addEmployee} var={[this.props.currentCondo.name]}></Translations></span>
                            </div>
                            <div className="inputContainer">
                                <DynamicInput inputName={Dictionary.EnterTheEmailHere} type="email" handleChange={this.props.handleChange} value={this.props.email} inputStateName={"email"}></DynamicInput>
                                <PromiseButton onClick={this.props.sendInvite} basicText={Dictionary.send} loadingText={Dictionary.SendingEmail} doneText={Dictionary.Done}></PromiseButton>
                            </div>
                        </div>
                        <div className="verticalDivider"></div>
                        <div className="options navigation">
                            <div className="option clickable" onClick={() => this.props.changeState(pageStates.manageListEmployees)}>
                                <ImageLoader name="EmployeeListIcon" className="icon"></ImageLoader>
                                <span><Translations value={Dictionary.manageListEmployees}></Translations></span>
                            </div>
                            <div className="horizontalDivider"></div>
                            <div className="option clickable" onClick={() => this.props.changeState(pageStates.manageListAdmin)}>
                                <ImageLoader name="EditUserIcon" className="icon"></ImageLoader>
                                <span><Translations value={Dictionary.editAdminList}></Translations></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SyndicateAddEmployee_PC