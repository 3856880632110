import { Box, Tab } from '@mui/material';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { iReservation } from 'Models/Reservation';
import * as React from 'react';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import DynamicRadioInput, { iRadioData } from 'GlobalComponents/Inputs/DynamicRadioInput';

export interface IAvailabilityPopupContentProps {
    curRes: iReservation
    title: Dictionary
    editRes: Function
}

export interface IAvailabilityPopupContentState {
    currentTab: string
    isAvailable: boolean
}

export default class AvailabilityPopupContent extends React.Component<IAvailabilityPopupContentProps, IAvailabilityPopupContentState> {
    constructor(props: IAvailabilityPopupContentProps) {
        super(props);

        this.state = {
            currentTab: '0',
            isAvailable: true
        }
    }

    private editRes = (e: any, startTime: boolean) => {
        this.props.editRes(e, startTime);
    }

    private ChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        this.setState({ currentTab: newValue });
    }

    public render() {
        let radioInputs: iRadioData[] = [
            {
                value: "available",
                checked: this.state.isAvailable,
                text: "available",
                onChange: () => this.setState({ isAvailable: true })
            },
            {
                value: "unavailable",
                checked: !this.state.isAvailable,
                text: "unavailable",
                onChange: () => this.setState({ isAvailable: false })
            }
        ]

        let startTime = this.props.curRes.startTime.getHours().toString().padStart(2, "0") + ":" + this.props.curRes.startTime.getMinutes().toString().padStart(2, "0");
        let endTime = this.props.curRes.endTime.getHours().toString().padStart(2, "0") + ":" + this.props.curRes.endTime.getMinutes().toString().padStart(2, "0");
        return (
            <div>
                <Box>
                    <TabContext value={this.state.currentTab}>
                        <Box>
                            <TabList onChange={this.ChangeTab}>
                                <Tab label={<Translations value={Dictionary.recurringAvailabilities} />} value="0" />
                                <Tab label={<Translations value={Dictionary.availabilityExceptions} />} value="1" />
                            </TabList>
                        </Box>
                        <TabPanel value="0">
                            Create an availability from
                            <input type="time" onChange={(e: any) => this.editRes(e, true)} value={startTime} step={900} required></input>
                            to
                            <input type="time" onChange={(e: any) => this.editRes(e, false)} value={endTime} step={900} required></input>
                        </TabPanel>
                        <TabPanel value="1">
                            <DynamicRadioInput name='AvailabilityRadioInput' value={radioInputs} />
                            From
                            <input type="time" onChange={(e: any) => this.editRes(e, true)} value={startTime} step={900} required></input>
                            to
                            <input type="time" onChange={(e: any) => this.editRes(e, false)} value={endTime} step={900} required></input>
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>
        )
    }
}
