export interface iUser {
    email: string,
    first_name: string,
    last_name: string,
    telephone: string,
    time_zone_utc?: string,
    language?: string,
    password: string,
    property_name?: string,
    corp_number?: string,
    street_number?: number,
    street_name?: string,
    city?: string,
    province?: string,
    country?: string,
    property_telephone?: string,
    property_type?: number
}

export interface iBaseUser {
    email: string,
    first_name: string,
    last_name: string,
    telephone: string,
    password: string,
}

export interface iCompleteBaseUser {
    key: string,
    first_name: string,
    last_name: string,
    telephone: string,
    password: string,
}

export interface iAdmin {
    user: {
        first_name: string,
        id: number,
        last_name: string
    }
}

export enum UserType {
    none = "none",
    unit = "unit",
    occupant = "occupant",
    employee = "employee",
    admin = "admin"
}