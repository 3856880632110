import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import * as React from 'react';
import { Dictionary, Translations } from 'Ressources/translations/Translations';

export interface ISelectMultipleWrapperProps {
    name: string
    options: any
    optionsText: Dictionary[]
    label: Dictionary
    handleChange: Function
    minWidth?: string
}

export interface ISelectMultipleWrapperState {
    value: any
}

export default class SelectMultipleWrapper extends React.Component<ISelectMultipleWrapperProps, ISelectMultipleWrapperState> {
    constructor(props: ISelectMultipleWrapperProps) {
        super(props);

        this.state = {
            value: []
        }
    }

    private handleChange = (event: any) => {
        this.setState({ value: event.target.value });
        this.props.handleChange(event.target.value);
    };

    private GetMenuItems = (options: any) => {
        let keys = Object.keys(options).filter((v: any) => isNaN(Number(v))) as Array<keyof typeof options>;
        let menuItems: JSX.Element[] = [];

        keys.map((key: any, i: number) => {
            menuItems.push(
                <MenuItem value={options[key]}><Translations value={this.props.optionsText[i]} /></MenuItem>
            )

        })

        return (menuItems)
    }

    public render() {
        let options = this.props.options;
        return (
            <FormControl size="medium" sx={{ m: 1, minWidth: this.props.minWidth ?? 200, width: this.props.minWidth ?? 200 }}>
                <InputLabel id={this.props.name + "label"}><Translations value={this.props.label} /></InputLabel>
                <Select
                    labelId={this.props.name + "label"}
                    id={this.props.name}
                    value={this.state.value}
                    multiple
                    label={<Translations value={Dictionary.propertyType} />}
                    onChange={this.handleChange}>
                    {this.GetMenuItems(options)}
                </Select>
            </FormControl>
        );
    }
}