import iCondo from 'Models/Condo';
import * as React from 'react';
import "../../SyndicatePage.scss"
import SyndicateServiceProvider from '../../SyndicateServiceProvider';
import MediaType from 'Ressources/MediaType';
import SyndicateIndividualInvite_Phone from './SyndicateIndividualInvite_Phone';
import SyndicateIndividualInvite_PC from './SyndicateIndividualInvite_PC';
import { UserType } from 'Models/User';
import { SelectChangeEvent } from '@mui/material';
import { iEmailMassInvite, iEmailMassInviteWithRole } from 'Models/EmailMassInvite';

interface IProps {
    handleChange: any
    changeState: any
    email: string
    currentCondo: iCondo
    mediaType: MediaType
}

interface IState {
    selectedCondo: any
    popupVisible: boolean
    userType: UserType
    unitNumber: string
}

class SyndicateIndividualInvite extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            selectedCondo: undefined,
            popupVisible: false,
            userType: UserType.none,
            unitNumber: "",
        }
    }

    private sendInvite = () => {
        if (this.state.userType == UserType.occupant || this.state.userType == UserType.unit) {
            let body: iEmailMassInvite = {
                syndicate_id: this.props.currentCondo.id,
                invites: [{ email: this.props.email, first_name: "", last_name: "", unit: this.state.unitNumber, voting_percent: "0" }]
            }
            return SyndicateServiceProvider.SendMassEmails(body).then((res: any) => {
                console.log(res);
            })
        } else {
            let body: iEmailMassInviteWithRole = {
                syndicate_id: this.props.currentCondo.id,
                role: this.state.userType,
                invites: [{ email: this.props.email, first_name: "", last_name: "" }]
            }
            return SyndicateServiceProvider.SendMassEmails(body);
        }
    }

    private HandleChangeUserType = (event: SelectChangeEvent) => {
        switch (event.target.value) {
            case UserType[UserType.admin]:
                this.setState({ userType: UserType.admin });
                break;
            case UserType[UserType.employee]:
                this.setState({ userType: UserType.employee });
                break;
            case UserType[UserType.occupant]:
                this.setState({ userType: UserType.occupant });
                break;
            case UserType[UserType.unit]:
                this.setState({ userType: UserType.unit });
                break;
            default:
                this.setState({ userType: UserType.none });
                break;
        }
    };

    private HandleChangeUnitNumber = (event: SelectChangeEvent) => {
        this.setState({ unitNumber: event.target.value });
    };

    private changeSelectedCondo = (condo: any) => {
        this.setState({ selectedCondo: condo.value[0] })
    }

    private addUnit = (condo: any) => {
        this.setState({ popupVisible: true })
        console.log(condo)
    }

    private closePopup = () => {
        this.setState({ popupVisible: false });
    }

    public render() {
        if (this.props.mediaType === MediaType.Phone) {
            return (
                <SyndicateIndividualInvite_Phone sendInvite={this.sendInvite} {...this.props} {...this.state}></SyndicateIndividualInvite_Phone>
            )
        }
        if (this.props.mediaType === MediaType.PC || this.props.mediaType === MediaType.Tablet) {
            return (
                <SyndicateIndividualInvite_PC sendInvite={this.sendInvite} closePopup={this.closePopup} addUnit={this.addUnit} changeSelectedCondo={this.changeSelectedCondo}
                    HandleChangeUserType={this.HandleChangeUserType} HandleChangeUnitNumber={this.HandleChangeUnitNumber} {...this.props} {...this.state}></SyndicateIndividualInvite_PC>
            )

        }
    }
}

export default SyndicateIndividualInvite