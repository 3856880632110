import axios from "axios";
import LoginProvider from "GlobalComponents/ServiceProvider";
import { iBaseUser, iCompleteBaseUser } from "../../../Models/User";

const path = 'https://connect.smartcondomanager.com/';//process.env.NODE_ENV !== 'production' ? 'https://connect.smartcondomanager.com/' : "/";

const SignupServiceProvider = {
    checkEmail: async function (email: string) {
        return axios.get(path + 'api/check-user/' + email, {
            headers: {
                "Cache-Control": "no-store"
            }
        }).catch(function (error: any) {
            console.log(error);
        });
    },
    checkPassword: async function (password: string) {
        let body = {
            "password": password
        }
        return axios.post(path + 'api/password-strength', body, {
            headers: {
                "Cache-Control": "no-store"
            }
        }).catch(function (error: any) {
            console.log(error);
        });
    },
    checkPhone: async function (phone: string) {
        return axios.get(path + 'api/validate-phone/' + phone, {
            headers: {
                "Cache-Control": "no-store"
            }
        }).catch(function (error: any) {
            console.log(error);
        });
    },
    createUser: async function (user: iBaseUser) {
        return axios.post(path + "api/signup-user", user, {
            headers: {
                'Cache-Control': "no-store",
                'Content-Type': 'application/json'
            }
        }).catch(function (error: any) {
            console.log(error);
        });
    },
    completeUserCreation: async function (user: iCompleteBaseUser) {
        return axios.post(path + "api/complete-email-invite", user, {
            headers: {
                'Cache-Control': "no-store",
                'Content-Type': 'application/json'
            }
        }).catch(function (error: any) {
            console.log(error);
        });
    },
    verifyEmailChecked: async function () {
        return axios.get(path + "api/check-verified", {
            withCredentials: false,
            headers: {
                "Cache-Control": "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            },
        }).catch(function (error: any) {
            console.log(error);
        });
    },
    sendImage: async function (file: File) {
        return axios.post(path + "api/set-picture/" + localStorage.getItem('username'), "image:" + file, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                'Content-Type': 'text/plain',
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            },
            responseType: "arraybuffer"
        }).catch(function (error: any) {
            console.log(error);
        });
    },
    sendImageWithKey: async function (file: File, token: string) {
        return axios.post(path + "api/set-picture/" + token, "image:" + file, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                'Content-Type': 'text/plain',
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            },
            responseType: "arraybuffer"
        }).catch(function (error: any) {
            console.log(error);
        });
    },

    imageTest: async function (file: File) {
        return axios.post(path + "api/test-picture", "image:" + file, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                'Content-Type': "text/plain",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            },
            responseType: "arraybuffer"
        }).catch(function (error: any) {
            console.log(error);
        });
    },

    getPictureEmail: async function (email: string) {
        if (email === "") {
            return false;
        }

        return axios.post(path + "api/get-picture", {
            email: email,
        }, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            },
            responseType: "arraybuffer"
        }).catch(function (error: any) {
            console.log(error);
        });
    },

    getPictureProperty: async function (propertyName: string) {
        if (propertyName === "") {
            return false;
        }

        return axios.post(path + "api/get-picture", {
            property_name: propertyName,
        }, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            },
            responseType: "arraybuffer"
        }).catch(function (error: any) {
            console.log(error);
        });
    },

    getPictureUser: async function (userId: number) {
        if (userId === -1) {
            return false;
        }

        return axios.post(path + "api/get-picture", {
            user_id: userId
        }, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            },
            responseType: "arraybuffer"
        }).catch(function (error: any) {
            console.log(error);
        });
    },

    GetFullNameFromToken: async function (token: string) {
        return axios.post(path + "api/get-name-from-token", {
            "key": token
        }, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch(function (error: any) {
            console.log(error);
        });
    }
}

export default SignupServiceProvider