enum ErrorKeys {
    emailAlreadyInUse,
    passwordInvalid,
    couldNotCreateAccount,
    length,
    number,
    special,
    phoneInvalid,
    onlyOneFileAllowed,
    onlyExcelFilesAllowed,
    syndicateNameAlreadyExists,
}

export default ErrorKeys