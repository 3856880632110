import ArrowButton from 'GlobalComponents/Buttons/ArrowButton';
import PromiseButton from 'GlobalComponents/Buttons/PromiseButton';
import LabeledArea from 'GlobalComponents/Containers/LabeledArea/LabeledArea';
import DynamicInput from 'GlobalComponents/Inputs/DynamicInput';
import { Size } from 'GlobalComponents/Inputs/DynamicRadioInput';
import iCondo from 'Models/Condo';
import * as React from 'react';
import { Button } from 'reactstrap';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import "./CreateUnit.scss";
import UnitsProvider from './UnitsProvider';
export interface ICreateUnitProps {
    GetUnits: Function
    currentCondo: iCondo
}

export interface ICreateUnitState {
    unitNumber: string,
    votingPercent: number
    visible: boolean
}

export default class CreateUnit extends React.Component<ICreateUnitProps, ICreateUnitState> {
    constructor(props: ICreateUnitProps) {
        super(props);

        this.state = {
            unitNumber: "",
            votingPercent: 0,
            visible: false,
        }
    }

    private handleChange = (event: any) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value } as Pick<ICreateUnitState, keyof ICreateUnitState>);
    }

    private createUnit = () => {
        return UnitsProvider.CreateUnit(this.props.currentCondo.id, this.state.unitNumber, this.state.votingPercent).then((res: any) => {
            this.props.GetUnits();
        })
    }

    private toggleVisible = () => {
        this.setState({ visible: !this.state.visible });
        return Promise.resolve(0);
    }

    private LabelContent = () => {
        return (
            <LabeledArea label={"Create a new unit"} id={"CreateUnitContainer"}>
                <div className='CreateUnitInputs'>
                    <DynamicInput size={Size.small} inputName={Dictionary.UnitNumber} type={"string"} handleChange={this.handleChange} inputStateName='unitNumber' value={this.state.unitNumber}></DynamicInput>
                    <DynamicInput size={Size.small} inputName={Dictionary.VotingPercent} type={"number"} handleChange={this.handleChange} inputStateName='votingPercent' value={this.state.votingPercent}></DynamicInput>
                </div>
                <div className='CreateUnitButtons'>
                    <PromiseButton basicText={Dictionary.close} loadingText={Dictionary.close} doneText={Dictionary.close} onClick={this.toggleVisible}></PromiseButton>
                    <PromiseButton basicText={Dictionary.createUnit} loadingText={Dictionary.sending} doneText={Dictionary.created} onClick={this.createUnit}></PromiseButton>
                </div>
            </LabeledArea>
        )
    }

    public render() {
        return (
            <div className={"CreateUnitLabeledArea" + (this.state.visible ? " OpenContent" : " CloseContent")}>
                {this.state.visible ? this.LabelContent() : <ArrowButton onClick={this.toggleVisible} text={Dictionary.createNewUnit}></ArrowButton>}
            </div >
        )
    }
}
