/*eslint no-template-curly-in-string: "off"*/
import * as React from 'react';
import SettingsContext from '../../GlobalComponents/SettingsContext';
import TranslationEN from './en'
import TranslationFR from './fr'
import { Languages } from '../../GlobalComponents/SettingsContext'

export enum Dictionary {
    email,
    password,
    login,
    forgotAccount,
    language,
    syndicate,
    createNewCoownerSyndicate,
    yourSyndicates,
    newsFeed,
    home,
    calendar,
    logout,
    creatingSyndicate,
    cancel,
    whatSundicateName,
    youWillBecomeSuperAdmin,
    enterSyndicateNameHere,
    nextStep,
    pleaseEnterEmail,
    back,
    enterEmailHere,
    confirmationSent,
    emailHasBeenSentTo,
    sendAgain,
    doYouWantToCompleteProfile,
    youCanDoItLater,
    finish,
    yes,
    no,
    letsContinue,
    enterSyndicateAddress,
    enterStreetAddress,
    enterAppNumber,
    enterCity,
    enterState,
    enterPostalCode,
    enterCountry,
    pleaseDragProfilePic,
    addTheSyndicateMembers,
    howManyMemberInAdmin,
    sendInvitationsSyndicateMembers,
    enterNumberMembersHere,
    memberNumberXEmail,
    introduceYourself,
    whatsYourName,
    enterFirstNameHere,
    enterLastNameHere,
    hiFirstNameLastName,
    pleaseEnterYourEmail,
    pleaseChooseAPassword,
    between816Char,
    end,
    toDashboard,
    pleaseEnterPhone,
    enterYourPhoneHere,
    almostOver,
    pleaseEnterAddress,
    management,
    accounting,
    communications,
    reservations,
    documents,
    inviteUsersJoinSyndicate,
    syndicateInfo,
    manageEmployees,
    individualInvite,
    groupInvite,
    shareSyndicateAccessKey,
    manageListCoowners,
    addEmployee,
    manageListEmployees,
    editAdminList,
    send,
    enterGroupEmailHere,
    createProperty,
    wrongEmailPassword,
    EmergencyContact,
    PleaseAddPersonEmergency,
    propertyType,
    Condo,
    Apartment,
    ShareSyndicateKey,
    FetchingKey,
    Locations,
    PleaseSelectLocation,
    Description,
    Name,
    Location,
    CreateAmenity,
    EnterCorpNum,
    SendingEmail,
    Done,
    Copy,
    Copying,
    Copied,
    EnterTheEmailHere,
    pleaseEnterPassword,
    pleaseEnterEmergencyName,
    pleaseEnterEmergencyPhone,
    creatingYourAccount,
    chooseAPicture,
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    save,
    editReservation,
    delete,
    addRecurringEvent,
    existingRecurringEvents,
    dontHaveAnAccount,
    getStartedFast,
    signUp,
    joinExistingSyndicate,
    createNewUnit,
    UnitNumber,
    VotingPercent,
    createUnit,
    sending,
    created,
    admin,
    owner,
    occupant,
    employee,
    none,
    userRole,
    private,
    rental,
    dragFilesHereToUploadThem,
    uploadFiles,
    firstName,
    lastName,
    unused,
    sent,
    error,
    notSent,
    inUse,
    votingPercent,
    close,
    forgotYourPassword,
    enterEmailHereAndWeWillSendResetLink,
    resetPassword,
    resetLinkSentSuccessfully,
    thereWasErrorWithEmail,
    emailAlreadyInUse,
    invitationSent,
    helpDesk,
    low,
    medium,
    high,
    urgent,
    public,
    priority,
    all,
    amenity,
    open,
    onHold,
    inProgress,
    closed,
    invalid,
    status,
    recurringAvailabilities,
    availabilityExceptions
}

interface IProps {
    value: Dictionary
    var?: string[]
}

interface IState {
    language: Languages
}

export class Translations extends React.Component<IProps, IState> {

    state = {
        language: Languages.EN
    }

    private replaceVars = (retVal: string) => {
        if (this.props.var) {
            this.props.var.map((val: string, i: number) => {
                retVal = retVal.replace("${" + i + "}", this.props.var ? this.props.var[i] : "");
            })
        }
        return retVal
    }

    public getString = () => {
        let retValue = ""
        switch (this.context.language) {
            case Languages.EN:
                retValue = TranslationEN[this.props.value];
                break;
            case Languages.FR:
                retValue = TranslationFR[this.props.value];
                break;
            default:
                break;
        }
        if (this.props.var)
            retValue = this.replaceVars(retValue);

        return retValue;
    }

    public render() {
        return (
            this.getString()
        )
    }
}

Translations.contextType = SettingsContext;
