import * as React from 'react';
import CalendarWeek from '../CalendarWeek/CalendarWeek';
import ImageLoader from 'Ressources/Images/ImageLoader';
import MediaType from 'Ressources/MediaType';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

// import Swiper core and required modules
import SwiperCore, {
    Pagination
} from 'swiper';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import { PopupType } from '../CalendarWeek/CalendarWeek_PC';

// install Swiper modules
SwiperCore.use([Pagination]);

interface IProps {
    selectedAmenity: number
    selectedWeek: Date
    drawLocations: Function
    changeWeek: Function
    mediaType: MediaType
}

interface IState {
}

class Calendar_Phone extends React.Component<IProps, IState> {

    public render() {
        return (
            <div className="CalendarContainer">
                <Swiper slidesPerView={1} spaceBetween={30} loop={true} pagination={{
                    "clickable": true
                }} className="mySwiper">
                    <SwiperSlide>
                        <div className="LocationSelector">
                            <h2><Translations value={Dictionary.Locations}></Translations></h2>
                            {this.props.drawLocations()}
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="CalendarWeek">
                            <React.Fragment>
                                <ImageLoader name="ArrowLeft" className="arrowIcon" onClick={() => { this.props.changeWeek(false) }}></ImageLoader>
                                <CalendarWeek popupContent={PopupType.RESERVATION} timeInterval={30} {...this.props}></CalendarWeek>
                                <ImageLoader name="ArrowLeft" className="arrowIcon" onClick={() => { this.props.changeWeek(true) }}></ImageLoader>
                            </React.Fragment>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        )
    }
}

export default Calendar_Phone