import * as React from 'react';
import ProgressBar from "react-bootstrap/ProgressBar";
import UserTypes from '../../../Ressources/UserTypes';
import SignupServiceProvider from './SignupServiceProvider';
import { iBaseUser, iCompleteBaseUser } from '../../../Models/User';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import ImageUploader from 'GlobalComponents/ImageUploader/ImageUploader';
import DynamicInput, { Size } from 'GlobalComponents/Inputs/DynamicInput';
import { ErrorList } from 'Ressources/translations/Errors/ErrorList';
import ErrorKeys from 'Ressources/translations/Errors/ErrorKeys';
import ArrowButton from 'GlobalComponents/Buttons/ArrowButton';
import PromiseButton from 'GlobalComponents/Buttons/PromiseButton';
// import 'react-widgets/dist/css/react-widgets.css';
import "./UserSignupForm.scss";
import "animate.css";

interface IProps {
    exitForm: Function
    withUserKey?: boolean
}

interface IState {
    currentActivity: Activities
    email: [string, boolean]
    first_name: [string, boolean]
    last_name: [string, boolean]
    telephone: [string, boolean]
    password: [string, boolean]
    errors: ErrorKeys[]
    picture: File
    userKey: string
}

enum Activities {
    Name,
    Email,
    Password,
    Telephone,
    Picture,
    ConfirmEmail,
}

class UserSignupForm extends React.Component<IProps, IState> {

    interval: any;
    imgRef: React.RefObject<ImageUploader>;

    constructor(props: any) {
        super(props);

        this.imgRef = React.createRef();

        if (!this.props.withUserKey) {
            let lastState = JSON.parse(localStorage.getItem('signupState') || '{"currentActivity":null}') as IState;
            if (lastState.currentActivity != null) {
                this.state = lastState;
            } else {
                this.state = ({
                    currentActivity: Activities.Name, first_name: ["", false], last_name: ["", false], email: ["", false],
                    password: ["", false], telephone: ["", false], picture: new File([""], "undefined"), errors: [], userKey: ""
                });
            }
        } else {
            let token = window.location.pathname.replace("/invite/", '');
            SignupServiceProvider.GetFullNameFromToken(token).then((res: any) => {
                if (res && res.data) {
                    this.setState({
                        first_name: [res.data.first_name, false],
                        last_name: [res.data.last_name, false]
                    })
                }
            })

            this.state = ({
                currentActivity: Activities.Name, first_name: ["", false], last_name: ["", false], email: ["", false],
                password: ["", false], telephone: ["", false], picture: new File([""], "undefined"), errors: [], userKey: window.location.pathname.replace("/invite/", '')
            });
        }
    }

    componentWillUnmount = () => {
        if (this.interval !== null)
            clearInterval(this.interval);
    }

    private handleChange = (event: any) => {
        const { target: { name, value } } = event
        this.setState({ [name]: [value, false] } as Pick<IState, keyof IState>, () => {
            this.setState({ errors: [] }, () => {
                localStorage.setItem('signupState', JSON.stringify(this.state));
            });
        });
    }

    private changeActivity = (currentActivity: Activities) => {
        switch (currentActivity) {
            case Activities.Name:
                this.props.withUserKey ?
                    this.setState({ currentActivity: Activities.Password }) :
                    this.setState({ currentActivity: Activities.Email })
                break;
            case Activities.Email:
                SignupServiceProvider.checkEmail(this.state.email[0]).then((res: any) => {
                    if (res) {
                        if (!res.data) {
                            this.setState({ currentActivity: Activities.Password })
                        }
                        else {
                            this.setState({ email: [this.state.email[0], true], errors: [ErrorKeys.emailAlreadyInUse] });
                        }
                    }
                    else {
                        this.setState({ email: [this.state.email[0], true], errors: [ErrorKeys.emailAlreadyInUse] });
                    }
                })
                break;
            case Activities.Password:
                SignupServiceProvider.checkPassword(this.state.password[0]).then((res: any) => {
                    if (res) {
                        if (res.data.constraints.length === 0) {
                            this.setState({ currentActivity: Activities.Telephone })
                        }
                        else {
                            let errors: ErrorKeys[] = res.data.constraints.map((val: keyof typeof ErrorKeys) => {
                                return ErrorKeys[val];
                            })
                            this.setState({ email: [this.state.email[0], true], errors: errors });
                        }
                    }
                    else {
                        this.setState({ email: [this.state.email[0], true], errors: [ErrorKeys.passwordInvalid] });
                    }
                })
                break;
            case Activities.Telephone:
                SignupServiceProvider.checkPhone(this.state.telephone[0]).then((res: any) => {
                    if (res) {
                        if (res.data) {
                            this.setState({ currentActivity: Activities.Picture })
                        }
                        else {
                            this.setState({ email: [this.state.email[0], true], errors: [ErrorKeys.phoneInvalid] });
                        }
                    }
                    else {
                        this.setState({ telephone: [this.state.telephone[0], true], errors: [ErrorKeys.phoneInvalid] });
                    }
                })
                break;
            case Activities.Picture:
                if (this.props.withUserKey) {
                    this.setState({ picture: this.imgRef.current?.getImage() }, this.SendConfirmEmailWithKey)
                } else {
                    this.setState({ currentActivity: Activities.ConfirmEmail, picture: this.imgRef.current?.getImage() }, this.sendConfirmEmail)
                }
                break;
            case Activities.ConfirmEmail:
                this.setState({ currentActivity: Activities.ConfirmEmail })
                break;
        }
    }

    private backActivity = (currentActivity: Activities) => {
        switch (currentActivity) {
            case Activities.Name:
                this.setState({ currentActivity: Activities.Name })
                break;
            case Activities.Email:
                this.setState({ currentActivity: Activities.Name })
                break;
            case Activities.Password:
                this.setState({ currentActivity: Activities.Email })
                break;
            case Activities.Telephone:
                this.setState({ currentActivity: Activities.Password })
                break;
            case Activities.Picture:
                this.setState({ currentActivity: Activities.Telephone })
                break;
            case Activities.ConfirmEmail:
                this.setState({ currentActivity: Activities.ConfirmEmail })
                break;
        }
    }

    private sendConfirmEmail = () => {
        var st = this.state
        var user: iBaseUser = { email: st.email[0], first_name: st.first_name[0], last_name: st.last_name[0], telephone: st.telephone[0], password: st.password[0] }
        SignupServiceProvider.createUser(user).then((e: any) => {
            if (e && e.data) {
                localStorage.setItem('username', this.state.email[0]);
                localStorage.setItem('password', this.state.password[0]);
                localStorage.setItem('token', e.data.token);
                SignupServiceProvider.sendImage(this.state.picture).then((e: any) => {
                    if (e && e.status === 200) {
                    }
                })
            }
            else {
                this.setState({ errors: [ErrorKeys.couldNotCreateAccount] })
            }
        })
    }

    private SendConfirmEmailWithKey = () => {
        var st = this.state
        var user: iCompleteBaseUser = { key: st.userKey, first_name: st.first_name[0], last_name: st.last_name[0], telephone: st.telephone[0], password: st.password[0] }
        SignupServiceProvider.completeUserCreation(user).then((e: any) => {
            if (e && e.status === 200) {
                localStorage.setItem('username', e.data.email);
                localStorage.setItem('password', this.state.password[0]);
                localStorage.setItem('token', e.data.token);
                SignupServiceProvider.sendImage(this.state.picture).then((e: any) => {
                    if (e && e.status === 200) {
                        this.exitForm();
                    }
                })
            }
            else {
                this.setState({ errors: [ErrorKeys.couldNotCreateAccount] })
            }
        })
    }

    private verifyEmailConfirmed = () => {
        SignupServiceProvider.verifyEmailChecked().then((e: any) => {
            if (e) {
                if (e.status === 200) {
                    if (e.data === "True") {
                        clearInterval(this.interval);
                        this.props.exitForm(true);
                        this.clearLocalStorage();
                    }
                }
            }
        })
    }

    private exitForm = () => {
        this.clearLocalStorage();
        this.props.exitForm(false);
    }

    private clearLocalStorage = () => {
        localStorage.setItem('signupState', '{"currentActivity":null}');
    }

    private nameBodyContent = () => {
        return (
            <div className="contentContainer name animFadeInRightBig">
                <div className="infoContainer">
                    <h1><Translations value={Dictionary.introduceYourself}></Translations></h1>
                    <span><Translations value={Dictionary.whatsYourName}></Translations></span>
                    <DynamicInput inputName={Dictionary.enterFirstNameHere} type="text" size={Size.large} handleChange={this.handleChange} value={this.state.first_name[0]} inputStateName={"first_name"} error={this.state.first_name[1]}></DynamicInput>
                    <DynamicInput inputName={Dictionary.enterLastNameHere} type="text" size={Size.large} handleChange={this.handleChange} value={this.state.last_name[0]} inputStateName={"last_name"} error={this.state.last_name[1]}></DynamicInput>
                </div>
                {this.state.first_name[0] !== "" && this.state.last_name[0] !== "" ?
                    <ArrowButton text={Dictionary.nextStep} onClick={() => this.changeActivity(Activities.Name)}></ArrowButton>
                    : <div />}
            </div>
        )
    }

    private emailBodyContent = () => {
        var nextVisible = this.state.email[0] !== ""
        return (
            <div className="contentContainer email animFadeInRightBig">
                <div className="infoContainer">
                    <h1><Translations value={Dictionary.hiFirstNameLastName} var={[this.state.first_name[0], this.state.last_name[0]]}></Translations></h1>
                    <span><Translations value={Dictionary.pleaseEnterYourEmail}></Translations></span>
                    <DynamicInput inputName={Dictionary.enterEmailHere} type="email" size={Size.large} handleChange={this.handleChange} value={this.state.email[0]} inputStateName={"email"} error={this.state.email[1]}></DynamicInput>
                </div>
                <div className={nextVisible ? "twoButton" : "oneButton"}>
                    <ArrowButton text={Dictionary.back} onClick={() => this.backActivity(Activities.Email)} left />
                    {nextVisible ?
                        <ArrowButton text={Dictionary.nextStep} onClick={() => this.changeActivity(Activities.Email)} /> : <div />}
                </div>
            </div>
        )
    }

    private passwordBodyContent = () => {
        var nextVisible = this.state.password[0] !== ""
        return (
            <div className="contentContainer password animFadeInRightBig">
                <div className="infoContainer">
                    <h1><Translations value={Dictionary.pleaseChooseAPassword}></Translations></h1>
                    <span><Translations value={Dictionary.between816Char}></Translations></span>
                    <DynamicInput inputName={Dictionary.pleaseEnterPassword} type="password" size={Size.large} handleChange={this.handleChange} value={this.state.password[0]} inputStateName={"password"} error={this.state.password[1]}></DynamicInput>
                </div>
                <div className={nextVisible ? "twoButton" : "oneButton"}>
                    <ArrowButton text={Dictionary.back} onClick={() => this.backActivity(Activities.Password)} left />
                    {nextVisible ?
                        <ArrowButton text={Dictionary.nextStep} onClick={() => this.changeActivity(Activities.Password)} /> : <div />}
                </div>
            </div>
        )
    }

    private telephoneBodyContent = () => {
        var nextVisible = this.state.telephone[0] !== ""
        return (
            <div className="contentContainer phone animFadeInRightBig">
                <div className="infoContainer">
                    <h1><Translations value={Dictionary.letsContinue}></Translations></h1>
                    <span><Translations value={Dictionary.pleaseEnterPhone}></Translations></span>
                    <DynamicInput inputName={Dictionary.enterYourPhoneHere} type="text" size={Size.large} handleChange={this.handleChange} value={this.state.telephone[0]} inputStateName={"telephone"} error={this.state.telephone[1]}></DynamicInput>
                </div>
                <div className={nextVisible ? "twoButton" : "oneButton"}>
                    <ArrowButton text={Dictionary.back} onClick={() => this.backActivity(Activities.Telephone)} left />
                    {nextVisible ?
                        <ArrowButton text={Dictionary.nextStep} onClick={() => this.changeActivity(Activities.Telephone)}></ArrowButton> : <div />}
                </div>
            </div>
        )
    }

    private pictureBodyContent = () => {
        var nextVisible = true
        return (
            <div className="contentContainer profilePic animFadeInRightBig">
                <div className="infoContainer">
                    <h1><Translations value={Dictionary.almostOver}></Translations></h1>
                    <span><Translations value={Dictionary.pleaseDragProfilePic}></Translations></span>
                    <ImageUploader ref={this.imgRef} lastPic={this.state.picture}></ImageUploader>
                </div>
                <div className={nextVisible ? "twoButton" : "oneButton"}>
                    <ArrowButton text={Dictionary.back} onClick={() => this.backActivity(Activities.Picture)} left />
                    {nextVisible ?
                        <ArrowButton text={Dictionary.end} onClick={() => this.changeActivity(Activities.Picture)}></ArrowButton> : <div />}
                </div>
            </div>
        )
    }

    private confirmationEmailBodyContent = () => {
        if (!this.interval)
            this.interval = setInterval(this.verifyEmailConfirmed, 10000);

        return (
            <div className="contentContainer emailConfirm animFadeInRightBig">
                <div className="infoContainer">
                    <h1><Translations value={Dictionary.confirmationSent}></Translations></h1>
                    <span><Translations value={Dictionary.emailHasBeenSentTo} var={[this.state.email[0]]}></Translations></span>
                </div>
                <div className="oneButton middle">
                    <PromiseButton basicText={Dictionary.sendAgain} loadingText={Dictionary.Description} doneText={Dictionary.Done} onClick={() => { console.log("nothing yet...") }} />
                </div>
            </div>
        )
    }

    private bodyContent = () => {
        switch (this.state.currentActivity) {
            case Activities.Name:
                return this.nameBodyContent();
            case Activities.Email:
                return this.emailBodyContent();
            case Activities.Password:
                return this.passwordBodyContent();
            case Activities.Telephone:
                return this.telephoneBodyContent();
            case Activities.Picture:
                return this.pictureBodyContent();
            case Activities.ConfirmEmail:
                return this.confirmationEmailBodyContent();
        }
    }

    public render() {
        return (
            <div id="SignupBodyContainer">
                <ProgressBar now={this.state.currentActivity} min={0} max={6} striped animated variant={"info"} />
                <h1 className="h1-header animate__animated animate__bounceInLeft"><Translations value={Dictionary.creatingYourAccount}></Translations></h1>
                <div id="FormContainer">
                    {this.bodyContent()}
                    <div>
                        <ErrorList values={this.state.errors}></ErrorList>
                    </div>
                    <ArrowButton text={Dictionary.cancel} onClick={this.exitForm} left />
                </div>
            </div>
        )
    }
}

export default UserSignupForm