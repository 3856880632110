import * as React from 'react';
import ImageLoader, { Extensions } from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import "./ArrowButton.scss"
// import 'react-widgets/dist/css/react-widgets.css';
import "animate.css";

interface IProps {
    text: Dictionary
    onClick: any
    width?: string
    left?: boolean
}

interface IState {
    buttonState: iButtonState
}

enum iButtonState {
    base,
    loading,
    done,
}

class ArrowButton extends React.Component<IProps, IState> {

    state = {
        buttonState: iButtonState.base
    }

    public render() {
        return (
            <div className={(this.props.left ? "animate__fadeInLeftBig " : "animate__fadeInRightBig ") + "button animate__animated animate__faster"} onClick={this.props.onClick}>
                <div className={this.props.left ? "dub-arrow_left" : "dub-arrow"}><ImageLoader name="doubleArrowIcon" className={this.props.left ? "img_rotate" : ""} extension={Extensions.PNG} /></div>
                <label className={this.props.left ? "label_left" : ""}><Translations value={this.props.text}></Translations></label>
            </div>
        )
    }
}

export default ArrowButton