import { Snackbar } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import PromiseButton from 'GlobalComponents/Buttons/PromiseButton';
import DynamicInput, { Size } from 'GlobalComponents/Inputs/DynamicInput';
import LoginProvider from 'GlobalComponents/ServiceProvider';
import { Languages } from 'GlobalComponents/SettingsContext';
import * as React from 'react';
import Activities from 'Ressources/Activities';
import ImageLoader, { Extensions } from 'Ressources/Images/ImageLoader';
import ErrorKeys from 'Ressources/translations/Errors/ErrorKeys';
import { ErrorList } from 'Ressources/translations/Errors/ErrorList';
import { Dictionary, Translations } from '../../Ressources/translations/Translations';
import "./Login.scss"
import SignupServiceProvider from './signupForm/SignupServiceProvider';

enum BodyContent {
    login,
    forgotPassword,
    resetPassword,
}

interface IProps {
    handleChange: Function
    userName: string
    password: string
    login: Function
    startSignUpForm: React.MouseEventHandler<HTMLButtonElement>
    wrongLogin: boolean
    changeActivity: Function
    resettingPassword?: boolean
    token?: string
}

interface IState {
    bodyContent: BodyContent
    isResetPasswordToastOpen: boolean
    isPasswordWeak: boolean
    passwordErrors: ErrorKeys[]
}

class Login_PC extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        if (props.resettingPassword) {
            this.state = {
                bodyContent: BodyContent.resetPassword,
                isResetPasswordToastOpen: false,
                isPasswordWeak: false,
                passwordErrors: []
            }
        } else {
            this.state = {
                bodyContent: BodyContent.login,
                isResetPasswordToastOpen: false,
                isPasswordWeak: false,
                passwordErrors: []
            }
        }
    }

    private ForgotPassword = () => {
        return LoginProvider.SendPasswordReset(this.props.userName).then((res: any) => {
            this.setState({ isResetPasswordToastOpen: true });
        });
    }

    private ResetPassword = () => {
        return SignupServiceProvider.checkPassword(this.props.password).then((res: any) => {
            if (res) {
                if (res.data.constraints.length === 0) {
                    return LoginProvider.ResetPassword(this.props.password, this.props.token ?? "").then((res: any) => {
                        if (res) {
                            window.location.href = window.location.href.substring(0, window.location.href.indexOf('/password-reset'));
                        }
                    });
                }
                else {
                    let errors: ErrorKeys[] = res.data.constraints.map((val: keyof typeof ErrorKeys) => {
                        return ErrorKeys[val];
                    })
                    this.setState({ isPasswordWeak: true, passwordErrors: errors });
                }
            }
        });
    }

    private header = () => {
        return (
            <div className="LoginHeaderContainer">
                <div className="top">

                </div>
                <div className="bottom">
                    <div className="notColored">
                        <div className="notColoredOrange">
                        </div>
                        <div className="notColoredWhite">
                            <ImageLoader name="Logo" className="TopMenuLogo"></ImageLoader>
                        </div>
                    </div>
                    <div className="colored">

                    </div>
                </div>
            </div>
        )
    }

    private LoginBodyContent = () => {
        return (
            <React.Fragment>
                <div className="LoginForm">
                    <div className="InputContainers">
                        <DynamicInput size={Size.large} inputName={Dictionary.email} handleChange={this.props.handleChange} type="email" inputStateName="userName" value={this.props.userName} />
                        <DynamicInput size={Size.large} inputName={Dictionary.password} handleChange={this.props.handleChange} type="password" inputStateName="password" value={this.props.password} />
                        <div className='errorMessageContainer'>
                            {this.props.wrongLogin ? <label className='errorMessage'>{<Translations value={Dictionary.wrongEmailPassword}></Translations>}</label> : null}
                        </div>
                    </div>
                    <u className='ForgotPasswordText' onClick={() => this.setState({ bodyContent: BodyContent.forgotPassword })}><Translations value={Dictionary.forgotYourPassword}></Translations></u>
                    <PromiseButton width={"30%"} height={"30%"} className='loginButton' onClick={this.props.login} basicText={Dictionary.login} loadingText={Dictionary.send} doneText={Dictionary.login} />
                </div>
                <div className="SignUpContainer">
                    <h2><Translations value={Dictionary.dontHaveAnAccount} /></h2>
                    <label><Translations value={Dictionary.getStartedFast} /></label>
                    <PromiseButton width={"30%"} height={"30%"} className='loginButton' onClick={this.props.startSignUpForm} basicText={Dictionary.signUp} loadingText={Dictionary.send} doneText={Dictionary.signUp} />
                </div>
            </React.Fragment>
        )
    }

    private handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ isResetPasswordToastOpen: false });
    };

    private ForgotPasswordBodyContent = () => {
        return (
            <React.Fragment>
                <div className="LoginForm">
                    <h4 style={{ marginTop: "50px" }}>
                        <Translations value={Dictionary.enterEmailHereAndWeWillSendResetLink}></Translations>
                    </h4>
                    <div className="InputContainers">
                        <DynamicInput size={Size.large} inputName={Dictionary.email} handleChange={this.props.handleChange} type="email" inputStateName="userName" value={this.props.userName} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "70%", justifyContent: "center" }}>
                        <PromiseButton width={"30%"} height={"30%"} className='loginButton' onClick={() => { this.setState({ bodyContent: BodyContent.login }) }} basicText={Dictionary.back} loadingText={Dictionary.back} doneText={Dictionary.back} />
                        <PromiseButton width={"30%"} height={"30%"} className='loginButton' onClick={this.ForgotPassword} basicText={Dictionary.send} loadingText={Dictionary.sending} doneText={Dictionary.sent} />
                    </div>
                    <Snackbar open={this.state.isResetPasswordToastOpen} autoHideDuration={6000} onClose={this.handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                        <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
                            <Translations value={Dictionary.resetLinkSentSuccessfully} />
                        </Alert>
                    </Snackbar>
                </div>
            </React.Fragment>
        )
    }

    private ResetPasswordBodyContent = () => {
        return (
            <React.Fragment>
                <div className="LoginForm">
                    <div className="InputContainers">
                        <DynamicInput size={Size.large} inputName={Dictionary.password} handleChange={this.props.handleChange} error={this.state.isPasswordWeak} type="password" inputStateName="password" value={this.props.password} />
                        <ErrorList values={this.state.passwordErrors}></ErrorList>
                        <div className='errorMessageContainer'>
                            {this.props.wrongLogin ? <label className='errorMessage'>{<Translations value={Dictionary.wrongEmailPassword}></Translations>}</label> : null}
                        </div>
                    </div>
                    <PromiseButton width={"30%"} height={"30%"} className='loginButton' onClick={this.ResetPassword} basicText={Dictionary.resetPassword} loadingText={Dictionary.sending} doneText={Dictionary.Done} />
                </div>
            </React.Fragment>
        )
    }

    private BodyInputs = () => {
        switch (this.state.bodyContent) {
            case BodyContent.login:
                return this.LoginBodyContent();
            case BodyContent.forgotPassword:
                return this.ForgotPasswordBodyContent();
            case BodyContent.resetPassword:
                return this.ResetPasswordBodyContent();
        }

    }

    private body = () => {
        return (
            <div id="BodyContainer">
                {this.BodyInputs()}
                <ImageLoader name='MontrealBackdrop' className='MontrealBackdrop'></ImageLoader>
            </div>
        )
    }

    public render() {
        return (
            <React.Fragment>
                {this.header()}
                {this.body()}
            </React.Fragment>
        )
    }
}

export default Login_PC