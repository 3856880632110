import iCondo from 'Models/Condo';
import * as React from 'react';
import MediaType from 'Ressources/MediaType';
import "../../SyndicatePage.scss"
import SyndicateServiceProvider from '../../SyndicateServiceProvider';
import SyndicateAddEmployee_PC from './SyndicateAddEmployee_PC';
import SyndicateAddEmployee_Phone from './SyndicateAddEmployee_Phone';

interface IProps {
    changeState: any
    handleChange: any
    currentCondo: iCondo
    email: string
    mediaType: MediaType
}

interface IState {
    key: string
}

class SyndicateAddEmployee extends React.Component<IProps, IState> {

    private sendInvite = () => {
        // SyndicateServiceProvider.SendMassEmails(this.props.currentCondo.id, [this.props.email]).then((res: any) => {
        //     console.log(res);
        // })
    }

    public render() {
        if (this.props.mediaType === MediaType.Phone) {
            return (
                <SyndicateAddEmployee_Phone sendInvite={this.sendInvite} {...this.props}></SyndicateAddEmployee_Phone>
            )
        }
        if (this.props.mediaType === MediaType.PC || this.props.mediaType === MediaType.Tablet) {
            return (
                <SyndicateAddEmployee_PC sendInvite={this.sendInvite}{...this.props}></SyndicateAddEmployee_PC>
            )

        }
    }
}

export default SyndicateAddEmployee