import PromiseButton from 'GlobalComponents/Buttons/PromiseButton';
import iCondo from 'Models/Condo';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from '../../../../Ressources/translations/Translations';
import { pageStates } from '../../SyndicatePage';

interface IProps {
    changeState: any
    currentCondo: iCondo
    addKeyToClipboard: Function
    syndicateKey: string
}

interface IState {
}

class SyndicateShareKey_PC extends React.Component<IProps, IState> {

    public render() {
        return (
            <div className="subContainer">
                <div className="options big">
                    <span><Translations value={Dictionary.inviteUsersJoinSyndicate} var={[this.props.currentCondo.name]}></Translations></span>
                    <div className="horizontalDivider full"></div>
                    <div className="subOptions">
                        <div className="options input">
                            <div className="title">
                                <ImageLoader name="GiveKeyIcon" className="icon"></ImageLoader>
                                <span><Translations value={Dictionary.ShareSyndicateKey} var={[this.props.currentCondo.name]}></Translations></span>
                            </div>
                            <div className="inputContainer">
                                <span>{this.props.syndicateKey === "" ? <Translations value={Dictionary.FetchingKey}></Translations> : this.props.syndicateKey}</span>
                                <PromiseButton onClick={this.props.addKeyToClipboard} basicText={Dictionary.Copy} loadingText={Dictionary.Copying} doneText={Dictionary.Copied}></PromiseButton>
                            </div>
                        </div>
                        <div className="verticalDivider"></div>
                        <div className="options navigation">
                            < div className="option clickable" onClick={() => this.props.changeState(pageStates.individualInvite)}>
                                <ImageLoader name="IndividualInviteIcon" className="icon"></ImageLoader>
                                <span><Translations value={Dictionary.individualInvite}></Translations></span>
                            </div >
                            <div className="horizontalDivider"></div>
                            <div className="option clickable" onClick={() => this.props.changeState(pageStates.groupInvite)}>
                                <ImageLoader name="GroupInviteIcon" className="icon"></ImageLoader>
                                <span><Translations value={Dictionary.groupInvite}></Translations></span>
                            </div>
                            <div className="horizontalDivider"></div>
                            <div className="option clickable" onClick={() => this.props.changeState(pageStates.manageListCoowners)}>
                                <ImageLoader name="PersonListIcon" className="icon"></ImageLoader>
                                <span><Translations value={Dictionary.manageListCoowners}></Translations></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SyndicateShareKey_PC

