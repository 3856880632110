import * as React from 'react';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import TextForumPost from './ForumPosts/TextForumPost';
import "./Forum.scss";
import PollForumPost from './ForumPosts/PollForumPost';
import ImageLoader from 'Ressources/Images/ImageLoader';

interface IProps {
    navigationBarClosed: boolean
}

interface IState {
    posts: any,
    polls: any
}

class Forum extends React.Component<IProps, IState> {

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.navigationBarClosed !== this.props.navigationBarClosed) {
            if (this.props.navigationBarClosed) {
                let div = document.getElementsByClassName("ForumContainer")[0];
                div.classList.remove('open')
                div.classList.add('closed')
            }
            else {
                let div = document.getElementsByClassName("ForumContainer")[0];
                div.classList.remove('closed')
                div.classList.add('open')
            }
        }
    }

    componentDidMount = () => {
        var posts = require("./../../Ressources/TestFiles/TextForumPosts.json")
        var polls = require("./../../Ressources/TestFiles/PollForumPosts.json")

        this.setState({
            posts: posts,
            polls: polls
        })
    }

    onPollChange = (value: any) => {
        var polls = this.state.polls;
        polls.data.forEach((e: any) => {
            e.options.forEach((n: any) => {
                if (n.name == value.target.defaultValue) {
                    n.checked = value.target.checked
                }
            })
        });
        this.setState({ polls: polls })
    }

    addComment = (text: string) => {
        var posts = this.state.posts;
        var comments = posts.data[0].comments;
        comments.push({
            text: text,
            time: new Date().toString(),
            username: "Anthony Rondeau-Demeule"
        })
        this.setState({ posts: posts });
    }

    public render() {
        if (this.state) {
            return (
                <div className="ForumContainer">
                    <div className="ForumHeaderContainer">
                        <ImageLoader name="ForumIcon" className="FocumIcon"></ImageLoader>
                        <h1><Translations value={Dictionary.newsFeed}></Translations></h1>
                    </div>
                    {this.state.posts.data.map((post: any) => <TextForumPost content={post} addComment={this.addComment}></TextForumPost>)}
                    {this.state.polls.data.map((post: any) => <PollForumPost onPollChange={this.onPollChange} content={post} addComment={this.addComment}></PollForumPost>)}
                </div>
            )
        }
        return null
    }
}

export default Forum