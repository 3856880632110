import iCondo from 'Models/Condo';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from '../../../../Ressources/translations/Translations';
import { pageStates } from '../../SyndicatePage';

interface IProps {
    changeState: any
    currentCondo: iCondo
}

interface IState {
}

class SyndicateBaseOptions_PC extends React.Component<IProps, IState> {

    public render() {
        return (
            <div className="subContainer">
                <div className="options clickable" onClick={() => this.props.changeState(pageStates.manageUsers)}>
                    <span><Translations value={Dictionary.syndicateInfo}></Translations></span>
                    <div className="horizontalDivider"></div>
                    <ImageLoader name="SyndicateInfo" className="icon"></ImageLoader>
                </div>
                <div className="verticalDivider small"></div>
                <div className="options">
                    <span><Translations value={Dictionary.manageEmployees}></Translations></span>
                    <div className="horizontalDivider"></div>
                    <div className="subOptions">
                        <div className="option clickable" onClick={() => this.props.changeState(pageStates.individualInvite)}>
                            <ImageLoader name="IndividualInviteIcon" className="icon"></ImageLoader>
                            <span><Translations value={Dictionary.individualInvite}></Translations></span>
                        </div>
                        <div className="verticalDivider"></div>
                        <div className="option clickable" onClick={() => this.props.changeState(pageStates.groupInvite)}>
                            <ImageLoader name="GroupInviteIcon" className="icon"></ImageLoader>
                            <span><Translations value={Dictionary.groupInvite}></Translations></span>
                        </div>
                    </div>
                    <div className="horizontalDivider"></div>
                    <div className="subOptions">
                        <div className="option clickable" onClick={() => this.props.changeState(pageStates.createAmenities)}>
                            <ImageLoader name="IndividualInviteIcon" className="icon"></ImageLoader>
                            <span>Create Amenities</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SyndicateBaseOptions_PC