import axios, { AxiosError, AxiosResponse } from "axios";
import PageStates from "Models/PageStates";
import Activities from "Ressources/Activities";

export default class LoginProvider {

    static path = 'https://connect.smartcondomanager.com/';

    static async Login(username: string, password: string) {
        const token = btoa(username + ":" + password);
        let res: any = await axios.post(LoginProvider.path + 'api/tokens', {}, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Basic ` + token,
            }
        }).catch((res: any) => {
            return false;
        });

        if (res && res.status === 200 && res.data.token !== "") {
            localStorage.setItem('username', username);
            localStorage.setItem('password', password); //TODO this is not secure !!!
            localStorage.setItem('token', res.data.token);

            return true;
        }
        return false;
    }

    static async RefreshToken() {
        var username = localStorage.getItem('username');
        var password = localStorage.getItem('password');
        if (username !== null && password !== null) {
            let res = await this.Login(username, password);

            return res;
        } else {
            let pageState = JSON.parse(localStorage.getItem('pageStates') || '"activityContainer":""') as PageStates;
            if (pageState !== null) {
                pageState.activityContainer = Activities.Login;
            }
            localStorage.setItem('pageStates', JSON.stringify(pageState));
            window.location.reload();
        }

        return false;
    }

    static async SendPasswordReset(email: string) {
        let res: any = await axios.post(LoginProvider.path + 'api/request-password-reset', {
            email: email
        }, {
            headers: {
                'Cache-Control': "no-store",
            }
        }).catch((res: any) => {
            return false;
        });

        if (res && res.status === 200) {
            return true;
        }
        return false;
    }

    static async ResetPassword(password: string, token: string) {
        let res: any = await axios.post(LoginProvider.path + 'api/do-password-reset', {
            password: password,
            token: token
        }, {
            headers: {
                'Cache-Control': "no-store",
            }
        }).catch((res: any) => {
            return false;
        });

        if (res && res.status === 200) {
            return true;
        }
        return false;
    }
}

axios.interceptors.response.use((response: AxiosResponse) => {
    return response;
}, async (error: any) => {
    if (error.response.config.url !== "https://connect.smartcondomanager.com/api/tokens" && error.response.status === 401) {
        LoginProvider.RefreshToken().then((successfull: boolean) => {
            if (successfull) {
                let configs = error.config;
                configs.headers.Authorization = `Bearer ` + localStorage.getItem('token');
                return axios.request(configs);
            }
            return Promise.reject(error.message);
        });
    }
    return Promise.reject(error.message);
});