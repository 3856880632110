import * as React from 'react';
import "./Header.scss"
import Activities from '../../Ressources/Activities';
import SettingsContext, { Languages } from '../SettingsContext';
import ImageLoader, { Extensions } from '../../Ressources/Images/ImageLoader';
import SignupServiceProvider from 'components/Login/signupForm/SignupServiceProvider';
import SyndicateSelector from 'GlobalComponents/SyndicateSelector';
import iCondo from 'Models/Condo';
import MediaType from 'Ressources/MediaType';
import UserRoleSelect from 'GlobalComponents/GodMode/UserRoleSelect';


interface IProps {
    changeActivity: Function
    getPicture: Function
    selectedProperty: iCondo
    condoPicture: any
    condoList: iCondo[]
    changeSelectedProperty: Function
    closeNavigationMenu: any
}

interface IState {
}

class Header_PC extends React.Component<IProps, IState> {

    public render() {
        return (
            <div className="headerContainer">
                <div className="top">

                </div>
                <div className="bottom">
                    <div className="notColored">
                        <div className="notColoredOrange">
                            <ImageLoader className='hamburgerIcon' name='HamburgerMenuIcon' onClick={this.props.closeNavigationMenu}></ImageLoader>
                        </div>
                        <div className="notColoredWhite">
                            <ImageLoader name="Logo" className="TopMenuLogo" onClick={() => this.props.changeActivity(Activities.Home)}></ImageLoader>
                        </div>
                    </div>
                    <div className="colored">
                        {this.props.selectedProperty ?
                            <SyndicateSelector image={this.props.condoPicture} condoList={this.props.condoList}
                                selectedProperty={this.props.selectedProperty} changeSelectedProperty={this.props.changeSelectedProperty}></SyndicateSelector>
                            : <div></div>}
                        {this.props.getPicture()}
                    </div>
                </div>
            </div >
        )
    }
}

export default Header_PC


Header_PC.contextType = SettingsContext;