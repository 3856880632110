/*eslint no-template-curly-in-string: "off"*/
let translationFR: any =
    [
        "Courriel",
        "Mot de passe",
        "Se connecter",
        "Compte oublié?",
        "Langue",
        "Syndicat",
        "Créer un nouveau syndicat de copropriétaires",
        "Vos syndicats",
        "Fil d'actualités",
        "Accueil",
        "Calendrier",
        "Déconnexion",
        "Créer votre syndicat",
        "Annuler",
        "Quel est le nom du syndicat ?",
        "*Veuillez noter qu'une fois le syndicat créé, vous deviendrez le super administrateur",
        "Nom du syndicat",
        "Étape suivante",
        "Veuillez saisir les informations de ${0}",
        "Retour",
        "Entrez votre email ici",
        "Confirmation envoyée!",
        "Un e-mail a été envoyé à ${0}. Cliquez sur le lien contenu à l'intérieur pour confirmer votre identité",
        "Envoyer à nouveau",
        "Voulez-vous compléter votre profil?",
        "Vous pouvez toujours le faire plus tard",
        "Terminer",
        "Oui",
        "Non",
        "Continuons",
        "Veuillez saisir l'adresse du syndicat",
        "Adresse postale",
        "Numéro d'appartement",
        "Ville",
        "Province",
        "Code postal",
        "Pays",
        "Veuillez glisser une photo de profil ici ou trouvez-la sur votre PC",
        "Ajouter des administrateurs au syndicat",
        "Combien de membres composent l'équipe d'administration en excluant vous-même ?",
        "Envoyez des invitations aux membres du syndicat en insérant leur adresse e-mail ici :",
        "Entrez le nombre de membres ici",
        "Courriel du membre ${0}",
        "Présentez-vous",
        "Quel est votre nom ?",
        "Prénom",
        "Nom",
        "Bonjour, ${0} ${1}",
        "Veuillez entrer votre e-mail",
        "Veuillez choisir un mot de passe",
        "Entre 8 et 16 caractères",
        "Terminer",
        "Vers tableau de bord",
        "Veuillez entrer votre numéro de téléphone",
        "Entrez votre numéro de téléphone ici",
        "Presque terminé",
        "Veuillez entrer votre adresse",
        "Gestion",
        "Comptabilité",
        "Communications",
        "Réservations",
        "Documents",
        "Invitez des utilisateurs à rejoindre ${0}",
        "Info syndicat",
        "Gérer vos employés",
        "Invitation individuelle",
        "Invitation de groupe",
        "Partager la clé d'accès du syndicat",
        "Gérer la liste des copropriétaires",
        "Ajouter un employé",
        "Gérer la liste des employés",
        "Modifier la liste des administrateurs",
        "Envoyer",
        "Entrez un groupe de email ici, séparés par : ",
        "Créer propriété",
        "Le mot de passe/courriel que vous avez entrez est invalide",
        "Personne à contacter en cas d'urgence",
        "Veuillez ajouter une personne que nous pourrions contacter en cas d'urgence",
        "Type de propriété",
        "Condo",
        "Appartements",
        "Partager la clé de ${0}",
        "Récupération de la clé ...",
        "Emplacements",
        "Veuillez sélectionner un emplacement à gauche pour voir ses réservations correspondantes !",
        "Description",
        "Nom",
        "Emplacement",
        "Créer emplacement",
        "Numéro de corporation",
        "Envoi...",
        "Terminé !",
        "Copier",
        "Copie...",
        "Copié !",
        "Courriel",
        "Mot de passe",
        "Nom de la personne",
        "Numéro de téléphone",
        "Création de votre compte ...",
        "Choisissez une image à télécharger",
        "dimanche",
        "lundi",
        "mardi",
        "mercredi",
        "jeudi",
        "vendredi",
        "samedi",
        "sauvegarder",
        "Modifier réservation",
        "Supprimer",
        "Ajouter un événement récurrent",
        "Événements récurrents existants",
        "Vous n'avez pas de compte ?",
        "Lancez-vous rapidement et créez-en un ici",
        "S'inscrire",
        "Rejoindre un syndicat déjà existant",
        "Créer nouvelle unité",
        "Numéro d'unité",
        "Pourcentage de vote (%)",
        "Créer unité",
        "Envoi en cours",
        "Créé",
        "Administrateur",
        "Propriétaire",
        "Occupant",
        "Employé",
        "Aucun",
        "Rôle de l'utilisateur",
        "Privé",
        "Location",
        "Glissez des fichiers ici pour les télécharger",
        "Télécharger fichier(s)...",
        "Prénom",
        "Nom",
        "Inutilisé",
        "Envoyé",
        "Erreur",
        "En attente",
        "Utilisé",
        "Pourcentage de vote",
        "Fermer",
        "Mot de passe oublié ?",
        "Entrez votre courriel ci-dessous et nous vous enverrons un lien de réinitialisation de mot de passe",
        "Réinitialiser mot de passe",
        "lien de réinitialisation envoyé avec succès",
        "Une erreur s'est produite lors de l'envoi de l'invitation",
        "Ce courriel est déjà utilisée",
        "L'invitation a été envoyée avec succès",
        "Centre d'aide",
        "faible",
        "moyen",
        "haut",
        "urgent",
        "publique",
        "priorité",
        "tout",
        "emplacement",
        "ouvert",
        "en attente",
        "en cours",
        "fermé",
        "invalide",
        "état",
        "Récurrent",
        "Exceptions"
    ]

export default translationFR