import { Snackbar } from '@material-ui/core';
import { Alert, AlertColor, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import CreateUnit from 'components/Units/CreateUnit';
import UnitsProvider from 'components/Units/UnitsProvider';
import PromiseButton from 'GlobalComponents/Buttons/PromiseButton';
import LabeledArea from 'GlobalComponents/Containers/LabeledArea/LabeledArea';
import UserRoleSelect from 'GlobalComponents/GodMode/UserRoleSelect';
import DynamicInput, { Size } from 'GlobalComponents/Inputs/DynamicInput';
import iCondo from 'Models/Condo';
import { UserType } from 'Models/User';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from '../../../../Ressources/translations/Translations';
import SyndicateIndividualInviteSelector from './SyndicateIndividualInviteSelector';

interface IProps {
    handleChange: any
    changeState: any
    email: string
    currentCondo: iCondo
    sendInvite: Function
    changeSelectedCondo: Function
    addUnit: Function
    closePopup: Function
    selectedCondo: any
    popupVisible: boolean
    userType: UserType
    HandleChangeUserType: (event: SelectChangeEvent<string>, child: React.ReactNode) => void
    HandleChangeUnitNumber: (event: SelectChangeEvent<string>, child: React.ReactNode) => void
    unitNumber: string
}

interface IState {
    userRole: UserType
    units: any[]
    IsSnackbarPopupOpen: boolean,
    SnackbarMessage: Dictionary,
    SnackbarSeverity: AlertColor | undefined
}

class SyndicateIndividualInvite_PC extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.GetUnits();

        this.state = {
            userRole: UserType.admin,
            units: [],
            IsSnackbarPopupOpen: false,
            SnackbarMessage: Dictionary.none,
            SnackbarSeverity: undefined
        }
    }

    private GetUnits = () => {
        UnitsProvider.GetUnits(this.props.currentCondo.id).then((res: any) => {
            this.setState({ units: res.data.units })
        })
    }

    private IsSendButtonDisabled = () => {
        if(this.props.userType === UserType.unit || this.props.userType === UserType.occupant){
            return this.props.email === "" || this.props.unitNumber === "";
        }
        return this.props.email === "" || this.props.userType === UserType.none;
    }

    private handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({IsSnackbarPopupOpen: false});
    };

    private sendInvite = () => {
        this.props.sendInvite().then((res: any) => {
            if(res && res.successfull){
                if(res.data.error.length > 0){
                    this.setState({IsSnackbarPopupOpen: true, SnackbarMessage: Dictionary.thereWasErrorWithEmail, SnackbarSeverity: "error"})
                }
                if(res.data.in_use.length > 0){
                    this.setState({IsSnackbarPopupOpen: true, SnackbarMessage: Dictionary.emailAlreadyInUse, SnackbarSeverity: "error"})
                }
                if(res.data.sent.length > 0){
                    this.setState({IsSnackbarPopupOpen: true, SnackbarMessage: Dictionary.invitationSent, SnackbarSeverity: "success"})
                }
            }
        })

        return Promise.resolve(0);
    }

    public render() {
        return (
            <div className="IndividualInviteContainer">
                <span><Translations value={Dictionary.inviteUsersJoinSyndicate} var={[this.props.currentCondo.name]}></Translations></span>
                <div className="horizontalDivider full"></div>
                <div className="SubContainer">
                    <div className="title">
                        <ImageLoader name="IndividualInviteIcon" className="individualIcon"></ImageLoader>
                        <span><Translations value={Dictionary.individualInvite}></Translations></span>
                    </div>
                    <div className="InputContainer">
                        <div className="BasicInputsContainer">
                            <DynamicInput inputName={Dictionary.EnterTheEmailHere} type="email" handleChange={this.props.handleChange} value={this.props.email} inputStateName={"email"} size={Size.medium} />
                            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                                <InputLabel id="userTypeLabelID"><Translations value={Dictionary.userRole} /></InputLabel>
                                <Select
                                    labelId="userTypeLabelID"
                                    id="userType"
                                    value={UserType[this.props.userType]}
                                    onChange={this.props.HandleChangeUserType}
                                    label={<Translations value={Dictionary.userRole} />}
                                >
                                    <MenuItem disabled hidden value={UserType[UserType.none]}><em><Translations value={Dictionary.none} /></em></MenuItem>
                                    <MenuItem value={UserType[UserType.admin]}><Translations value={Dictionary.admin} /></MenuItem>
                                    <MenuItem value={UserType[UserType.unit]}><Translations value={Dictionary.owner} /></MenuItem>
                                    <MenuItem value={UserType[UserType.employee]}><Translations value={Dictionary.employee} /></MenuItem>
                                    <MenuItem value={UserType[UserType.occupant]}><Translations value={Dictionary.occupant} /></MenuItem>
                                </Select>
                            </FormControl>
                            {this.props.userType == UserType.occupant || this.props.userType == UserType.unit ? <SyndicateIndividualInviteSelector {...this.props} {...this.state} /> : null}
                        </div>
                        {this.state.userRole === UserType.admin || this.state.userRole === UserType.unit ? <CreateUnit GetUnits={this.GetUnits} {...this.props} /> : null}
                        <PromiseButton disabled={this.IsSendButtonDisabled()} onClick={this.sendInvite} basicText={Dictionary.send} loadingText={Dictionary.SendingEmail} doneText={Dictionary.Done} />
                    </div>
                </div>
                <Snackbar open={this.state.IsSnackbarPopupOpen} autoHideDuration={6000} onClose={this.handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                    <Alert onClose={this.handleClose} severity={this.state.SnackbarSeverity} sx={{ width: '100%' }}>
                    <Translations value={this.state.SnackbarMessage} />
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default SyndicateIndividualInvite_PC