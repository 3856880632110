import SignupServiceProvider from 'components/Login/signupForm/SignupServiceProvider';
import * as React from 'react';

interface IProps {
    comment: any
}

interface IState {
    picture: any
}

class ForumComment extends React.Component<IProps, IState> {

    state = {
        picture: "",
    }


    getPicture = () => {
        return (
            <img src={"data:image/jpeg;base64," + this.state.picture} alt="No picture" className="PostPicture"></img>
        )
    }

    componentDidMount = () => {
        SignupServiceProvider.getPictureUser(2).then((e: any) => {
            if (e) {
                const buf = Buffer.from(e.data, 'binary').toString('base64');
                this.setState({ picture: buf })
            }
        })
    }

    public render() {
        return (
            <div className='ForumPostComment'>
                {this.getPicture()}
                <div>
                    <div className='ForumPostCommentText'>
                        <div className='name'>{this.props.comment.username}</div>
                        <div className='text'>{this.props.comment.text}</div>
                    </div>
                    <div className='time'>{this.props.comment.time}</div>
                </div>

            </div>
        )
    }
}

export default ForumComment