import * as React from 'react';
import { Dictionary, Translations } from 'Ressources/translations/Translations';

interface IProps {
}

interface IState {
}

class DynamicTimeInput extends React.Component<IProps, IState> {

    public render() {
        return (
            <React.Fragment>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
            </React.Fragment>
        )
    }
}

export default DynamicTimeInput