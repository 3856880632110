import React from 'react';
import ProgressBar from "react-bootstrap/ProgressBar";
import "./SyndicateCreationForm.scss";
import "animate.css";
import { Dictionary, Translations } from '../../../Ressources/translations/Translations';
import Activities from 'Ressources/Activities';
import { iProperty } from 'Models/Condo';
import SyndicateServiceProvider from '../SyndicateServiceProvider';
import ImageUploader from 'GlobalComponents/ImageUploader/ImageUploader';
import PlacesAutocomplete from "react-places-autocomplete";
import DynamicInput, { Size } from 'GlobalComponents/Inputs/DynamicInput';
import ErrorKeys from 'Ressources/translations/Errors/ErrorKeys';
import { ErrorList } from 'Ressources/translations/Errors/ErrorList';
import ArrowButton from 'GlobalComponents/Buttons/ArrowButton';
import SignupServiceProvider from 'components/Login/signupForm/SignupServiceProvider';
import { iEmailMassInviteWithRole } from 'Models/EmailMassInvite';
import { UserType } from 'Models/User';
import SelectWrapper from 'GlobalComponents/Inputs/SelectWrapper';

interface ISyndicateCreationFormProps {
    exitForm: Function
}

interface ISyndicateCreationFormState {
    currentActivity: SyndicateActivities
    pType: [PropertyType, boolean]
    phone: [string, boolean]
    syndicate_name: [string, boolean]
    postal_code: [string, boolean]
    street_name: [string, boolean]
    street_num: [number, boolean]
    city: [string, boolean]
    state: [string, boolean]
    country: [string, boolean]
    numberSyndicateMembers: [number | '', boolean]
    syndicateMembersEmail: [string, boolean][]
    emailConfirmed: boolean
    errors: ErrorKeys[]
    completeProfile: boolean | null
    profilePic: any
    address: string
    corp_num: [number | '', boolean]
    picture: File
    currentCreationStep: CreationStep
}

enum PropertyType {
    NONE = 0,
    RENTAL = 1,
    PRIVATE = 2,
}

enum CreationStep {
    PROPERTY,
    IMAGE,
    EMAIL
}

enum SyndicateActivities {
    Name,
    PropertyTypeCorpNumberPhone,
    Address,
    Picture,
    InvitationNumber,
    InvitationEmail,
}

class SyndicateCreationForm extends React.Component<ISyndicateCreationFormProps, ISyndicateCreationFormState> {

    interval: any;
    imgRef: React.RefObject<ImageUploader>;
    propertyLabels = [Dictionary.none, Dictionary.private, Dictionary.rental]


    constructor(props: any) {
        super(props);

        this.imgRef = React.createRef();
        let lastState = JSON.parse(localStorage.getItem('createSyndicateState') || '{"currentActivity":null}') as ISyndicateCreationFormState;

        if (lastState.currentActivity != null) {
            this.state = lastState;
        }
        else {
            this.state = ({
                currentActivity: SyndicateActivities.Name, pType: [PropertyType.NONE, false], syndicate_name: ["", false], corp_num: ['', false],
                street_name: ["", false], city: ["", false], state: ["", false], street_num: [0, false], address: "", picture: new File([""], "undefined"),
                country: ["", false], completeProfile: null, postal_code: ["", false], phone: ["", false], profilePic: "",
                numberSyndicateMembers: ['', false], syndicateMembersEmail: [["", false]], emailConfirmed: false, errors: [], currentCreationStep: CreationStep.PROPERTY
            });
        }
    }

    componentDidUpdate(prevPros: ISyndicateCreationFormProps, prevState: ISyndicateCreationFormState) {
        if (this.state.numberSyndicateMembers[0] !== prevState.numberSyndicateMembers[0]) {
            let emails: [[string, boolean]] = [["", false]]
            for (let i = 1; i < this.state.numberSyndicateMembers[0]; i++) {
                emails.push(["", false]);
            }
            this.setState({ syndicateMembersEmail: emails });
        }
    }

    private handleChange = (event: any) => {
        const { target: { name, value } } = event
        this.setState({ [name]: [value, false] } as Pick<ISyndicateCreationFormState, keyof ISyndicateCreationFormState>, () => {
            this.setState({ errors: [] }, () => {
                localStorage.setItem('createSyndicateState', JSON.stringify(this.state));
            });
        });
    }

    private addressChange = (val: any) => {
        this.setState({ address: val });
    }

    private getPlacesPostCodeById = async (placeId: any) =>
        new Promise((resolve, reject) => {
            if (!placeId) reject("placeId not provided")

            try {
                new window.google.maps.places.PlacesService(
                    document.createElement("div")
                ).getDetails(
                    {
                        placeId,
                        fields: ["address_components"],
                    },
                    details => {
                        let postcode = null
                        if (details !== null && details.address_components !== undefined) {
                            details.address_components.forEach(entry => {
                                if (entry.types[0] === "postal_code") {
                                    postcode = entry.long_name
                                }
                            })
                        }
                        return resolve(postcode)
                    }
                )
            } catch (e) {
                reject(e)
            }
        })

    private addressSelected = (address: string, placeId: string) => {
        const vals = address.split(',')
        vals.forEach((e, i, arr) => {
            if (i !== 0) {
                arr[i] = e.replace(/\s+/g, '');
            }
        });
        this.setState({ address: "", street_name: [vals[0], false], city: [vals[1], false], state: [vals[2], false], country: [vals[3], false] })

        this.getPlacesPostCodeById(placeId).then((val) => {
            this.setState({ postal_code: [val as string, false] })
        })
    }

    private handleEmailsChange = (event: any) => {
        const { target: { name, value } } = event
        let emails = this.state.syndicateMembersEmail;
        emails[name.split('.')[1]] = [value, false];
        this.setState({ syndicateMembersEmail: emails });
        this.setState({ errors: [] })
    }

    private changeActivity = (currentActivity: SyndicateActivities) => {
        switch (currentActivity) {
            case SyndicateActivities.Name:
                SyndicateServiceProvider.VerifySyndicateName(this.state.syndicate_name[0]).then((res: any) => {
                    if (!res.data) {
                        this.setState({ currentActivity: SyndicateActivities.PropertyTypeCorpNumberPhone })
                    } else {
                        this.setState({ syndicate_name: [this.state.syndicate_name[0], true], errors: [ErrorKeys.syndicateNameAlreadyExists] })
                    }
                })
                break;
            case SyndicateActivities.PropertyTypeCorpNumberPhone:
                SignupServiceProvider.checkPhone(this.state.phone[0]).then((res: any) => {
                    if (res.data) {
                        this.setState({ currentActivity: SyndicateActivities.Address })
                    }
                    else {
                        this.setState({ phone: [this.state.phone[0], true], errors: [ErrorKeys.phoneInvalid] });
                    }
                })
                break;
            case SyndicateActivities.Address:
                this.setState({ currentActivity: SyndicateActivities.Picture })
                break;
            case SyndicateActivities.Picture:
                if (this.imgRef !== null && this.imgRef.current !== null) {
                    this.setState({ picture: this.imgRef.current.getImage() })
                }
                this.setState({ currentActivity: SyndicateActivities.InvitationNumber })
                break;
            case SyndicateActivities.InvitationNumber:
                this.setState({ currentActivity: SyndicateActivities.InvitationEmail })
                break;
            case SyndicateActivities.InvitationEmail:
                this.setState({ currentActivity: SyndicateActivities.InvitationEmail })
                break;
        }
    }

    private backActivity = (currentActivity: SyndicateActivities) => {
        switch (currentActivity) {
            case SyndicateActivities.Name:
                this.setState({ currentActivity: SyndicateActivities.Name })
                break;
            case SyndicateActivities.PropertyTypeCorpNumberPhone:
                this.setState({ currentActivity: SyndicateActivities.Name })
                break;
            case SyndicateActivities.Address:
                this.setState({ currentActivity: SyndicateActivities.PropertyTypeCorpNumberPhone })
                break;
            case SyndicateActivities.Picture:
                this.setState({ currentActivity: SyndicateActivities.Address })
                break;
            case SyndicateActivities.InvitationNumber:
                this.setState({ currentActivity: SyndicateActivities.Picture })
                break;
            case SyndicateActivities.InvitationEmail:
                this.setState({ currentActivity: SyndicateActivities.InvitationNumber })
                break;
        }
    }

    private createProperty = (currentStep: CreationStep, propertyId?: number) => {
        let pName = this.PropertyTypeToString(this.state.pType[0]);
        var property: iProperty = {
            name: this.state.syndicate_name[0], phone: this.state.phone[0], property_type: pName, corporation_number: this.state.corp_num[0], address: (this.state.street_num[0].toString() + this.state.street_name[0]),
            city: this.state.city[0], province: this.state.state[0], postal_code: this.state.postal_code[0], country: this.state.country[0]
        }

        switch (currentStep) {
            case CreationStep.PROPERTY:
                SyndicateServiceProvider.CreateProperty(property).then((res: any) => {
                    if (res.successfull) {
                        let propertyID = res.data.id;
                        this.setState({ currentCreationStep: CreationStep.IMAGE });
                        this.createProperty(CreationStep.IMAGE, propertyID)
                    }
                });
                break;
            case CreationStep.IMAGE:
                if (this.state.picture.name === "undefined") {
                    this.createProperty(CreationStep.EMAIL, propertyId)
                    break;
                }
                SyndicateServiceProvider.SendImage(this.state.picture, this.state.syndicate_name[0]).then((successfull: any) => {
                    if (successfull) {
                        if (this.state.syndicateMembersEmail.length === 1 && this.state.syndicateMembersEmail[0][0] === '') {
                            this.props.exitForm(Activities.Syndicate);
                            return;
                        }
                        this.setState({ currentCreationStep: CreationStep.EMAIL });
                        this.createProperty(CreationStep.EMAIL, propertyId);
                    }
                })
                break;
            case CreationStep.EMAIL:
                let body: iEmailMassInviteWithRole = {
                    syndicate_id: propertyId === undefined ? -1 : propertyId,
                    role: UserType.admin,
                    invites: []
                };
                this.state.syndicateMembersEmail.map((g: [string, boolean]) => {
                    body.invites.push({ email: g[0], first_name: "", last_name: "" });
                });
                SyndicateServiceProvider.SendMassEmails(body).then((successfull: any) => {
                    if (successfull) {
                        this.props.exitForm(Activities.Syndicate);
                    } else {
                    }
                });
                break;
        }
    }

    private PropertyTypeToString = (pType: PropertyType) => {
        switch (pType) {
            case PropertyType.PRIVATE:
                return "PRIVATE";
            case PropertyType.RENTAL:
                return "RENTAL";
            default:
                return "None";
        }
    }

    private exitForm = () => {
        this.clearLocalStorage();
        this.props.exitForm(Activities.Syndicate);
    }

    private clearLocalStorage = () => {
        localStorage.setItem('createSyndicateState', '{"currentActivity":null}');
    }

    private NameContent = () => {
        var nextVisible = this.state.syndicate_name[0] !== "";
        return (
            <div className="contentContainer name animFadeInRightBig">
                <div className="infoContainer">
                    <h1><Translations value={Dictionary.whatSundicateName}></Translations></h1>
                    <span><Translations value={Dictionary.youWillBecomeSuperAdmin}></Translations></span>
                    <DynamicInput inputName={Dictionary.enterSyndicateNameHere} type="text" size={Size.large} handleChange={this.handleChange} value={this.state.syndicate_name[0]} inputStateName={"syndicate_name"} error={this.state.syndicate_name[1]}></DynamicInput>
                </div>
                <div className={"oneButton right"}>
                    {nextVisible ?
                        <ArrowButton text={Dictionary.nextStep} onClick={() => this.changeActivity(SyndicateActivities.Name)} /> : <div />}
                </div>
            </div>
        )
    }

    private PropertyTypeCorpNumberPhoneContent = () => {
        var nextVisible = this.state.pType[0] !== PropertyType.NONE && this.state.corp_num[0] !== "" && this.state.phone[0] !== "";
        return (
            <div className="contentContainer email animFadeInRightBig">
                <div className="infoContainer">
                    <h1><Translations value={Dictionary.pleaseEnterEmail} var={[this.state.syndicate_name[0]]}></Translations></h1>
                    <DynamicInput inputName={Dictionary.EnterCorpNum} minVal={0} type="number" size={Size.large} handleChange={this.handleChange} value={this.state.corp_num[0]} inputStateName={"corp_num"} error={this.state.corp_num[1]}></DynamicInput>
                    <DynamicInput inputName={Dictionary.enterYourPhoneHere} type="phone" size={Size.large} handleChange={this.handleChange} value={this.state.phone[0]} inputStateName={"phone"} error={this.state.phone[1]}></DynamicInput>
                    <SelectWrapper name='propertyType' label={Dictionary.propertyType} defaultValueKey={PropertyType.NONE} options={PropertyType} optionsText={this.propertyLabels} handleChange={(val: any) => this.setState({ pType: [val, false] })}></SelectWrapper>
                </div>
                <div className={nextVisible ? "twoButton" : "oneButton"}>
                    <ArrowButton text={Dictionary.back} onClick={() => this.backActivity(SyndicateActivities.PropertyTypeCorpNumberPhone)} left />
                    {nextVisible ?
                        <ArrowButton text={Dictionary.nextStep} onClick={() => this.changeActivity(SyndicateActivities.PropertyTypeCorpNumberPhone)} /> : <div />}
                </div>
            </div>
        )
    }

    private AddressContent = () => {
        var nextVisible = this.state.street_name[0] !== "" && this.state.country[0] !== "" && this.state.state[0] !== "" && this.state.city[0] !== "" && this.state.postal_code[0] !== ""

        return (
            <div className="contentContainer address animFadeInRightBig">
                <div className="infoContainer">
                    <h1><Translations value={Dictionary.letsContinue}></Translations></h1>
                    <span><Translations value={Dictionary.enterSyndicateAddress}></Translations></span>

                    <PlacesAutocomplete value={this.state.address} onChange={this.addressChange} onSelect={this.addressSelected}>
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input {...getInputProps({ placeholder: "address", name: "address" })}></input>

                                <div>
                                    {loading ? <div>loading...</div> : null}
                                    {suggestions.map((suggestion: any) => {
                                        const style = {
                                            backgroundColor: suggestion.active ? "#41b6e6" : "#fff"
                                        }

                                        return <div {...getSuggestionItemProps(suggestion, { style })}>
                                            {suggestion.description}
                                        </div>
                                    })}
                                </div>

                            </div>
                        )}
                    </PlacesAutocomplete>

                    <DynamicInput inputName={Dictionary.enterStreetAddress} type="text" size={Size.large} handleChange={this.handleChange} value={this.state.street_name[0]} inputStateName={"street_name"} error={this.state.street_name[1]}></DynamicInput>
                    <DynamicInput inputName={Dictionary.enterCity} type="text" size={Size.large} handleChange={this.handleChange} value={this.state.city[0]} inputStateName={"city"} error={this.state.city[1]}></DynamicInput>
                    <DynamicInput inputName={Dictionary.enterState} type="text" size={Size.large} handleChange={this.handleChange} value={this.state.state[0]} inputStateName={"state"} error={this.state.state[1]}></DynamicInput>
                    <DynamicInput inputName={Dictionary.enterPostalCode} type="text" size={Size.large} handleChange={this.handleChange} value={this.state.postal_code[0]} inputStateName={"postal_code"} error={this.state.postal_code[1]}></DynamicInput>
                    <DynamicInput inputName={Dictionary.enterCountry} type="text" size={Size.large} handleChange={this.handleChange} value={this.state.country[0]} inputStateName={"country"} error={this.state.country[1]}></DynamicInput>
                </div>
                <div className={nextVisible ? "twoButton" : "oneButton"}>
                    <ArrowButton text={Dictionary.back} onClick={() => this.backActivity(SyndicateActivities.Address)} left />
                    {nextVisible ? <ArrowButton text={Dictionary.nextStep} onClick={() => this.changeActivity(SyndicateActivities.Address)} /> : <div />}
                </div>
            </div>
        )
    }

    private PictureContent = () => {
        var nextVisible = true
        return (
            <div className="contentContainer profilePic animFadeInRightBig">
                <div className="infoContainer">
                    <h1><Translations value={Dictionary.letsContinue}></Translations></h1>
                    <span><Translations value={Dictionary.pleaseDragProfilePic}></Translations></span>
                    <ImageUploader ref={this.imgRef} lastPic={this.state.picture}></ImageUploader>
                </div>
                <div className={nextVisible ? "twoButton" : "oneButton"}>
                    <ArrowButton text={Dictionary.back} onClick={() => this.backActivity(SyndicateActivities.Picture)} left />
                    {nextVisible ? <ArrowButton text={Dictionary.nextStep} onClick={() => this.changeActivity(SyndicateActivities.Picture)} /> : <div />}
                </div>
            </div>
        )
    }

    private InvitationNumberContent = () => {
        var nextVisible = this.state.numberSyndicateMembers[0] !== ''
        let sendInvites = this.state.numberSyndicateMembers[0] != 0
        return (
            <div className="contentContainer emerCont animFadeInRightBig">
                <div className="infoContainer">
                    <h1><Translations value={Dictionary.addTheSyndicateMembers}></Translations></h1>
                    <span><Translations value={Dictionary.howManyMemberInAdmin}></Translations></span>
                    <DynamicInput inputName={Dictionary.enterNumberMembersHere} type="number" minVal={0} size={Size.large} handleChange={this.handleChange}
                        value={this.state.numberSyndicateMembers[0]} inputStateName={"numberSyndicateMembers"} error={this.state.numberSyndicateMembers[1]}></DynamicInput>
                </div>
                <div className={nextVisible ? "twoButton" : "oneButton"}>
                    <ArrowButton text={Dictionary.back} onClick={() => this.backActivity(SyndicateActivities.InvitationNumber)} left />
                    {nextVisible ? <ArrowButton text={sendInvites ? Dictionary.nextStep : Dictionary.createProperty} onClick={() => sendInvites ? this.changeActivity(SyndicateActivities.InvitationNumber) : this.createProperty(this.state.currentCreationStep)} /> : <div />}
                </div>
            </div>
        )
    }

    private InvitationEmailContent = () => {
        let items = []

        for (let i = 0; i < this.state.numberSyndicateMembers[0]; i++) {
            let hasEmail = this.state.syndicateMembersEmail[i] ? this.state.syndicateMembersEmail[i][1] : false;
            let hasValue = this.state.syndicateMembersEmail[i] ? this.state.syndicateMembersEmail[i][0] : "";

            items.push(<DynamicInput key={i} inputName={Dictionary.memberNumberXEmail} var={[(i + 1).toString()]} type="email" size={Size.large} handleChange={this.handleEmailsChange}
                value={hasValue} inputStateName={"syndicateMembersEmail." + i} error={hasEmail} idInc={i}></DynamicInput>);
        }

        var nextVisible = this.state.syndicateMembersEmail.reduce((sum, next: any) => sum && next[0] !== "", true);

        return (
            <div className="contentContainer emerCont animFadeInRightBig">
                <div className="infoContainer">
                    <h1><Translations value={Dictionary.addTheSyndicateMembers}></Translations></h1>
                    <span><Translations value={Dictionary.sendInvitationsSyndicateMembers}></Translations></span>
                    <div className="emailList">
                        {items}
                    </div>
                </div>
                <div className={nextVisible ? "twoButton" : "oneButton"}>
                    <ArrowButton text={Dictionary.back} onClick={() => this.backActivity(SyndicateActivities.InvitationEmail)} left />
                    {nextVisible ?
                        <ArrowButton text={Dictionary.createProperty} onClick={() => this.createProperty(this.state.currentCreationStep)} /> : <div />}
                </div>
            </div>
        )
    }

    private bodyContent = () => {
        switch (this.state.currentActivity) {
            case SyndicateActivities.Name:
                return this.NameContent();
            case SyndicateActivities.PropertyTypeCorpNumberPhone:
                return this.PropertyTypeCorpNumberPhoneContent();
            case SyndicateActivities.Address:
                return this.AddressContent();
            case SyndicateActivities.Picture:
                return this.PictureContent();
            case SyndicateActivities.InvitationNumber:
                return this.InvitationNumberContent();
            case SyndicateActivities.InvitationEmail:
                return this.InvitationEmailContent();
        }
    }

    public render() {
        return (
            <div id="FormBodyContainer">
                <ProgressBar now={this.state.currentActivity} min={0} max={6} striped animated variant={"info"} />
                <h1 className="h1-header animate__animated animate__bounceInLeft"><Translations value={Dictionary.creatingSyndicate}></Translations></h1>
                <div id="FormContainer">
                    {this.bodyContent()}
                    <div>
                        <ErrorList values={this.state.errors}></ErrorList>
                    </div>
                    <ArrowButton text={Dictionary.cancel} onClick={this.exitForm} left />
                </div>
            </div>
        )
    }
}

export default SyndicateCreationForm