import * as React from 'react';
import { DataGrid, GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Avatar } from '@mui/material';
import ImageLoader from 'Ressources/Images/ImageLoader';

export interface IUsersTableProps {
    admins: any[]
    residents: any[]
    employees: any[]
}

export interface IUsersTableState {
    users: any[]
}

export default class UsersTable extends React.Component<IUsersTableProps, IUsersTableState> {
    constructor(props: IUsersTableProps) {
        super(props);

        this.state = {
            users: []
        }
    }

    componentDidMount = () => {
        this.updateUsersList();
    }

    componentDidUpdate = (prevProps: IUsersTableProps) => {
        if (prevProps !== this.props) {
            this.updateUsersList();
        }
    }

    private updateUsersList = () => {
        var id: number = 0;
        var list: any[] = [];
        this.props.admins?.map((user: any) => {
            list.push({
                picture: user.user.first_name.charAt(0) + user.user.last_name.charAt(0),
                lastName: user.user.last_name,
                firstName: user.user.first_name,
                unitNumber: user.unit,
                role: "Admin",
                id: id++
            })
        })

        this.props.residents?.map((user: any) => {
            list.push({
                picture: "",
                lastName: user.user.last_name,
                firstName: user.user.first_name,
                unitNumber: user.unit,
                role: user.user.vacant ? "Vacant" : "Resident",
                id: id++
            })
        })

        this.props.employees?.map((user: any) => {
            list.push({
                picture: "",
                lastName: user.user.last_name,
                firstName: user.user.first_name,
                unitNumber: user.unit,
                role: "Employee",
                id: id++
            })
        })

        this.setState({ users: list });
    }

    private RenderPicture = (params: GridCellParams) => {
        if (params.value == "") {
            return (
                <ImageLoader name='EmptyIcon' className='EmptyIcon'></ImageLoader>
            )
        } else {
            return (
                <Avatar>{params.value}</Avatar>
            )
        }
    }

    public render() {
        const columns: GridColDef[] = [
            { field: 'picture', headerName: '', minWidth: 70, width: 70, renderCell: (params: GridCellParams) => (this.RenderPicture(params)), sortable: false, disableColumnMenu: true, hideable: false },
            { field: 'unitNumber', headerName: 'Unit number', type: 'number', minWidth: 100, width: 100, disableColumnMenu: true, hideable: false },
            { field: 'role', headerName: 'Role', minWidth: 80, width: 80, disableColumnMenu: true, hideable: false },
            { field: 'firstName', headerName: 'First name', minWidth: 130, flex: 1, resizable: true, filterable: true, hideable: false },
            { field: 'lastName', headerName: 'Last name', minWidth: 130, flex: 1, resizable: true, hideable: false },
        ];

        return (
            <div style={{ height: 400, width: '90%', margin: "30px 0px 0px 0px" }}>
                <DataGrid
                    rows={this.state.users}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    loading={this.state.users.length == 0}
                />
            </div>
        );
    }
}
