import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import "./OptionsMenu.scss"

interface IProps {
}

interface IState {
    active: boolean
}

class OptionsMenu extends React.Component<IProps, IState> {

    state = {
        active: false
    }

    private toggleActive = () => {
        this.setState({ active: !this.state.active })
    }

    public render() {
        return (
            <div className={this.state.active ? "menu open" : "menu"} onClick={() => this.setState({ active: !this.state.active })}>
                <ImageLoader className='menuButton unselectable' name='EditUserIcon'></ImageLoader>
                <ImageLoader className='menuButton unselectable' name='GearsIcon'></ImageLoader>
                <ImageLoader className='menuButton unselectable' name='DeleteIcon'></ImageLoader>
            </div>
        )
    }
}

export default OptionsMenu