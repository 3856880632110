import iCondo from 'Models/Condo';
import { iAdmin } from 'Models/User';
import * as React from 'react';
import MediaType from 'Ressources/MediaType';
import AdminInfo from '../../sub-components/AdminInfo';
import "../../SyndicatePage.scss"
import SyndicateServiceProvider, { USER_TYPES } from '../../SyndicateServiceProvider';
import SyndicateCoOwnersList_PC from './SyndicateCoOwnersList_PC';
import SyndicateCoOwnersList_Phone from './SyndicateCoOwnersList_Phone';

interface IProps {
    changeState: any
    currentCondo: iCondo
    mediaType: MediaType
}

interface IState {
    key: string
    adminList: Array<iAdmin>
}

class SyndicateCoOwnersList extends React.Component<IProps, IState> {

    componentDidMount = () => {
        SyndicateServiceProvider.GetPropertyUsers(this.props.currentCondo.id, USER_TYPES.residents).then((res: any) => {
            this.setState({ adminList: res.data.residents as Array<iAdmin> })
        })
    }

    private renderAdmins = () => {
        if (this.state) {
            let admins = this.state.adminList.map((admin: iAdmin) => {
                return (
                    <React.Fragment>
                        <AdminInfo admin={admin}></AdminInfo>
                    </React.Fragment>
                )
            })

            return admins;
        } else {
            return (
                <div>Loading...</div>
            )
        }
    }

    public render() {
        if (this.props.mediaType === MediaType.Phone) {
            return (
                <SyndicateCoOwnersList_Phone renderAdmins={this.renderAdmins} {...this.props}></SyndicateCoOwnersList_Phone>
            )
        }
        if (this.props.mediaType === MediaType.PC || this.props.mediaType === MediaType.Tablet) {
            return (
                <SyndicateCoOwnersList_PC renderAdmins={this.renderAdmins}{...this.props}></SyndicateCoOwnersList_PC>
            )

        }
    }
}

export default SyndicateCoOwnersList