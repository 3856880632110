import * as React from 'react';

export enum Languages {
    FR,
    EN,
}

export const settings = {
    language: Languages
}

const SettingsContext = React.createContext({
    language: Languages.EN,
    toggleLanguage: (val: Languages) => { }
});

export default SettingsContext