import axios, { AxiosResponse } from "axios";
import LoginProvider from "GlobalComponents/ServiceProvider";
import { iProperty } from "../../Models/Condo";

export enum USER_TYPES {
    employees = "employees",
    admins = "admins",
    residents = "residents"
}
export default class SyndicateServiceProvider extends LoginProvider {

    static async CreateProperty(property: iProperty) {
        let res: any = await axios.post(this.path + 'api/create-property', property, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
    }

    static async SendImage(file: File, syndicateName: string) {
        let res: any = await axios.post(this.path + "api/set-picture/" + syndicateName, "image:" + file, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                'Content-Type': 'text/plain',
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
    }

    static async SendMassEmails(body: any) {
        let res: any = await axios.post(this.path + "api/email-invite", body, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
    }

    static async GetPropertyUsers(property_id: number, target: USER_TYPES) {
        let body = {
            property_id: property_id,
            target: target
        }

        let res: any = await axios.post(this.path + "api/get-property-users", body, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
    }

    static async GetPropertyList() {
        let res: any = await axios.get(this.path + "api/get-user-properties", {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
        return { successfull: false };
    }

    static async GenerateAccessKey(property_id: number) {
        let body = {
            "property_id": property_id,
        }

        let res: any = await axios.post(this.path + "api/generate-property-token", body, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
    }

    static async ParseExcel(file: File) {
        let res: any = await axios.post(this.path + "api/parse-excel", file, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                'Content-Type': 'text/plain',
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            console.log(res);
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
    }

    static async VerifySyndicateName(name: string) {
        let res: any = await axios.get(this.path + "api/check-property/" + name, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            console.log(res);
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
    }
}