export default class PageStates {
    constructor() {
        this.activityContainer = ""
        this.syndicatePage = ""
        this.loginPage = ""
    }

    public defaultLoginState = () => {
        return '"{"currentActivity":0,"first_name":["",false],"last_name":["",false],"email":["",false],"password":["",false],"telephone":["",false],"language":"","emergencyName":["",false],"emergencyPhone":["",false],"emailConfirmed":false,"picture":{},"userType":"","propertyAlreadyAdded":false,"errors":[]}"';
    }

    activityContainer: any
    syndicatePage: any
    loginPage: any
}