import iCondo from 'Models/Condo';
import { iEmailMassInvite } from 'Models/EmailMassInvite';
import iMassInviteData, { MassInviteStatus } from 'Models/MassInviteData';
import * as React from 'react';
import MediaType from 'Ressources/MediaType';
import ErrorKeys from 'Ressources/translations/Errors/ErrorKeys';
import "../../SyndicatePage.scss"
import SyndicateServiceProvider from '../../SyndicateServiceProvider';
import SyndicateGroupInviteSelector from './SyndicateGroupInviteSelector';
import SyndicateGroupInvite_PC from './SyndicateGroupInvite_PC';
import SyndicateGroupInvite_Phone from './SyndicateGroupInvite_Phone';

interface IProps {
    handleChange: any
    changeState: any
    separator: string
    groupEmail: string
    currentCondo: iCondo
    mediaType: MediaType
}

interface IState {
    files: File[]
    parsedExcel: iMassInviteData[]
    showSelector: boolean
    invites: any
}

class SyndicateGroupInvite extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            files: [],
            parsedExcel: [],
            showSelector: false,
            invites: {}
        }
    }

    handleDrop = (files: any) => {
        this.setState({ files: files })
    }

    private ParseExcel = () => {
        return SyndicateServiceProvider.ParseExcel(this.state.files[0]).then((res: any) => {
            if (res.successfull) {
                let parsedExcel: iMassInviteData[] = [];
                let data: any[] = Object.values(res.data.rows);
                for (let i = 0; i < data.length; i++) {
                    let massInviteData: iMassInviteData = {
                        data: data[i],
                        id: i,
                        status: MassInviteStatus.NotSent,
                        errors: [],
                        isInEditMode: false
                    };
                    parsedExcel.push(massInviteData);
                }
                this.setState({ parsedExcel: parsedExcel, showSelector: true })
            }
        })
    }

    private ToggleEditCell = (param: any) => {
        param.row.isInEditMode = !param.row.isInEditMode;
    }

    private EditCell = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, param: any, index: number) => {
        param.row.data[param.field] = event.target.value;
        this.forceUpdate();
    }

    private DeleteRow = (param: any) => {
        let parsedExcel = [...this.state.parsedExcel];
        let searchedElement = parsedExcel.find(e => e.id == param.id);
        if (searchedElement) {
            let index = parsedExcel.indexOf(searchedElement);
            parsedExcel.splice(index, 1);
            this.setState({ parsedExcel: parsedExcel });
        }
    }

    private SendInvite = (headers: any[], valuesArray: any[]) => {
        let invites: any[] = [];
        valuesArray.forEach(values => {
            invites.push(this.CreateInviteEntry(headers, values))
        });

        let body: iEmailMassInvite = {
            syndicate_id: this.props.currentCondo.id,
            invites: invites
        }

        SyndicateServiceProvider.SendMassEmails(body).then((res: any) => {
            if (res.successfull) {
                let excel = this.state.parsedExcel;

                if (res.data.in_use.length > 0) {
                    excel.forEach((element: iMassInviteData) => {
                        if (res.data.in_use.includes(element.data[2])) {
                            element.status = MassInviteStatus.InUse
                        }
                    });
                }

                if (res.data.sent.length > 0) {
                    excel.forEach((element: iMassInviteData) => {
                        if (res.data.sent.includes(element.data[2])) {
                            element.status = MassInviteStatus.Sent
                        }
                    });
                }

                this.setState({ parsedExcel: excel })
            }
        });
    }

    private CreateInviteEntry(headers: any[], values: any) {
        let obj: any = new Object;

        headers.forEach((header: any) => {
            switch (header) {
                case 1:
                    obj["first_name"] = values.data[headers.indexOf(1)];
                    break;
                case 2:
                    obj["last_name"] = values.data[headers.indexOf(2)];
                    break;
                case 3:
                    obj["email"] = values.data[headers.indexOf(3)];
                    break;
                case 4:
                    obj["unit"] = values.data[headers.indexOf(4)];
                    break;
                case 5:
                    obj["voting_percent"] = values.data[headers.indexOf(5)];
                    break;
            }
        });

        return obj;
    }

    private ValidateOnlyOneFile = (files: File[]) => {
        return files.length === 1 ? -1 : ErrorKeys.onlyOneFileAllowed
    }

    private ValidateExcelFile = (files: File[]) => {
        return files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? -1 : ErrorKeys.onlyExcelFilesAllowed
    }

    public render() {
        if (this.props.mediaType === MediaType.Phone) {
            return (
                <SyndicateGroupInvite_Phone sendInvite={this.SendInvite} {...this.props}></SyndicateGroupInvite_Phone>
            )
        }
        if (this.props.mediaType === MediaType.PC || this.props.mediaType === MediaType.Tablet) {
            if (this.state.showSelector) {
                return (
                    <SyndicateGroupInviteSelector sendInvite={this.SendInvite} ToggleEditCell={this.ToggleEditCell} EditCell={this.EditCell} DeleteRow={this.DeleteRow} {...this.props} {...this.state} />
                )
            } else {
                return (
                    <SyndicateGroupInvite_PC ParseExcel={this.ParseExcel} handleDrop={this.handleDrop} ValidateOnlyOneFile={this.ValidateOnlyOneFile} ValidateExcelFile={this.ValidateExcelFile} {...this.props} />
                )
            }
        }
    }
}

export default SyndicateGroupInvite