import LeftHighlightButton from 'GlobalComponents/Buttons/LeftHighlightButton';
import iCondo from 'Models/Condo';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import Activities from '../../Ressources/Activities';
import { Dictionary, Translations } from '../../Ressources/translations/Translations';
import "./NavigatorBar.scss"

interface IProps {
    changeActivity: Function
    navigationBarClosed: boolean
    closeNavigationMenu: any
    currentActivity: Activities
    condoList: iCondo[]
}

interface IState {
    hoverInTimeout: NodeJS.Timeout | undefined
    hoverOutTimeout: NodeJS.Timeout | undefined
    locked: boolean
    hasSyndicates: boolean
}

class NavigatorBar extends React.Component<IProps, IState> {



    constructor(props: IProps) {
        super(props);

        this.state = {
            hoverInTimeout: undefined,
            hoverOutTimeout: undefined,
            locked: true,
            hasSyndicates: props.condoList.length !== 0,
        }
    }

    logout = () => {
        localStorage.removeItem("username");
        localStorage.removeItem("password");
        this.props.changeActivity(Activities.Login);
    }

    componentDidUpdate = (prevProps: IProps) => {
        if (prevProps.navigationBarClosed !== this.props.navigationBarClosed) {
            if (this.props.navigationBarClosed) {
                let div = document.getElementsByClassName("navigationContainer")[0];
                div.classList.remove('open')
                div.classList.add('closed')
            }
            else {
                let div = document.getElementsByClassName("navigationContainer")[0];
                div.classList.remove('closed')
                div.classList.add('open')
            }
        }
    }

    private hoverAreaEnter = () => {
        if (this.state && this.props.navigationBarClosed) {
            var timeout = setTimeout(() => {
                this.props.closeNavigationMenu()
            }, 800)
            this.setState({ hoverInTimeout: timeout })
        }
    }

    private hoverAreaLeave = () => {
        if (this.state && this.props.navigationBarClosed && this.state.hoverInTimeout) {
            clearTimeout(this.state.hoverInTimeout);
            this.setState({ hoverInTimeout: undefined })
        }
    }

    private arrowClicked = () => {
        this.props.closeNavigationMenu()
        this.setState({ locked: !this.state.locked })
        this.contentEnter();
    }

    private contentEnter = () => {
        if (this.state && !this.state.locked && !this.props.navigationBarClosed && this.state.hoverOutTimeout) {
            clearTimeout(this.state.hoverOutTimeout);
            this.setState({ hoverOutTimeout: undefined })
        }
    }

    private contentLeave = (e: any) => {
        if (e.relatedTarget.className === "hoverArea")
            return
        if (this.state && !this.state.locked && !this.props.navigationBarClosed && this.state.hoverOutTimeout == undefined) {
            var timeout = setTimeout(() => {
                this.props.closeNavigationMenu()
            }, 800)
            this.setState({ hoverOutTimeout: timeout })
        }
    }

    public render() {
        return (
            <React.Fragment>
                <div className="navigationContainer" onMouseEnter={this.contentEnter} onMouseLeave={(e: any) => this.contentLeave(e)}>
                    <div className="navigationSubContainer">
                        <div className="navigationButtons">
                            <LeftHighlightButton className={this.props.currentActivity == Activities.Syndicate ? "Selected" : ""} width={"100%"} onClick={() => this.props.changeActivity(Activities.Syndicate)} basicText={Dictionary.syndicate} loadingText={Dictionary.syndicate} doneText={Dictionary.syndicate} />
                            {this.state.hasSyndicates ?
                                <React.Fragment>
                                    <LeftHighlightButton className={this.props.currentActivity == Activities.Management ? "Selected" : ""} width={"100%"} onClick={() => this.props.changeActivity(Activities.Management)} basicText={Dictionary.management} loadingText={Dictionary.management} doneText={Dictionary.management} />
                                    <LeftHighlightButton className={this.props.currentActivity == Activities.Home ? "Selected" : ""} width={"100%"} onClick={() => this.props.changeActivity(Activities.Home)} basicText={Dictionary.home} loadingText={Dictionary.home} doneText={Dictionary.home} />
                                    <LeftHighlightButton className={this.props.currentActivity == Activities.Accounting ? "Selected" : ""} width={"100%"} onClick={() => this.props.changeActivity(Activities.Accounting)} basicText={Dictionary.accounting} loadingText={Dictionary.accounting} doneText={Dictionary.accounting} />
                                    <LeftHighlightButton className={this.props.currentActivity == Activities.Communications ? "Selected" : ""} width={"100%"} onClick={() => this.props.changeActivity(Activities.Communications)} basicText={Dictionary.communications} loadingText={Dictionary.communications} doneText={Dictionary.communications} />
                                    <LeftHighlightButton className={this.props.currentActivity == Activities.Calendar ? "Selected" : ""} width={"100%"} onClick={() => this.props.changeActivity(Activities.Calendar)} basicText={Dictionary.calendar} loadingText={Dictionary.calendar} doneText={Dictionary.calendar} />
                                    <LeftHighlightButton className={this.props.currentActivity == Activities.Reservations ? "Selected" : ""} width={"100%"} onClick={() => this.props.changeActivity(Activities.Reservations)} basicText={Dictionary.reservations} loadingText={Dictionary.reservations} doneText={Dictionary.reservations} />
                                    <LeftHighlightButton className={this.props.currentActivity == Activities.Documents ? "Selected" : ""} width={"100%"} onClick={() => this.props.changeActivity(Activities.Documents)} basicText={Dictionary.documents} loadingText={Dictionary.documents} doneText={Dictionary.documents} />
                                    <LeftHighlightButton className={this.props.currentActivity == Activities.HelpDesk ? "Selected" : ""} width={"100%"} onClick={() => this.props.changeActivity(Activities.HelpDesk)} basicText={Dictionary.helpDesk} loadingText={Dictionary.helpDesk} doneText={Dictionary.helpDesk} />
                                </React.Fragment> : null}

                        </div>
                        <div className="otherButtons">
                            <ImageLoader name="LogoutIcon" className="icon" onClick={this.logout}></ImageLoader>
                            <ImageLoader name="GearsIcon" className="icon"></ImageLoader>
                        </div>
                    </div>
                </div>
                <div className='hoverArea' onMouseEnter={this.hoverAreaEnter} onMouseLeave={this.hoverAreaLeave}></div>
            </React.Fragment>

        )
    }
}

export default NavigatorBar