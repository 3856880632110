import axios from "axios";
import { iReservation, iReservationFetcher } from "Models/Reservation";
import { EnumDeclaration } from "typescript";

const path = 'https://connect.smartcondomanager.com/';

const CalendarServiceProvider = {

    getPropertyAmenities: async function (propertyId: number) {
        let payload = {
            "property_id": propertyId
        }
        return axios.post(path + "api/get-amenities", payload, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch(function (error: any) {
            console.log(error);
        });
    },

    createReservation: async function (reservation: iReservationFetcher) {
        let payload = {
            "amenity_id": reservation.amenityId,
            "start_time": reservation.startTime.toISOString().substring(0, 16),
            "end_time": reservation.endTime.toISOString().substring(0, 16),
            // "recurring": {
            //     "end_date": "2099-01-01T00:00",
            //     "monthly": "false",
            //     "period": 10080
            // }
        }
        return axios.post(path + "api/create-reservation", payload, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch(function (error: any) {
            console.log(error);
        });
    },

    getReservations: async function (reservation: iReservationFetcher) {
        console.log(reservation.endTime.toISOString());
        let payload = {
            "amenity_id": reservation.amenityId,
            "start_time": reservation.startTime.toISOString().substring(0, 16),
            "end_time": reservation.endTime.toISOString().substring(0, 16)
        }

        return axios.post(path + "api/get-reservations", payload, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch(function (error: any) {
            console.log(error);
        });
    },

    deleteReservation: async function (reservationId: number) {
        let payload = {
            "reservation_id": reservationId,
        }

        return axios.post(path + "api/delete-reservation", payload, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch(function (error: any) {
            console.log(error);
        });
    },

    editReservation: async function (reservation: iReservation) {
        let payload = {
            "reservation_id": reservation.reservationId,
            "start_time": reservation.startTime.toISOString().substring(0, 16),
            "end_time": reservation.endTime.toISOString().substring(0, 16)
        }

        return axios.post(path + "api/update-reservation", payload, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch(function (error: any) {
            console.log(error);
        });
    },
}

export default CalendarServiceProvider