import * as React from 'react';
import "./Header.scss"
import Activities from '../../Ressources/Activities';
import SettingsContext, { Languages } from '../SettingsContext';
import ImageLoader, { Extensions } from '../../Ressources/Images/ImageLoader';
import SignupServiceProvider from 'components/Login/signupForm/SignupServiceProvider';
import SyndicateSelector from 'GlobalComponents/SyndicateSelector';
import iCondo from 'Models/Condo';
import MediaType from 'Ressources/MediaType';


interface IProps {
    changeActivity: Function
    getPicture: Function
}

interface IState {
}

class Header_Phone extends React.Component<IProps, IState> {

    public render() {
        return (
            <div className="headerContainer">
                <ImageLoader name="Logo" className="TopMenuLogo" onClick={() => this.props.changeActivity(Activities.Home)}></ImageLoader>
                {this.props.getPicture()}
            </div>
        )
    }
}

export default Header_Phone