import * as React from 'react';
import { ChangeEventHandler } from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import TicketProvider from './TicketProvider';

export interface ITicketLeaveCommentSectionProps {
    ticketId: number
    GetPosts: Function
}

export interface ITicketLeaveCommentSectionState {
    commentContent: string
}

export default class TicketLeaveCommentSection extends React.Component<ITicketLeaveCommentSectionProps, ITicketLeaveCommentSectionState> {
    constructor(props: ITicketLeaveCommentSectionProps) {
        super(props);

        this.state = {
            commentContent: ""
        }
    }

    private CreatePost = () => {
        TicketProvider.CreatePostTicket(this.props.ticketId, this.state.commentContent).then(() => {
            this.setState({ commentContent: "" });
            this.props.GetPosts();
        });
    }

    private OnChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        this.setState({ commentContent: e.target.value });
    }

    public render() {
        return (
            <div className='LeaveCommentContainer'>
                <textarea onChange={this.OnChange} className="CommentInput" placeholder='Leave your comment here...' value={this.state.commentContent}></textarea>
                <ImageLoader className='SendButton' name='SendIcon' onClick={this.CreatePost}></ImageLoader>
            </div>
        );
    }
}
