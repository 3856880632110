import SignupServiceProvider from 'components/Login/signupForm/SignupServiceProvider';
import DynamicRadioInput, { iRadioData } from 'GlobalComponents/Inputs/DynamicRadioInput';
import * as React from 'react';
import { Input } from 'reactstrap';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import ForumComments from '../ForumComments/ForumComments';

interface IProps {
    content: any
    onPollChange: Function
    addComment: Function
}

interface IState {
    picture: any
}

class PollForumPost extends React.Component<IProps, IState> {

    state = {
        picture: "",
    }

    getPicture = () => {
        return (
            <img src={"data:image/jpeg;base64," + this.state.picture} alt="No picture" className="PostPicture"></img>
        )
    }

    renderContent = () => {
        let radioValues: iRadioData[] = this.props.content.options.map((option: any) => {
            return {
                value: option.name,
                checked: option.checked,
                text: option.name,
                onChange: this.props.onPollChange
            }
        });

        return (
            <React.Fragment>
                <DynamicRadioInput name='pollOptions' value={radioValues} type={this.props.content.optionsType}></DynamicRadioInput>
            </React.Fragment>
        )
    }

    componentDidMount = () => {
        let username = localStorage.getItem('username');
        SignupServiceProvider.getPictureEmail(username ?? "").then((e: any) => {
            if (e) {
                const buf = Buffer.from(e.data, 'binary').toString('base64');
                this.setState({ picture: buf })
            }
        })
    }

    public render() {
        return (
            <div className="ForumPostContainer">
                <div className="ForumPostHeader">
                    {this.getPicture()}
                    <div>
                        <div className='name'>{this.props.content.username}</div>
                        <div className='time'>{this.props.content.time}</div>
                    </div>
                </div>
                <div className='ForumPostContent'>
                    {this.renderContent()}
                </div>
                <ForumComments {...this.props}></ForumComments>
            </div>
        )
    }
}

export default PollForumPost