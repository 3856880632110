import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import UserRoleSelect from 'GlobalComponents/GodMode/UserRoleSelect';
import iCondo from 'Models/Condo';
import { UserType } from 'Models/User';
import * as React from 'react';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
export interface ISyndicateIndividualInviteSelectorProps {
    changeSelectedCondo: Function
    addUnit: Function
    selectedCondo: any
    currentCondo: iCondo
    userRole: UserType
    units: any[]
    HandleChangeUnitNumber: (event: SelectChangeEvent<string>, child: React.ReactNode) => void
    unitNumber: string
}
export interface ISyndicateIndividualInviteSelectorState {

}
export default class SyndicateIndividualInviteSelector extends React.Component<ISyndicateIndividualInviteSelectorProps, ISyndicateIndividualInviteSelectorState> {
    constructor(props: ISyndicateIndividualInviteSelectorProps) {
        super(props);
        this.state = {
        }
    }

    private menuItems = () => {
        let items: any[] = [];
        this.props.units.forEach((element: any) => {
            items.push(<MenuItem value={element.unit_number} key={element.unit_id}> {element.unit_number}</MenuItem>)
        });

        return items;
    }

    public render() {
        return (
            <React.Fragment>
                <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                    <InputLabel id="userTypeLabelID"><Translations value={Dictionary.UnitNumber} /></InputLabel>
                    <Select
                        labelId="userTypeLabelID"
                        id="userType"
                        value={this.props.unitNumber}
                        onChange={this.props.HandleChangeUnitNumber}
                        label={<Translations value={Dictionary.UnitNumber} />}
                    >
                        <MenuItem disabled hidden value={0}></MenuItem>
                        {this.menuItems()}
                    </Select>
                </FormControl>
            </React.Fragment>
        )
    }
}