/*eslint no-template-curly-in-string: "off"*/
import * as React from 'react';
import SettingsContext from '../../../GlobalComponents/SettingsContext';
import TranslationEN from './en'
import TranslationFR from './fr'
import { Languages } from '../../../GlobalComponents/SettingsContext'
import ErrorKeys from './ErrorKeys';

interface IProps {
    values: ErrorKeys[]
    var?: string[]
}

interface IState {
    language: Languages
}

export class ErrorList extends React.Component<IProps, IState> {

    state = {
        language: Languages.FR
    }

    private replaceVars = (retVal: string) => {
        if (this.props.var) {
            this.props.var.map((val: string, i: number) => {
                retVal = retVal.replace("${" + i + "}", this.props.var ? this.props.var[i] : "");
            })
        }
        return retVal
    }

    public getString = (key: ErrorKeys) => {
        let retValue = ""
        switch (this.context.language) {
            case Languages.EN:
                retValue = TranslationEN[key];
                break;
            case Languages.FR:
                retValue = TranslationFR[key];
                break;
            default:
                break;
        }
        if (this.props.var)
            retValue = this.replaceVars(retValue);

        return retValue;
    }

    public render() {
        return (
            this.props.values.map((val: ErrorKeys) => (
                <li key={val} className="errorMessage">{this.getString(val)}</li>
            ))
        )
    }
}

ErrorList.contextType = SettingsContext;
