import SignupServiceProvider from 'components/Login/signupForm/SignupServiceProvider';
import { iAdmin } from 'Models/User';
import * as React from 'react';
import Activities from 'Ressources/Activities';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Buffer } from 'buffer';
import "./AdminInfo.scss"

interface IProps {
    admin: iAdmin
}

interface IState {
    picture: any
}

class SyndicatePage extends React.Component<IProps, IState> {

    state = {
        picture: ""
    }

    getPicture = () => {
        if (this.state.picture !== "") {
            return (
                <img src={"data:image/jpeg;base64," + this.state.picture} alt="No picture" className="ProfilePic"></img>
            )
        }
        else {
            return (
                <div></div>
            )
        }
    }

    componentDidMount = () => {
        let username = localStorage.getItem('username');
        SignupServiceProvider.getPictureEmail(username ?? "").then((e: any) => {
            if (e) {
                const buf = Buffer.from(e.data, 'binary').toString('base64');
                this.setState({ picture: buf })
            }
        })
    }

    public render() {
        return (
            <div className="AdminContainer">
                <div className="emptyItem"></div>
                <div>
                    {this.getPicture()}
                    <span>{this.props.admin.user.first_name + "  " + this.props.admin.user.last_name}</span>
                </div>
                <ImageLoader name="DeleteIcon" className="DeleteIcon" onClick={() => { }}></ImageLoader>
            </div>
        )
    }
}

export default SyndicatePage