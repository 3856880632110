import PromiseButton from 'GlobalComponents/Buttons/PromiseButton';
import DragAndDropArea from 'GlobalComponents/Inputs/DragAndDropArea';
import iCondo from 'Models/Condo';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from '../../../../Ressources/translations/Translations';
import { pageStates } from '../../SyndicatePage';

interface IProps {
    handleChange: any
    handleDrop: Function
    changeState: any
    separator: string
    groupEmail: string
    currentCondo: iCondo
    ValidateOnlyOneFile: Function
    ValidateExcelFile: Function
    ParseExcel: Function
}

interface IState {
}

class SyndicateGroupInvite_PC extends React.Component<IProps, IState> {

    public render() {
        return (
            <div className="IndividualInviteContainer">
                <span><Translations value={Dictionary.inviteUsersJoinSyndicate} var={[this.props.currentCondo.name]}></Translations></span>
                <div className="horizontalDivider full"></div>
                <div className="SubContainer">
                    <div className="title">
                        <ImageLoader name="GroupInviteIcon" className="individualIcon"></ImageLoader>
                        <span><Translations value={Dictionary.groupInvite}></Translations></span>
                    </div>
                    <div className="InputContainer">
                        <DragAndDropArea {...this.props} width={60} height={70} validators={[this.props.ValidateExcelFile, this.props.ValidateOnlyOneFile]}></DragAndDropArea>
                        <PromiseButton onClick={this.props.ParseExcel} basicText={Dictionary.send} loadingText={Dictionary.SendingEmail} doneText={Dictionary.Done}></PromiseButton>
                    </div>
                </div>
            </div>
        )
    }
}

export default SyndicateGroupInvite_PC