import DynamicTimeInput from 'GlobalComponents/Inputs/DynamicTimeInput';
import * as React from 'react';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

interface IProps {
}

interface IState {
}

class Reservation extends React.Component<IProps, IState> {

    public render() {
        return (
            <div>
                <DynamicTimeInput></DynamicTimeInput>
                <DateRangePicker value={[new Date(), new Date()]}></DateRangePicker >
            </div>
        )
    }
}

export default Reservation