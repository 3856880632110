import * as React from 'react';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import "./DynamicRadioInput.scss"

export enum Size {
    small,
    medium,
    large
}

export interface iRadioData {
    value: any,
    checked: boolean,
    text: any,
    onChange: any
}

interface IProps {
    name: string,
    value: iRadioData[]
    error?: boolean
    size?: Size
    type?: string
    selectable?: boolean
}

interface IState {
}

class DynamicRadioInput extends React.Component<IProps, IState> {

    private getClassName = () => {
        switch (this.props.size) {
            case Size.small:
                return "radio__form__group__small";
            case Size.medium:
                return "radio__form__group";
            case Size.large:
                return "radio__form__group__large";
        }
        return "radio__form__group";
    }

    private row = (data: iRadioData) => {
        return (
            <label className="radio__form__field">
                <input type={this.props.type ? this.props.type : "radio"} name={this.props.name} value={data.value} onChange={(e) => data.onChange(e)} checked={data.checked}></input>
                <span className={this.props.selectable ? "" : "unselectable"} >{data.text}</span>
            </label>
        )
    }

    public render() {

        return (
            <div className={this.getClassName() + " radio__field"}>
                {this.props.value.map((data: iRadioData) => {
                    return this.row(data);
                })}
            </div>
        )
    }
}

export default DynamicRadioInput