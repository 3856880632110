import PromiseButton from 'GlobalComponents/Buttons/PromiseButton';
import DynamicInput from 'GlobalComponents/Inputs/DynamicInput';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from 'Ressources/translations/Translations';

interface IProps {
    handleChange: Function
    syndicateKey: string
    sendSyndicateKey: Function
}

interface IState {
}

class SyndicateJoinSyndicate_PC extends React.Component<IProps, IState> {

    public render() {
        return (
            <div className="subContainer">
                <div className="options big">
                    <span><Translations value={Dictionary.joinExistingSyndicate}></Translations></span>
                    <div className="horizontalDivider full"></div>
                    <div className="subOptions">
                        <div className="options input">
                            <div className="title">
                                <ImageLoader name="JoinSyndicateIcon" className="individualIcon"></ImageLoader>
                                <span><Translations value={Dictionary.joinExistingSyndicate}></Translations></span>
                            </div>
                            <div className="inputContainer">
                                <DynamicInput inputName={Dictionary.joinExistingSyndicate} type="text" handleChange={this.props.handleChange} value={this.props.syndicateKey} inputStateName={"syndicateKey"}></DynamicInput>
                                <PromiseButton onClick={this.props.sendSyndicateKey} basicText={Dictionary.send} loadingText={Dictionary.SendingEmail} doneText={Dictionary.Done}></PromiseButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SyndicateJoinSyndicate_PC