import { iReservation } from 'Models/Reservation';
import * as React from 'react';
import "./CalendarReservation.scss"

interface IProps {
    length: number
    startHour: number
    startMin: number
    timeInterval: number
    next: number
    reservation: iReservation
    deleteRes: Function
    editRes: Function
    id: number
    openPopup: Function
}

interface IState {
    startTime: Date
    endTime: Date
}

class CalendarReservation extends React.Component<IProps, IState> {

    movement = 0;
    movingEle: HTMLElement | null = document.createElement("div");
    reducedEle: HTMLElement | null = document.createElement("div");
    nextId = 1 + this.props.length - 1;
    wasOutside = true;

    state = {
        popupVisible: false,
        startTime: new Date(),
        endTime: new Date()
    }

    componentDidMount = () => {
        this.setState({
            startTime: this.props.reservation.startTime,
            endTime: this.props.reservation.endTime
        })
    }

    componentDidUpdate = (lastProps: IProps) => {
        if (this.props.reservation !== lastProps.reservation) {
            this.setState({
                startTime: this.props.reservation.startTime,
                endTime: this.props.reservation.endTime
            })
        }
    }

    private dragBegin = (up: boolean) => {
        if (up) {
            window.addEventListener("mousemove", this.onMouseMoveUp);
            window.addEventListener("mouseup", this.onDragEndUp);
            this.reducedEle = document.getElementById((this.props.id - this.nextId).toString());

        } else {
            window.addEventListener("mousemove", this.onMouseMoveDown);
            window.addEventListener("mouseup", this.onDragEndDown);
            this.reducedEle = document.getElementById((this.props.id + this.nextId).toString());
        }
        this.nextId = this.props.length;
        this.movement = 0;
        this.wasOutside = true;
        this.movingEle = document.getElementById(this.props.id.toString());
    }

    private onMouseMoveUp = (e: any) => {
        this.movement = this.movement - e.movementY;
        if (this.movement >= 0) { // we are moving outside the initial reservation
            if (!this.wasOutside) { // we were moving inside previously, we need to update the reduced element and id
                this.nextId = this.props.length;
                this.reducedEle = document.getElementById((this.props.id - this.nextId).toString());
                this.wasOutside = true;
            }
            if (e.movementY > 0) {
                this.onMouseMoveUpOutsideDown();
            } else {
                if (this.reducedEle !== null && this.reducedEle.getElementsByClassName("ReservationContainer")[0] === undefined) {
                    this.onMouseMoveUpOutsideUp();
                }
            }
        } else { // we are moving inside the initial reservation
            if (this.wasOutside) { // we were moving outside previously, we need to update the reduced element and id
                this.nextId = this.props.length - 1;
                this.reducedEle = document.getElementById((this.props.id - this.nextId).toString());
                this.wasOutside = false;
            }
            if (e.movementY > 0) {
                this.onMouseMoveUpInsideDown();
            } else {
                this.onMouseMoveUpInsideUp();
            }
        }
    }

    private onMouseMoveUpOutsideDown = () => {
        if (this.movement !== 1 && this.nextId !== 1 && this.movement < (30 * ((this.nextId - this.props.length + 1) - 1))) {
            this.onMouseMoveUpOutsideDownThreshhold();
        } else {
            if (this.reducedEle !== null) {
                this.reducedEle.style.height = Math.max(30 - (this.movement % 30), 0).toString() + "px";
                this.reducedEle.style.borderBottom = "none";
            }
            if (this.movingEle !== null) {
                this.movingEle.style.height = (30 * this.props.length + this.movement).toString() + "px";
            }
        }
    }

    private onMouseMoveUpOutsideDownThreshhold = () => {
        this.nextId--;
        if (this.reducedEle !== null) {
            this.reducedEle.style.height = "30px";
            this.reducedEle.style.borderBottom = "1px solid #767f93";
        }
        this.reducedEle = document.getElementById((this.props.id - this.nextId).toString());
    }

    private onMouseMoveUpOutsideUp = () => {
        if (this.movement !== 1 && this.movement > (30 * (this.nextId - this.props.length + 1)) - 1) {
            this.onMouseMoveUpOutsideUpThreshhold();
        } else {
            if (this.reducedEle !== null && this.reducedEle.getAttribute("data-rowType") === "data") {
                if (this.reducedEle !== null) {
                    this.reducedEle.style.height = Math.max(30 - (this.movement % 30), 0).toString() + "px";
                    this.reducedEle.style.borderBottom = "none";
                }
                if (this.movingEle !== null) {
                    this.movingEle.style.height = (30 * this.props.length + this.movement).toString() + "px";
                }
            }
        }
    }

    private onMouseMoveUpOutsideUpThreshhold = () => {
        if (this.reducedEle !== null && this.reducedEle.getAttribute("data-rowType") === "data") { // dont change the next cell if we have hit the top
            this.nextId++;
            if (this.reducedEle !== null) {
                this.reducedEle.style.height = "0px";
                this.reducedEle.style.borderBottom = "none";
            }
            this.reducedEle = document.getElementById((this.props.id - this.nextId).toString());
        }
    }

    private onMouseMoveUpInsideDown = () => {
        console.log("moving down with : ", this.movement);
        console.log("threshhold is : ", (-30 * (this.props.length - this.nextId)) - 1, "  - index : ", this.nextId);
        if (this.movement < ((-30 * (this.props.length - this.nextId)) - 1)) { // movement is always negative here
            this.onMouseMoveUpInsideDownThreshhold();
        } else {
            if (this.reducedEle !== null) {
                this.reducedEle.style.height = Math.min(30, -(this.movement % 30)).toString() + "px";
                this.reducedEle.style.borderBottom = "1px solid #767f93";
            }
            if (this.movingEle !== null) {
                this.movingEle.style.height = ((30 * this.props.length) + this.movement).toString() + "px";
            }
        }
    }

    private onMouseMoveUpInsideDownThreshhold = () => {
        this.nextId++;
        if (this.reducedEle !== null) {
            console.log("set id to 30px : ", this.nextId, "     -- to element : ");

            console.log(this.reducedEle)
            this.reducedEle.style.height = "30px";
            this.reducedEle.style.borderBottom = "1px solid #767f93";
        }
        this.reducedEle = document.getElementById((this.props.id - this.nextId).toString());
    }

    private onMouseMoveUpInsideUp = () => {
        console.log("moving up with : ", this.movement);
        console.log("threshhold is : ", (-30 * this.nextId) - 1, "  - index : ", this.nextId);
        if (this.movement > ((-30 * this.nextId) - 1)) {
            this.onMouseMoveUpInsideUpThreshhold();
        } else {
            if (this.reducedEle !== null) {
                this.reducedEle.style.height = Math.min(30, 30 + (this.movement % 30)).toString() + "px";
                this.reducedEle.style.borderBottom = "1px solid #767f93";
            }
            if (this.movingEle !== null) {
                this.movingEle.style.height = ((30 * this.props.length) + this.movement).toString() + "px";
            }
        }
    }

    private onMouseMoveUpInsideUpThreshhold = () => {
        this.nextId--;
        if (this.reducedEle !== null) {
            console.log("set id to 0px : ", this.nextId, "     -- to element : ");
            console.log(this.reducedEle)
            this.reducedEle.style.height = "0px";
            this.reducedEle.style.borderBottom = "none";
        }
        this.reducedEle = document.getElementById((this.props.id - this.nextId).toString());
    }

    private onDragEndUp = () => {
        let quotient = Math.floor(this.movement / 30);
        let remainder = this.movement % 30;
        let movLength = quotient + (remainder > 30 / 3 ? 1 : 0);

        if (this.reducedEle !== null && this.reducedEle.getElementsByClassName("ReservationContainer")[0] === undefined) {
            this.reducedEle.style.height = remainder > 30 / 3 ? "0px" : "30px";
            this.reducedEle.style.borderBottom = "1px solid #767f93";
        }
        if (this.movingEle !== null) {
            this.movingEle.style.height = (30 * (movLength + this.props.length)).toString() + "px";
        }

        this.props.editRes(this.props.reservation, movLength, true);
        window.removeEventListener("mousemove", this.onMouseMoveUp);
        window.removeEventListener("mouseup", this.onDragEndUp);
    }

    private onMouseMoveDown = (e: any) => {
        if (e.movementY < 0) {
            this.movement = Math.max(-(30 * (this.props.length - 1)), this.movement + e.movementY);
            if (this.movement !== 1 && this.nextId !== 1 && this.movement < (30 * ((this.nextId - this.props.length + 1) - 1))) {
                this.nextId--;
                if (this.reducedEle !== null && this.reducedEle.getElementsByClassName("ReservationContainer")[0] === undefined) {
                    this.reducedEle.style.height = "30px";
                    this.reducedEle.style.borderBottom = "1px solid #767f93";
                }
                this.reducedEle = document.getElementById((this.props.id + this.nextId).toString());
            } else {
                if (this.reducedEle !== null && this.reducedEle.getElementsByClassName("ReservationContainer")[0] === undefined) {
                    this.reducedEle.style.height = Math.max(30 - (this.movement % 30), 0).toString() + "px";
                    this.reducedEle.style.borderBottom = "1px solid #767f93";
                }
                if (this.movingEle !== null) {
                    this.movingEle.style.height = (30 * this.props.length + this.movement).toString() + "px";
                }
            }
        } else {
            if (this.reducedEle !== null && this.reducedEle.getElementsByClassName("ReservationContainer")[0] === undefined) {
                this.movement = Math.max(1, this.movement + e.movementY);
                if (this.movement !== 1 && this.movement > (30 * (this.nextId - this.props.length + 1)) - 1) {
                    this.nextId++;
                    if (this.reducedEle !== null) {
                        this.reducedEle.style.height = "0px";
                        this.reducedEle.style.borderBottom = "none";
                    }
                    this.reducedEle = document.getElementById((this.props.id + this.nextId).toString());
                } else {
                    if (this.reducedEle !== null) {
                        this.reducedEle.style.height = Math.max(30 - (this.movement % 30), 0).toString() + "px";
                    }
                    if (this.movingEle !== null) {
                        this.movingEle.style.height = (30 * this.props.length + this.movement).toString() + "px";
                    }
                }
            }
        }
    }

    private onDragEndDown = () => {
        let quotient = Math.floor(this.movement / 30);
        let remainder = this.movement % 30;
        let movLength = quotient + (remainder > 30 / 3 ? 1 : 0);

        if (this.reducedEle !== null && this.reducedEle.getElementsByClassName("ReservationContainer")[0] === undefined) {
            this.reducedEle.style.height = remainder > 30 / 3 ? "0px" : "30px";
            this.reducedEle.style.borderBottom = "1px solid #767f93";
        }
        if (this.movingEle !== null) {
            this.movingEle.style.height = (30 * (movLength + this.props.length)).toString() + "px";
        }

        this.props.editRes(this.props.reservation, movLength, false);
        window.removeEventListener("mousemove", this.onMouseMoveDown);
        window.removeEventListener("mouseup", this.onDragEndDown);
    }

    public render() {

        let size = (30 * this.props.length - 10).toString() + "px";
        let endHour = this.props.startHour + Math.floor(this.props.length / 4) + Math.floor(((this.props.length % 4 * 15) + this.props.startMin) / 60);
        let endMin = this.props.startMin + ((this.props.length % 4) * this.props.timeInterval)
        if (endMin >= 60) {
            endMin -= Math.floor(endMin / 60) * 60;
        }



        return (
            <div className="ReservationContainer" tabIndex={0} onDoubleClick={() => this.props.openPopup(this.props.reservation)}>
                <div className="Content" style={{ height: size }}>
                    {this.props.startHour.toString().padStart(2, "0") + ":" + this.props.startMin.toString().padStart(2, "0") + this.timeString(this.props.startHour) + " - " + endHour.toString().padStart(2, "0") + ":" + endMin.toString().padStart(2, "0") + this.timeString(endHour)}
                </div>
            </div>
        )
    }

    private timeString = (time: number) => {
        return time > 12 ? " PM" : " AM"
    }
}

export default CalendarReservation