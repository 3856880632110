import * as React from 'react';
import "./Calendar.scss"
import CalendarServiceProvider from '../CalendarServiceProvider';
import iCondo from 'Models/Condo';
import DynamicRadioInput, { iRadioData } from 'GlobalComponents/Inputs/DynamicRadioInput';
import MediaType from 'Ressources/MediaType';
import Calendar_Phone from './Calendar_Phone';
import Calendar_PC from './Calendar_PC';

interface IProps {
    selectedProperty: iCondo
    navigationBarClosed: boolean
    mediaType: MediaType
}

interface IState {
    locations: any[]
    selectedAmenity: number
    selectedWeek: Date
}

class Calendar extends React.Component<IProps, IState> {

    state = {
        locations: [],
        selectedAmenity: -1,
        selectedWeek: new Date()
    }

    componentDidMount() {
        if (this.props.selectedProperty && this.props.selectedProperty.id !== -1) {
            this.getPropertyAmenities();
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.selectedProperty && this.props.selectedProperty.id !== -1 && this.props.selectedProperty !== prevProps.selectedProperty) {
            this.getPropertyAmenities();
        }

        if (prevProps.navigationBarClosed !== this.props.navigationBarClosed) {
            if (this.props.navigationBarClosed) {
                let div = document.getElementsByClassName("CalendarContainer")[0];
                div.classList.remove('open')
                div.classList.add('closed')
            }
            else {
                let div = document.getElementsByClassName("CalendarContainer")[0];
                div.classList.remove('closed')
                div.classList.add('open')
            }
        }
    }

    private getPropertyAmenities = () => {
        CalendarServiceProvider.getPropertyAmenities(this.props.selectedProperty.id).then((res: any) => {
            let loc = res.data != undefined ? res.data.amenities : [];
            this.setState({ locations: loc, selectedAmenity: loc[0] ? loc[0].amenity_id : -1 });
        })
    }

    drawLocations = () => {
        if (this.state) {
            let radioValues: any[] = this.state.locations.map((loc: any) => {
                return {
                    value: loc.name,
                    checked: this.state.selectedAmenity === loc.amenity_id,
                    text: loc.name,
                    onChange: () => this.setState({ selectedAmenity: loc.amenity_id })
                } as iRadioData;
            })
            return <DynamicRadioInput value={radioValues} name="row" error={false}></DynamicRadioInput>
        }
    }

    private changeWeek = (advance: boolean) => {
        let newWeek = new Date(this.state.selectedWeek);
        newWeek.setDate(advance ? newWeek.getDate() + 7 : newWeek.getDate() - 7);

        this.setState({ selectedWeek: newWeek });
    }

    public render() {
        if (this.props.mediaType === MediaType.Phone) {
            return (
                <Calendar_Phone drawLocations={this.drawLocations} changeWeek={this.changeWeek} {...this.state} {...this.props}></Calendar_Phone>
            )
        }
        if (this.props.mediaType === MediaType.PC || this.props.mediaType === MediaType.Tablet) {
            return (
                <Calendar_PC drawLocations={this.drawLocations} changeWeek={this.changeWeek} {...this.state} {...this.props}></Calendar_PC>
            )
        }
    }
}

export default Calendar