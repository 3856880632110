import * as React from 'react';
import Login from './Login/Login';
import Activities from "./../Ressources/Activities"
import Header from '../GlobalComponents/header/Header';
import Calendar from './Calendar/Calendar/Calendar';
import HomePage from "./Home/HomePage";
import SyndicatePage, { pageStates } from './Syndicate/SyndicatePage';
import NavigatorBar from 'GlobalComponents/NavigatorBar/NavigatorBar';
import "./ActivityContainer.scss"
import SyndicateFullPageSignupForm from './Syndicate/CreationForm/SyndicateCreationForm';
import ProfilePic from './UserSettings/ProfilePic';
import PageStates from 'Models/PageStates';
import SyndicateServiceProvider from './Syndicate/SyndicateServiceProvider';
import iCondo from 'Models/Condo';
import AddLocation from './ManagerTools/AddLocation/AddLocation';
import MediaType from 'Ressources/MediaType';
import Forum from './Forum/Forum';
import Reservation from './Reservation/Reservation';
import HelpDesk from './HelpDesk/HelpDesk';

interface IProps {
    mediaType: MediaType
}

interface IState {
    currentActivity: Activities
    selectedProperty: iCondo
    condoList: iCondo[]
    navigationBarClosed: boolean
}

class ActivityContainer extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        let state = JSON.parse(localStorage.getItem('pageStates') || '"activityContainer":""') as PageStates;
        let activity = parseInt(state.activityContainer) as Activities
        this.state = {
            currentActivity: isNaN(activity) ? Activities.Login : activity,
            selectedProperty: { id: -1, name: "" },
            condoList: [{ id: -1, name: "" }],
            navigationBarClosed: false
        }

        let result: iCondo[] = [{ id: -1, name: "" }];
        let selectedCondo = result[0];

        if (localStorage.getItem("username") === null) {
            this.setState({ currentActivity: Activities.Login, selectedProperty: selectedCondo, condoList: result });
        } else {
            SyndicateServiceProvider.GetPropertyList().then((e: any) => {
                if (e.successfull) {
                    result = e.data.properties;
                    selectedCondo = result[0];
                }

                if (state.activityContainer !== "") {
                    activity = parseInt(state.activityContainer) as Activities
                    this.setState({ currentActivity: activity, condoList: result, selectedProperty: selectedCondo });
                } else {
                    this.setState({ currentActivity: Activities.Home, selectedProperty: selectedCondo, condoList: result });
                }
            });
        }
    }

    private changeActivity = (type: Activities) => {
        let state = new PageStates();
        let result: iCondo[] = [{ id: -1, name: "" }];
        let selectedCondo: iCondo = result[0];

        // Bypass the down logic when you are logging out or creating a syndicate - even when you dont have a property. 
        if (type === Activities.Login || type === Activities.CreateSyndicate) {
            return this.setState({ currentActivity: type }, () => {
                state.activityContainer = JSON.stringify(this.state.currentActivity);
                localStorage.setItem('pageStates', JSON.stringify(state));
            })
        }

        return SyndicateServiceProvider.GetPropertyList().then((e: any) => {
            if (e.successfull) {
                result = e.data.properties;
                selectedCondo = result[0];
            }

            selectedCondo = result[0];

            this.setState({ currentActivity: result.length === 0 ? Activities.Syndicate : type, selectedProperty: selectedCondo, condoList: result }, () => {
                state.activityContainer = JSON.stringify(this.state.currentActivity);
                localStorage.setItem('pageStates', JSON.stringify(state));
            })
        });
    }

    private changeSelectedProperty = (condo: any) => {
        this.setState({ selectedProperty: condo.value[0] })
    }

    private closeNavigationMenu = () => {
        this.setState({ navigationBarClosed: !this.state.navigationBarClosed })
    }

    public render() {
        switch (this.state.currentActivity) {
            case Activities.Home:
                return (
                    <React.Fragment>
                        <Header {...this.props} changeActivity={this.changeActivity} {...this.state} changeSelectedProperty={this.changeSelectedProperty} closeNavigationMenu={this.closeNavigationMenu}></Header>
                        <div className="activityContent">
                            <NavigatorBar changeActivity={this.changeActivity} {...this.state} closeNavigationMenu={this.closeNavigationMenu}></NavigatorBar>
                            <Forum {...this.state}></Forum>
                        </div>
                    </React.Fragment>
                );
            case Activities.Calendar:
                return (
                    <React.Fragment>
                        <Header {...this.props} changeActivity={this.changeActivity} {...this.state} changeSelectedProperty={this.changeSelectedProperty} closeNavigationMenu={this.closeNavigationMenu}></Header>
                        <div className="activityContent">
                            <NavigatorBar changeActivity={this.changeActivity} {...this.state} closeNavigationMenu={this.closeNavigationMenu}></NavigatorBar>
                            <Calendar {...this.props} {...this.state}></Calendar>
                        </div>
                    </React.Fragment>
                );
            case Activities.Login:
                return (<Login changeActivity={this.changeActivity} {...this.props}></Login>);
            case Activities.Syndicate:
                return (
                    <React.Fragment>
                        <Header {...this.props} changeActivity={this.changeActivity} {...this.state} changeSelectedProperty={this.changeSelectedProperty} closeNavigationMenu={this.closeNavigationMenu}></Header>
                        <div className="activityContent">
                            <NavigatorBar changeActivity={this.changeActivity} {...this.state} closeNavigationMenu={this.closeNavigationMenu}></NavigatorBar>
                            <SyndicatePage {...this.state} changeActivity={this.changeActivity} {...this.props}></SyndicatePage>
                        </div>
                    </React.Fragment>
                );
            case Activities.CreateSyndicate:
                return (
                    <SyndicateFullPageSignupForm exitForm={this.changeActivity}></SyndicateFullPageSignupForm>
                )
            case Activities.Management:
                return (
                    <React.Fragment>
                        <Header {...this.props} changeActivity={this.changeActivity} {...this.state} changeSelectedProperty={this.changeSelectedProperty} closeNavigationMenu={this.closeNavigationMenu}></Header>
                        <div className="activityContent">
                            <NavigatorBar changeActivity={this.changeActivity} {...this.state} closeNavigationMenu={this.closeNavigationMenu}></NavigatorBar>
                            <span>MANAGEMENT - UNDER CONSTRUCTION :)</span>
                        </div>
                    </React.Fragment>
                );
            case Activities.Accounting:
                return (
                    <React.Fragment>
                        <Header {...this.props} changeActivity={this.changeActivity} {...this.state} changeSelectedProperty={this.changeSelectedProperty} closeNavigationMenu={this.closeNavigationMenu}></Header>
                        <div className="activityContent">
                            <NavigatorBar changeActivity={this.changeActivity} {...this.state} closeNavigationMenu={this.closeNavigationMenu}></NavigatorBar>
                            <span>ACCOUNTING - UNDER CONSTRUCTION :)</span>
                        </div>
                    </React.Fragment>
                );
            case Activities.Reservations:
                return (
                    <React.Fragment>
                        <Header {...this.props} changeActivity={this.changeActivity} {...this.state} changeSelectedProperty={this.changeSelectedProperty} closeNavigationMenu={this.closeNavigationMenu}></Header>
                        <div className="activityContent">
                            <NavigatorBar changeActivity={this.changeActivity} {...this.state} closeNavigationMenu={this.closeNavigationMenu}></NavigatorBar>
                            <Reservation />
                        </div>
                    </React.Fragment>
                );
            case Activities.Documents:
                return (
                    <React.Fragment>
                        <Header {...this.props} changeActivity={this.changeActivity} {...this.state} changeSelectedProperty={this.changeSelectedProperty} closeNavigationMenu={this.closeNavigationMenu}></Header>
                        <div className="activityContent">
                            <NavigatorBar changeActivity={this.changeActivity} {...this.state} closeNavigationMenu={this.closeNavigationMenu}></NavigatorBar>
                            <AddLocation {...this.state} />
                        </div>
                    </React.Fragment>
                );
            case Activities.Communications:
                return (
                    <React.Fragment>
                        <Header {...this.props} changeActivity={this.changeActivity} {...this.state} changeSelectedProperty={this.changeSelectedProperty} closeNavigationMenu={this.closeNavigationMenu}></Header>
                        <div className="activityContent">
                            <NavigatorBar changeActivity={this.changeActivity} {...this.state} closeNavigationMenu={this.closeNavigationMenu}></NavigatorBar>
                            <span>COMMUNICATIONS - UNDER CONSTRUCTION :)</span>
                            <ProfilePic></ProfilePic>
                        </div>
                    </React.Fragment>
                );
            case Activities.HelpDesk:
                return (
                    <React.Fragment>
                        <Header {...this.props} changeActivity={this.changeActivity} {...this.state} changeSelectedProperty={this.changeSelectedProperty} closeNavigationMenu={this.closeNavigationMenu}></Header>
                        <div className="activityContent">
                            <NavigatorBar changeActivity={this.changeActivity} {...this.state} closeNavigationMenu={this.closeNavigationMenu}></NavigatorBar>
                            <HelpDesk {...this.props} {...this.state} />
                        </div>
                    </React.Fragment>
                );
            default:
                return (
                    <div>ERROR</div>
                )
        }
    }
}

export default ActivityContainer