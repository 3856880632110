import { AnyTxtRecord } from 'dns';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import ErrorKeys from 'Ressources/translations/Errors/ErrorKeys';
import { ErrorList } from 'Ressources/translations/Errors/ErrorList';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import "./DragAndDropArea.scss"

export interface IDragAndDropAreaProps {
    handleDrop: Function
    width?: number
    height?: number
    validators?: any[]
}

export interface IDragAndDropAreaState {
    dragging: boolean
    files: File[]
    errors: ErrorKeys[]
}

export default class DragAndDropArea extends React.Component<IDragAndDropAreaProps, IDragAndDropAreaState> {
    dropRef = React.createRef<HTMLDivElement>()
    dragCounter: number;

    constructor(props: IDragAndDropAreaProps) {
        super(props);
        this.dragCounter = 0

        this.state = {
            dragging: false,
            files: [],
            errors: []
        }
    }

    handleDrag = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }
    handleDragIn = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ dragging: true })
        }
    }
    handleDragOut = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter--
        if (this.dragCounter > 0) return
        this.setState({ dragging: false })
    }
    handleDrop = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ dragging: false })
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.handleDrop(e.dataTransfer.files)
            this.RunValidators(Object.values(e.dataTransfer.files));
            this.setState({ files: Object.values(e.dataTransfer.files) })
            e.dataTransfer.clearData()
            this.dragCounter = 0
        }
    }

    componentDidMount() {
        this.dragCounter = 0
        let div = this.dropRef.current
        if (div !== null) {
            div.addEventListener('dragenter', this.handleDragIn)
            div.addEventListener('dragleave', this.handleDragOut)
            div.addEventListener('dragover', this.handleDrag)
            div.addEventListener('drop', this.handleDrop)
        }
    }
    componentWillUnmount() {
        let div = this.dropRef.current
        if (div !== null) {
            div.removeEventListener('dragenter', this.handleDragIn)
            div.removeEventListener('dragleave', this.handleDragOut)
            div.removeEventListener('dragover', this.handleDrag)
            div.removeEventListener('drop', this.handleDrop)
        }
    }

    private RunValidators = (files: File[]) => {
        let errors: any[] = [];
        this.props.validators?.forEach((validator) => {
            let errorCode = validator(files);
            if (errorCode != -1) {
                errors.push(errorCode);
            }
        })
        this.setState({ errors: errors });
    }

    private deleteFile = (fileName: string) => {
        let files = this.state.files
        for (let i = 0; i < files.length; i++) {
            if (files[i].name === fileName) {
                files.splice(i, 1);
                break;
            }
        }
        this.setState({ files: files })
        this.RunValidators(files);
    }

    private renderFiles = () => {
        if (this.state.files && this.state.files.length > 0) {
            return (
                <React.Fragment>
                    <div className='DragDropFilesContent'>
                        {this.state.files.map((file: File, i: number) =>
                            <div key={i} className="FileRow">
                                <div className='FileName'>{file.name}</div>
                                <ImageLoader className='FileDeleteIcon' name='DeleteIcon' onClick={() => this.deleteFile(file.name)} />
                            </div>
                        )}
                    </div>
                    <div className='DragDropErrorsContent'>
                        {<div className='ErrorRow'>
                            <ErrorList values={this.state.errors} />
                        </div>}
                    </div>
                </React.Fragment>
            )
        }
        return (
            <div className='DragDropEmptyContainer'><Translations value={Dictionary.dragFilesHereToUploadThem} /></div>
        )
    }

    public render() {
        return (
            <div ref={this.dropRef} className={this.state.dragging ? "DragDropContainer dragging" : "DragDropContainer"} style={{ width: this.props.width + "%", height: this.props.height + "%" }}>
                {this.state.dragging &&
                    <div className='DragDropDroppingBorder'>
                        <Translations value={Dictionary.uploadFiles} />
                    </div>
                }
                {!this.state.dragging &&
                    <div className='DragDropContent'>
                        {this.renderFiles()}
                    </div>
                }
            </div>
        );
    }
}
