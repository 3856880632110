import * as React from 'react';
import "./LoadingCircle.scss"

export interface iRadioData {
}

interface IProps {
    top?: number
    left?: number
    blurEffect?: boolean
}

interface IState {
}

class LoadingCircle extends React.Component<IProps, IState> {

    public render() {
        let renderEle = this.props.blurEffect ? 
        <div className="lds-blurEffect"><div className="lds-ring" style={{top: this.props.top, left: this.props.left}}><div></div><div></div><div></div><div></div></div></div> : 
        <div className="lds-ring" style={{top: this.props.top, left: this.props.left}}><div></div><div></div><div></div><div></div></div>

        return (
            <React.Fragment>
                {renderEle}
            </React.Fragment>
        )
    }
}

export default LoadingCircle