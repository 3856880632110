import { Divider } from '@material-ui/core';
import ChipSelectWrapper from 'GlobalComponents/Inputs/UserSelect/ChipSelectWrapper';
import DragAndDropArea from 'GlobalComponents/Inputs/DragAndDropArea';
import SelectWrapper from 'GlobalComponents/Inputs/SelectWrapper';
import iTicket, { PriorityType, Status } from 'Models/Ticket';
import * as React from 'react';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import TicketProvider from './TicketProvider';
import TicketCommentsLoader from './TicketCommentsLoader';

export interface IEditTicketProps {
    selectedTicket: iTicket | undefined
    Back: Function
}

export interface IEditTicketState {
    ticket: iTicket
}

export default class EditTicket extends React.Component<IEditTicketProps, IEditTicketState> {

    priorityLabels = [Dictionary.low, Dictionary.medium, Dictionary.high, Dictionary.urgent];
    statusLabels = [Dictionary.open, Dictionary.onHold, Dictionary.inProgress, Dictionary.closed, Dictionary.invalid];

    constructor(props: IEditTicketProps) {
        super(props);

        let defaultTicket: iTicket = {
            amenity_id: '',
            assignments: [],
            date_closed: new Date(),
            date_created: new Date(),
            date_updated: new Date(),
            details: '',
            files: [],
            id: 0,
            notes: [],
            priority: "",
            property_id: 0,
            status: "",
            summary: "",
            unit_id: 0,
            user_id: 0
        }
        this.state = {
            ticket: props.selectedTicket ?? defaultTicket
        }
    }

    private DateFormatter = (date: Date) => {
        if (date === null || date === undefined) {
            return "--/--/--";
        }
        else {
            return date.toLocaleString();
        }
    }

    private EditTicket = (priority?: PriorityType, status?: Status) => {
        let nullablePriority = priority ? PriorityType[priority] : undefined;
        let nullableStatus = status ? Status[status] : undefined;
        TicketProvider.EditTicket(this.state.ticket.id, nullablePriority, nullableStatus);
    }

    public render() {
        let priority: PriorityType = PriorityType[this.state.ticket.priority as keyof typeof PriorityType];
        let status: Status = Status[this.state.ticket.status as keyof typeof Status];
        return (
            <div className='EditTicketContent'>
                <div className='LeftSide'>
                    <h1>{this.state.ticket.details}</h1>
                    <Divider />
                    <div className='Dropdowns'>
                        <div className='Dropdown'>
                            <span><Translations value={Dictionary.priority}></Translations></span>
                            <SelectWrapper name="priority" label={Dictionary.priority} options={PriorityType} defaultValueKey={priority} optionsText={this.priorityLabels} handleChange={(val: PriorityType) => { this.EditTicket(val) }} />
                        </div>
                        <div className='Dropdown'>
                            <span><Translations value={Dictionary.status}></Translations></span>
                            <SelectWrapper name="status" label={Dictionary.status} options={Status} defaultValueKey={status} optionsText={this.statusLabels} handleChange={(val: Status) => { this.EditTicket(undefined, val) }} />
                        </div>
                    </div>
                    <div className='SummaryWrapper'>
                        <span>{this.state.ticket.summary}</span>
                    </div>
                    <TicketCommentsLoader ticketId={this.state.ticket.id} />

                </div>
                <Divider orientation='vertical' />
                <div className='RightSide'>
                    Dates :
                    <div>
                        <span>Created : </span>
                        <span>{this.DateFormatter(this.state.ticket.date_created)}</span>
                    </div>
                    <div>
                        <span>Updated : </span>
                        <span>{this.DateFormatter(this.state.ticket.date_updated)}</span>
                    </div>
                    <div>
                        <span>Closed : </span>
                        <span>{this.DateFormatter(this.state.ticket.date_closed)}</span>
                    </div>
                    <Divider style={{ margin: "20px" }} />
                    <ChipSelectWrapper propertyId={this.state.ticket.property_id} ticketId={this.state.ticket.id}></ChipSelectWrapper>
                    <Divider style={{ margin: "20px" }} />
                    Additional files
                    <DragAndDropArea handleDrop={() => { }} height={20} />
                </div>
            </div>
        );
    }
}
