import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import CalendarServiceProvider from 'components/Calendar/CalendarServiceProvider';
import iAmenity from 'Models/Amenity';
import iCondo, { iProperty } from 'Models/Condo';
import * as React from 'react';
import { Dictionary, Translations } from 'Ressources/translations/Translations';

export interface IAmenitySelectProps {
    selectedProperty: iCondo
    name: string
    label: Dictionary
    handleChange: Function
    minWidth?: string
    allOption?: boolean
}

export interface IAmenitySelectState {
    value: any
    amenities: iAmenity[]
}

export default class AmenitySelect extends React.Component<IAmenitySelectProps, IAmenitySelectState> {
    constructor(props: IAmenitySelectProps) {
        super(props);

        this.state = {
            value: "",
            amenities: []
        }
    }

    componentDidMount = () => {
        CalendarServiceProvider.getPropertyAmenities(this.props.selectedProperty.id).then((res: any) => {
            let amenities = res.data != undefined ? res.data.amenities : [];
            this.setState({ amenities: amenities });
        })
    }

    private handleChange = (event: any) => {
        this.setState({ value: event.target.value });

        let amenity = this.state.amenities.find((amenity: iAmenity) => amenity.name === event.target.value);
        let amenityId = amenity?.amenity_id ?? -1;

        this.props.handleChange(amenityId);
    };

    private GetMenuItems = () => {
        let menuItems: JSX.Element[] = [];

        if (this.props.allOption) {
            menuItems.push(
                <MenuItem value={"all"}>all</MenuItem>
            )
        }

        this.state.amenities.map((amenity: iAmenity, i: number) => {
            menuItems.push(
                <MenuItem value={amenity.name}>{amenity.name}</MenuItem>
            )

        })

        menuItems.push(
            <MenuItem value={"other"}>Other</MenuItem>
        )

        return (menuItems)
    }

    public render() {
        return (
            <FormControl size="medium" sx={{ m: 1, minWidth: this.props.minWidth ?? 200, width: this.props.minWidth ?? 200 }}>
                <InputLabel id={this.props.name + "label"}><Translations value={this.props.label} /></InputLabel>
                <Select
                    labelId={this.props.name + "label"}
                    id={this.props.name}
                    value={this.state.value}
                    label={<Translations value={Dictionary.propertyType} />}
                    onChange={this.handleChange}>
                    {this.GetMenuItems()}
                </Select>
            </FormControl>
        );
    }
}