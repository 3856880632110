import OptionsMenu from 'GlobalComponents/Containers/OptionsMenu/OptionsMenu';
import { iReservation } from 'Models/Reservation';
import * as React from 'react';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import "./ReservationPopupContent.scss"

interface IProps {
    editRes: Function
    curRes: iReservation
    title: Dictionary
}

interface IState {
}

class ReservationPopupContent extends React.Component<IProps, IState> {

    private editRes = (e: any, startTime: boolean) => {
        this.props.editRes(e, startTime);
    }

    public render() {
        let startTime = this.props.curRes.startTime.getHours().toString().padStart(2, "0") + ":" + this.props.curRes.startTime.getMinutes().toString().padStart(2, "0");
        let endTime = this.props.curRes.endTime.getHours().toString().padStart(2, "0") + ":" + this.props.curRes.endTime.getMinutes().toString().padStart(2, "0");
        return (
            <div>
                <h3><Translations value={this.props.title} /></h3>
                <div>
                    <form>
                        <input type="time" onChange={(e: any) => this.editRes(e, true)} value={startTime} step={900} required></input>
                        <input type="time" onChange={(e: any) => this.editRes(e, false)} value={endTime} step={900} required></input>
                    </form>
                </div>
            </div>
        )
    }
}

export default ReservationPopupContent