import * as React from 'react';
import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Avatar, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import PromiseButton from 'GlobalComponents/Buttons/PromiseButton';
import SyndicateServiceProvider from 'components/Syndicate/SyndicateServiceProvider';
import ImageLoader from 'Ressources/Images/ImageLoader';
import iMassInviteData, { MassInviteStatus } from 'Models/MassInviteData';
import { createMuiTheme, createTheme } from '@material-ui/core/styles';

export interface ISyndicateGroupInviteSelectorProps {
    sendInvite: Function
    ToggleEditCell: Function
    EditCell: Function
    DeleteRow: Function
    parsedExcel: iMassInviteData[]
}

export interface ISyndicateGroupInviteSelectorState {
    columns: GridColDef[]
    headerValues: any[]
}

export default class SyndicateGroupInviteSelector extends React.Component<ISyndicateGroupInviteSelectorProps, ISyndicateGroupInviteSelectorState> {
    constructor(props: ISyndicateGroupInviteSelectorProps) {
        super(props);

        this.state = {
            columns: [],
            headerValues: ['']
        }
    }

    componentDidMount = () => {
        this.CreateColumns();
    }

    private HeaderValueChanged = (event: SelectChangeEvent, headerIndex: number) => {
        let values = this.state.headerValues;
        values[headerIndex] = event.target.value;
        this.setState({ headerValues: values });
    };

    private RenderStatus = (params: any) => {
        switch (params.formattedValue) {
            case MassInviteStatus.Sent:
                return (
                    <div><Translations value={Dictionary.sent}></Translations></div>
                )
            case MassInviteStatus.Error:
                return (
                    <div><Translations value={Dictionary.error}></Translations></div>
                )
            case MassInviteStatus.InUse:
                return (
                    <div><Translations value={Dictionary.inUse}></Translations></div>
                )
            case MassInviteStatus.NotSent:
                return (
                    <div><Translations value={Dictionary.notSent}></Translations></div>
                )
        }
        return (
            <div>{params.formattedValue}</div>
        )
    }

    private RenderOptions = (param: GridCellParams) => {
        let editButton = param.row.isInEditMode ? "CheckmarkIcon" : "EditIcon"
        return (
            <div>
                <ImageLoader name={editButton} className='EditIcon' onClick={() => this.props.ToggleEditCell(param)}></ImageLoader>
                <ImageLoader name='DeleteIcon' className='DeleteIcon' onClick={() => this.props.DeleteRow(param)}></ImageLoader>
            </div>
        )
    }

    private RenderCell = (param: GridCellParams, index: number) => {
        if (param.row.isInEditMode) {
            return (
                <div>
                    <TextField id="standard-basic" label="Standard" variant="standard" value={param.row.data[index]} onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => { this.props.EditCell(event, param, index) }} />
                </div>
            )
        } else {
            return (
                <div>
                    {param.row.data[index]}
                </div>
            )
        }
    }

    private CreateColumns = () => {
        let columns: any[] = [];
        let headers: any[] = [];

        columns.push({ field: '', headerName: '', width: 70, minWidth: 70, renderCell: (param: GridCellParams) => this.RenderOptions(param), sortable: false, disableColumnMenu: true, hideable: false })

        for (let i = 0; i < this.props.parsedExcel[0].data.length; i++) {
            columns.push({ field: i, headerName: '', flex: 1, renderHeader: () => this.CreateSelector(i), renderCell: (e: GridCellParams) => this.RenderCell(e, i) })
            headers.push('');
        }

        columns.push({ field: 'status', headerName: 'status', width: 200, minWidth: 200, renderCell: this.RenderStatus })

        this.setState({ columns: columns, headerValues: headers })
    }

    private CreateSelector = (headerIndex: number) => {
        return (
            <FormControl style={{ minWidth: 120 }} size="small">
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.headerValues[headerIndex]}
                    label="Age"
                    error={this.state.headerValues[headerIndex] === ""}
                    onChange={(e: SelectChangeEvent) => this.HeaderValueChanged(e, headerIndex)}
                >
                    <MenuItem value={0}><Translations value={Dictionary.unused} /></MenuItem>
                    <MenuItem value={1}><Translations value={Dictionary.firstName} /></MenuItem>
                    <MenuItem value={2}><Translations value={Dictionary.lastName} /></MenuItem>
                    <MenuItem value={3}><Translations value={Dictionary.email} /></MenuItem>
                    <MenuItem value={4}><Translations value={Dictionary.UnitNumber} /></MenuItem>
                    <MenuItem value={5}><Translations value={Dictionary.votingPercent} /></MenuItem>
                </Select>
            </FormControl>
        )
    }



    public render() {
        const theme = createTheme({
            overrides:{
                MuiTableRow: {
                    root: { //for the body
                        height: "100%"
                    },
                    head: { //for the head
                        height: "100%"
                    }
                }
            }
        })

        return (
            <div style={{ height: 400, width: '90%', margin: "30px 0px 0px 0px" }}>
                <DataGrid
                    rows={this.props.parsedExcel}
                    columns={this.state.columns}
                    headerHeight={80}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
                <PromiseButton basicText={Dictionary.send} loadingText={Dictionary.sending} doneText={Dictionary.sendAgain} onClick={() => this.props.sendInvite(this.state.headerValues, this.props.parsedExcel)}></PromiseButton>
            </div>
        );
    }
}
