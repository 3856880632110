import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import "./DynamicInput.scss"

export enum Size {
    small,
    medium,
    large
}
interface IProps {
    inputName: Dictionary
    type: React.HTMLInputTypeAttribute
    handleChange: any
    inputStateName: string
    value: any
    error?: boolean
    size?: Size
    var?: any
    idInc?: number
    minVal?: number
}

interface IState {
    showPassword: boolean
}

class DynamicInput extends React.Component<IProps, IState> {

    state = {
        showPassword: this.props.type === "password"
    }

    private getClassName = () => {
        switch (this.props.size) {
            case Size.small:
                return "form__group__small";
            case Size.medium:
                return "form__group";
            case Size.large:
                return "form__group__large";
        }
        return "form__group";
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.type !== prevProps.type) {
            this.setState({ showPassword: this.props.type === "password" })
        }
    }

    public render() {
        let type = this.props.type === "password" ? (!this.state.showPassword ? "text" : this.props.type) : this.props.type;
        let inc = this.props.idInc ? this.props.idInc : 0

        return (
            <div className={this.getClassName() + " field"}>
                <input type={type} className={this.props.error ? "form__field form__field_error" : "form__field"} placeholder="Name" name={this.props.inputStateName}
                    value={this.props.value} id={"name" + (this.props.inputName + inc)} required min={this.props.minVal}
                    onChange={this.props.handleChange} onClick={(e: any) => { e.target.select(); }} />
                <label htmlFor={"name" + (this.props.inputName + inc)} className="form__label"><Translations value={this.props.inputName} var={this.props.var}></Translations></label>
                {this.props.type === "password" ? <ImageLoader name="EyeIcon" className="showPasswordButton" onClick={(e) => { this.setState({ showPassword: !this.state.showPassword }) }}></ImageLoader> : null}

            </div>
        )
    }
}

export default DynamicInput