import * as React from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import SelectWrapper from 'GlobalComponents/Inputs/SelectWrapper';
import iCondo from 'Models/Condo';
import CalendarServiceProvider from 'components/Calendar/CalendarServiceProvider';
import iAmenity from 'Models/Amenity';
import PromiseButton from 'GlobalComponents/Buttons/PromiseButton';
import TicketProvider from './TicketProvider';
import Autocomplete from '@mui/material/Autocomplete';
import { PriorityType, Visibility } from 'Models/Ticket';
import AmenitySelect from 'GlobalComponents/Inputs/AmenitySelect';

export interface ICreateTicketProps {
    selectedProperty: iCondo
    Back: Function
}

export interface ICreateTicketState {
    priorityType: PriorityType
    amenityId: number
    location: string
    summary: string
    details: string
    priority: PriorityType
    visibility: Visibility
}

export default class CreateTicket extends React.Component<ICreateTicketProps, ICreateTicketState> {

    priorityLabels = [Dictionary.low, Dictionary.medium, Dictionary.high, Dictionary.urgent];
    visibilityLabels = [Dictionary.private, Dictionary.public];

    constructor(props: ICreateTicketProps) {
        super(props);

        this.state = {
            priorityType: PriorityType.LOW,
            amenityId: -1,
            location: "",
            priority: PriorityType.NORMAL,
            visibility: Visibility.PRIVATE,
            summary: "",
            details: ""
        }
    }

    private CreateTicket = () => {
        TicketProvider.CreateTicket(this.props.selectedProperty.id, this.state.summary, PriorityType[this.state.priority], this.state.details).then((res) => {
            if (res?.successfull) {
                this.props.Back(true);
            }
        })
    }

    public render() {
        return (
            <React.Fragment>
                <div className='Header'>
                    <h1>Create a ticket</h1>
                    {/* <h1><Translations value={Dictionary.helpDesk} /></h1> */}
                </div>
                <div className='CreateTicketContent'>
                    <div className='row'>
                        <span>Summary</span>
                        <textarea onInput={(event: React.FormEvent<HTMLTextAreaElement>) => { this.setState({ summary: event.currentTarget.value }) }}></textarea>
                    </div>
                    <div className='row'>
                        <span>Affected amenity</span>
                        <AmenitySelect {...this.props} handleChange={(id: any) => { this.setState({ amenityId: id }) }} label={Dictionary.addEmployee} name="amenitySelect" />
                    </div>
                    <div className='row'>
                        <span>Priority</span>
                        <SelectWrapper name="priority" label={Dictionary.confirmationSent} options={PriorityType} defaultValueKey={PriorityType.NORMAL} optionsText={this.priorityLabels} handleChange={(val: any) => { this.setState({ priority: val }) }} />
                    </div>
                    <div className='row'>
                        <span>Visibility</span>
                        <SelectWrapper name="visibility" label={Dictionary.none} options={Visibility} defaultValueKey={Visibility.PRIVATE} optionsText={this.visibilityLabels} handleChange={(val: any) => { this.setState({ visibility: val }) }} />
                    </div>
                    <div className='row'>
                        <span>Assignee</span>
                        <span>Automatic</span>
                    </div>
                    <div className='row'>
                        <span>Details</span>
                        <textarea onInput={(event: React.FormEvent<HTMLTextAreaElement>) => { this.setState({ details: event.currentTarget.value }) }}></textarea>
                    </div>
                </div>
                <PromiseButton basicText={Dictionary.send} loadingText={Dictionary.sending} doneText={Dictionary.sent} onClick={this.CreateTicket}></PromiseButton>
            </React.Fragment>
        );
    }
}
