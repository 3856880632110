import * as React from 'react';
import Activities from 'Ressources/Activities';
import iCondo from '../../Models/Condo';
import ImageLoader from '../../Ressources/Images/ImageLoader';
import { Dictionary, Translations } from '../../Ressources/translations/Translations';
import BaseOptions from './BaseOptions/BaseOptions';
import SyndicateBaseOptions from './Modules/SyndicateBaseOptions/SyndicateBaseOptions';
import SyndicateInviteOptions from './Modules/SyndicateInviteOptions/SyndicateInviteOptions';
import SyndicateGroupInvite from './Modules/SyndicateGroupInvite/SyndicateGroupInvite';
import SyndicateIndividualInvite from './Modules/SyndicateIndividualInvite/SyndicateIndividualInvite';
import "./SyndicatePage.scss"
import SyndicateShareKey from './Modules/SyndicateShareKey/SyndicateShareKey';
import SyndicateCoOwnersList from './Modules/SyndicateCoOwnersList/SyndicateCoOwnersList';
import SyndicateAddEmployee from './Modules/SyndicateAddEmployee/SyndicateAddEmployee';
import SyndicateEmployeesList from './Modules/SyndicateEmployeesList/SyndicateEmployeesList';
import SyndicateAdministratorsList from './Modules/SyndicateAdministratorsList/SyndicateAdministratorsList';
import SyndicateServiceProvider from './SyndicateServiceProvider';
import PageStates from 'Models/PageStates';
import MediaType from 'Ressources/MediaType';
import SyndicateJoinSyndicate from './Modules/SyndicateJoinSyndicate/SyndicateJoinSyndicate';
import ManageUsers from './Modules/ManageUsers/ManageUsers';
import CreateAmenities from './Modules/Amenities/CreateAmenities';

interface IProps {
    changeActivity: Function
    condoList: Array<iCondo>
    navigationBarClosed: boolean
    mediaType: MediaType
}

interface IState {
    startSignupForm: boolean
    pageState: pageStates
    currentCondo: iCondo
    email: string
    groupEmail: string
    syndicateKey: string
    separator: string
    errors: string[]
}

export enum pageStates {
    base,
    options,
    info,
    inviteChoices,
    individualInvite,
    groupInvite,
    shareKey,
    manageListCoowners,
    addEmployee,
    manageListEmployees,
    manageListAdmin,
    joinSyndicate,
    manageUsers,
    createAmenities,
}

class SyndicatePage extends React.Component<IProps, IState> {

    state = {
        startSignupForm: false,
        pageState: pageStates.base,
        currentCondo: { id: 0, name: "" },
        email: "",
        groupEmail: "",
        syndicateKey: "",
        separator: "",
        errors: [],
    }

    constructor(props: IProps) {
        super(props);

        let state = JSON.parse(localStorage.getItem('pageStates') || '"syndicatePage":""') as PageStates;

        if (state.syndicatePage !== "") {
            this.state = JSON.parse(state.syndicatePage);
        }
    }

    private handleChange = (event: any) => {
        let state = JSON.parse(localStorage.getItem('pageStates') || '"syndicatePage":""') as PageStates;
        const { target: { name, value } } = event
        this.setState({ [name]: value } as Pick<IState, keyof IState>, () => {
            this.setState({ errors: [] }, () => {
                state.syndicatePage = JSON.stringify(this.state);
                localStorage.setItem('pageStates', JSON.stringify(state));
            })
        });
    }

    private changeState = (pageState: pageStates) => {
        let state = JSON.parse(localStorage.getItem('pageStates') || '"syndicatePage":""') as PageStates;
        this.setState({ pageState: pageState }, () => {
            state.syndicatePage = JSON.stringify(this.state);
            localStorage.setItem('pageStates', JSON.stringify(state));
        })
    }

    private yourSyndicateClicked = (condo: iCondo) => {
        let state = JSON.parse(localStorage.getItem('pageStates') || '"syndicatePage":""') as PageStates;
        this.setState({ currentCondo: condo, pageState: pageStates.options }, () => {
            state.syndicatePage = JSON.stringify(this.state);
            localStorage.setItem('pageStates', JSON.stringify(state));
        })
    }

    private content = () => {
        switch (this.state.pageState) {
            case pageStates.base:
                return <BaseOptions yourSyndicateClicked={this.yourSyndicateClicked} changeState={this.changeState} {...this.props}></BaseOptions>
            case pageStates.options:
                return <SyndicateBaseOptions changeState={this.changeState} {...this.props} {...this.state}></SyndicateBaseOptions>
            case pageStates.inviteChoices:
                return <SyndicateInviteOptions changeState={this.changeState} {...this.props} {...this.state}></SyndicateInviteOptions>
            case pageStates.individualInvite:
                return <SyndicateIndividualInvite handleChange={this.handleChange} changeState={this.changeState} {...this.props} {...this.state}></SyndicateIndividualInvite>
            case pageStates.groupInvite:
                return <SyndicateGroupInvite handleChange={this.handleChange} changeState={this.changeState} {...this.props} {...this.state}></SyndicateGroupInvite>
            case pageStates.shareKey:
                return <SyndicateShareKey changeState={this.changeState} {...this.props} {...this.state}></SyndicateShareKey>
            case pageStates.manageListCoowners:
                return <SyndicateCoOwnersList changeState={this.changeState} {...this.props} {...this.state}></SyndicateCoOwnersList>
            case pageStates.addEmployee:
                return <SyndicateAddEmployee handleChange={this.handleChange} changeState={this.changeState} {...this.props} {...this.state}></SyndicateAddEmployee>
            case pageStates.manageListEmployees:
                return <SyndicateEmployeesList changeState={this.changeState} {...this.props} {...this.state}></SyndicateEmployeesList>
            case pageStates.manageListAdmin:
                return <SyndicateAdministratorsList changeState={this.changeState} {...this.props} {...this.state}></SyndicateAdministratorsList>
            case pageStates.joinSyndicate:
                return <SyndicateJoinSyndicate changeState={this.changeState} handleChange={this.handleChange} {...this.props} {...this.state}></SyndicateJoinSyndicate>
            case pageStates.manageUsers:
                return <ManageUsers changeState={this.changeState} {...this.props} {...this.state}></ManageUsers>
            case pageStates.createAmenities:
                return <CreateAmenities handleChange={this.handleChange} changeState={this.changeState} {...this.props} {...this.state}></CreateAmenities>
            default:
                break;
        }
    }

    private back = () => {
        switch (this.state.pageState) {
            case pageStates.options:
            case pageStates.joinSyndicate:
                this.changeState(pageStates.base);
                break;
            case pageStates.inviteChoices:
            case pageStates.manageUsers:
            case pageStates.individualInvite:
            case pageStates.groupInvite:
            case pageStates.createAmenities:
                this.changeState(pageStates.options);
                break;
            case pageStates.shareKey:
            case pageStates.manageListCoowners:
            case pageStates.addEmployee:
            case pageStates.manageListEmployees:
            case pageStates.manageListAdmin:
                this.changeState(pageStates.inviteChoices);
                break;
            default:
                break;
        }
    }

    componentDidUpdate = (prevProps: IProps) => {
        if (prevProps.navigationBarClosed !== this.props.navigationBarClosed) {
            if (this.props.navigationBarClosed) {
                let div = document.getElementsByClassName("Syndicate_page_container")[0];
                div.classList.remove('open')
                div.classList.add('closed')
            }
            else {
                let div = document.getElementsByClassName("Syndicate_page_container")[0];
                div.classList.remove('closed')
                div.classList.add('open')
            }
        }
    }

    public render() {
        return (
            <div className="Syndicate_page_container">
                <div className="header">
                    {this.state.pageState === pageStates.base ? <div></div> : <ImageLoader name="BackIcon" className="icon" onClick={this.back}></ImageLoader>}
                    <h1><Translations value={Dictionary.syndicate}></Translations></h1>
                    <div></div>
                </div>
                {this.content()}
            </div>
        )
    }
}

export default SyndicatePage