import SignupServiceProvider from 'components/Login/signupForm/SignupServiceProvider';
import OptionsMenu from 'GlobalComponents/Containers/OptionsMenu/OptionsMenu';
import iPost from 'Models/Post';
import * as React from 'react';
import { Buffer } from 'buffer';

export interface ITicketPostProps {
    post: iPost
}

export interface ITicketPostState {
    picture: any
}

export default class TicketPost extends React.Component<ITicketPostProps, ITicketPostState> {
    constructor(props: ITicketPostProps) {
        super(props);

        this.state = {
            picture: ""
        }
    }

    componentDidMount = () => {
        SignupServiceProvider.getPictureUser(this.props.post.user_id).then((e: any) => {
            if (e) {
                const buf = Buffer.from(e.data, 'binary').toString('base64');
                this.setState({ picture: buf })
            }
        })
    }

    getPicture = () => {
        return (
            <img src={"data:image/jpeg;base64," + this.state.picture} alt="No picture" className="PostPicture"></img>
        )
    }

    public render() {
        return (
            <div className="PostContainer">
                <div className="PostHeader">
                    <div>
                        {this.getPicture()}
                        <div>
                            <div className='name'>Anthony RD</div>
                            {/* {this.props.post.username} */}
                            <div className='time'>{this.props.post.date_created}</div>
                            {/* {this.props.post.time} */}
                        </div>
                    </div>
                    <OptionsMenu></OptionsMenu>
                </div>
                <div className='PostContent'>
                    {this.props.post.content}
                </div>
            </div>
        );
    }
}
