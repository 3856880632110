import { iReservation, iReservationFetcher } from 'Models/Reservation';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import CalendarReservation from '../Reservation/CalendarReservation';
import CalendarServiceProvider from '../CalendarServiceProvider';
import "./CalendarWeek.scss"
import MediaType from 'Ressources/MediaType';
import CalendarWeek_Phone from './CalendarWeek_Phone';
import CalendarWeek_PC, { PopupType } from './CalendarWeek_PC';


interface IProps {
    timeInterval: number
    selectedAmenity: number
    selectedWeek: Date
    mediaType: MediaType
    popupContent: PopupType
}

interface IState {
    reservations: iReservation[][]
    savedReservations: iReservation[][]
    resChanged: boolean
    week: Date[]
    loading: boolean
    showPopup: boolean
    curRes: iReservation
    scrolling: boolean
    scrollHoverTimeout: NodeJS.Timeout | undefined
}

class CalendarWeek extends React.Component<IProps, IState> {

    private deleteRes = (reservation: iReservation) => {
        return CalendarServiceProvider.deleteReservation(reservation.reservationId).then((res: any) => {
            this.closePopup();
            this.fetchReservations();
        })
    }

    private editRes = (reservation: iReservation) => {
        return CalendarServiceProvider.editReservation(reservation).then((res: any) => {
            this.fetchReservations();
        })
    }


    private popupEditRes = (e: any, startTime: boolean) => {
        let newTime = e.target.value.split(':');
        let curRes = this.state.curRes;
        if (startTime) {
            let time = new Date(curRes.startTime)
            time.setHours(newTime[0]);
            time.setMinutes(newTime[1]);
            curRes.startTime = time;
            this.setState({ curRes: curRes })

        } else {
            let time = new Date(curRes.endTime)
            time.setHours(newTime[0]);
            time.setMinutes(newTime[1]);
            curRes.endTime = time;
            this.setState({ curRes: curRes })
        }

        Promise.resolve(0);
    }


    private saveRes = () => {
        let res = this.state.curRes;

        if (res.reservationId == -1) {
            res.amenityId = this.props.selectedAmenity;
            return CalendarServiceProvider.createReservation(res).then((res: any) => {
                if (res && res.statusText === "CREATED") {
                    this.closePopup();
                    this.fetchReservations();
                }
            })
        }
        return this.editRes(res).then((e: any) => {
            this.closePopup();
        });
    }

    private cellClicked = (row: number, column: number) => {
        let selectedDate = new Date(this.state.week[0]);
        let mins = column % 60;
        let hours = Math.floor(column / 60);

        selectedDate.setDate(selectedDate.getDate() + row);
        selectedDate.setHours(hours);
        selectedDate.setMinutes(mins);
        selectedDate.setSeconds(0);

        let endDate = new Date(selectedDate);
        endDate.setMinutes(endDate.getMinutes() + 15);

        let res: iReservation = {
            amenityId: this.props.selectedAmenity,
            startTime: selectedDate,
            endTime: endDate,
            reservationId: -1,
            reservedBy: -1
        }

        this.openPopup(res);
    }

    constructor(props: IProps) {
        super(props);

        this.state = {
            reservations: [],
            savedReservations: [],
            resChanged: false,
            week: this.setDaysArray(),
            loading: true,
            showPopup: false,
            curRes: {
                amenityId: -1,
                startTime: new Date(),
                endTime: new Date(),
                reservationId: -1,
                reservedBy: -1
            },
            scrolling: false,
            scrollHoverTimeout: undefined
        }

        this.fetchReservations();
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.selectedAmenity === undefined) {

        }
        if (prevProps.selectedAmenity !== this.props.selectedAmenity) {
            this.fetchReservations();
        }
        if (prevProps.selectedWeek !== this.props.selectedWeek) {
            this.setState({ week: this.setDaysArray() }, this.fetchReservations)
        }
    }

    private setDaysArray = () => {
        var curr = new Date(this.props.selectedWeek); // get current date
        var curr2 = new Date(this.props.selectedWeek); // get current date

        var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
        var lastday = new Date(curr2.setDate(curr2.getDate() - curr2.getDay() + 6));

        firstday.setHours(0);
        firstday.setMinutes(0);
        lastday.setHours(11);
        lastday.setMinutes(59);
        lastday.setHours(lastday.getHours() + 12);

        let tempWeek = [...Array(7)];
        tempWeek[0] = firstday;
        for (let i = 1; i < 6; i++) {
            let date = new Date(firstday);
            date.setDate(firstday.getDate() + i);
            tempWeek[i] = date
        }
        tempWeek[6] = lastday;

        return tempWeek
    }

    private fetchReservations = () => {
        this.setState({ loading: true });
        let resTable: iReservation[][] = new Array<Array<iReservation>>();
        for (let i = 0; i < 7; i++) {
            resTable[i] = new Array<iReservation>();
        }

        if (this.props.selectedAmenity !== -1) {
            let week: iReservationFetcher = { amenityId: this.props.selectedAmenity, startTime: this.state.week[0], endTime: this.state.week[6] }
            CalendarServiceProvider.getReservations(week).then((res: any) => {
                if (res && res.data) {
                    res.data.reservations.map((res: any) => {
                        let startTime = new Date(res.start_time + ":00.000Z");
                        let endTime = new Date(res.end_time + ":00.000Z");
                        resTable[startTime.getDay()].push({ amenityId: 1, startTime: startTime, endTime: endTime, reservationId: res.reservation_id, reservedBy: res.reserved_by_id });
                    })

                    this.setState({ reservations: resTable, savedReservations: JSON.parse(JSON.stringify(resTable)), loading: false });
                }
            })
        }
    }

    private checkReservationLength = (day: number, hour: number, min: number) => {
        let date = new Date(this.state.week[0]);
        date.setDate(date.getDate() + day);
        date.setHours(hour);
        date.setMinutes(min);
        date.setSeconds(0);

        let len = 0;
        let next = 0;
        let res: iReservation = { amenityId: -1, startTime: new Date(), endTime: new Date(), reservationId: -1, reservedBy: -1 };

        if (this.state.reservations !== null && this.state.reservations.length !== 0) {
            this.state.reservations[day].forEach((e: iReservation, i: number) => {
                if (date.getTime() >= e.startTime.getTime() && date.getTime() < e.endTime.getTime()) {
                    len = ((e.endTime.getTime() - e.startTime.getTime()) / 60000) / this.props.timeInterval;
                    res = this.state.reservations[day][i]
                    if (this.state.reservations[day][i + 1]) {
                        next = this.state.reservations[day][i + 1].startTime.getTime() - e.endTime.getTime();
                    }
                }
            })
        }

        return { len, res, next };
    }

    private addReservations = (len: number, startHour: number, startMin: number, next: number, reservation: iReservation, day: number, i: number, id: number) => {
        if (len > 0) {
            return (
                <CalendarReservation length={len} startHour={startHour} startMin={startMin} timeInterval={this.props.timeInterval} next={next}
                    reservation={reservation} deleteRes={this.deleteRes} openPopup={this.openPopup} editRes={this.editRes} id={id}></CalendarReservation>
            );
        }
        return (
            <div onMouseDown={(e: React.MouseEvent<HTMLElement>) => this.cellClicked(day, i)} >
                {/* {id} */}
                <ImageLoader name="PlusIcon" className="PlusIcon"></ImageLoader>
            </div >
        );
    }

    private closePopup = () => {
        this.setState({ showPopup: false });
        return Promise.resolve(0);
    }

    private openPopup = (res: iReservation) => {
        this.setState({ showPopup: true, curRes: res });
        return Promise.resolve(0);
    }

    private onScroll = () => {
        if (!this.state.scrolling) {
            var timeout = setTimeout(() => {
                this.hideScroll()
            }, 1600)
            this.setState({ scrolling: true, scrollHoverTimeout: timeout })
            return;
        }
    }

    private hideScroll = () => {
        this.setState({ scrolling: false, scrollHoverTimeout: undefined })
    }

    public render() {
        if (this.props.mediaType === MediaType.Phone) {
            return (
                <CalendarWeek_Phone checkReservationLength={this.checkReservationLength} addReservations={this.addReservations} {...this.state} {...this.props}></CalendarWeek_Phone>
            )
        }
        if (this.props.mediaType === MediaType.PC || this.props.mediaType === MediaType.Tablet) {
            return (
                <CalendarWeek_PC onScroll={this.onScroll} editRes={this.popupEditRes} deleteRes={this.deleteRes} saveRes={this.saveRes} closePopup={this.closePopup} checkReservationLength={this.checkReservationLength} addReservations={this.addReservations} {...this.state} {...this.props}></CalendarWeek_PC>
            )
        }
    }
}

export default CalendarWeek