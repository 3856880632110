import SignupServiceProvider from 'components/Login/signupForm/SignupServiceProvider';
import * as React from 'react';
import { Dictionary, Translations } from 'Ressources/translations/Translations';

interface IProps {
    addComment: Function
}

interface IState {
    picture: any
}

class ForumReply extends React.Component<IProps, IState> {

    commentRef: React.RefObject<HTMLTextAreaElement> | undefined;

    state = {
        picture: "",
    }

    textChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.target.style.height = "auto";
        e.target.style.height = e.target.scrollHeight + "px";
    }

    getPicture = () => {
        return (
            <img src={"data:image/jpeg;base64," + this.state.picture} alt="No picture" className="PostPicture"></img>
        )
    }

    componentDidMount = () => {
        SignupServiceProvider.getPictureUser(2).then((e: any) => {
            if (e) {
                const buf = Buffer.from(e.data, 'binary').toString('base64');
                this.setState({ picture: buf })
            }
        })
    }

    private captureEnter = (e: any) => {
        var key = e.keyCode;
        if (key === 13) {
            console.log("pressed enter")
            this.props.addComment(e.target.value);
            e.target.value = ""
            e.stopPropagation();
        }
    }

    public render() {
        return (
            <div className='ForumPostCommentAnswerContainer'>
                {this.getPicture()}
                <textarea ref={this.commentRef} onChange={this.textChanged} onKeyDownCapture={this.captureEnter} placeholder='Write an answer...' className='ForumPostCommentAnswer'></textarea>
            </div>
        )
    }
}

export default ForumReply