import { Box, Chip, FormControl, InputLabel, ListSubheader, MenuItem, OutlinedInput, Select, Theme, useTheme } from '@mui/material';
import TicketProvider from 'components/HelpDesk/TicketProvider';
import SyndicateServiceProvider, { USER_TYPES } from 'components/Syndicate/SyndicateServiceProvider';
import * as React from 'react';
import { Dictionary, Translations } from 'Ressources/translations/Translations';

export interface IChipSelectWrapperProps {
    propertyId: number
    ticketId: number
}

export interface IChipSelectWrapperState {
    adminList: any
    employeeList: any
    residentList: any
    personName: any
}

export default class ChipSelectWrapper extends React.Component<IChipSelectWrapperProps, IChipSelectWrapperState> {

    private ITEM_HEIGHT = 48;
    private ITEM_PADDING_TOP = 8;
    private MenuProps = {
        PaperProps: {
            style: {
                maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    constructor(props: IChipSelectWrapperProps) {
        super(props);

        this.state = {
            adminList: [],
            employeeList: [],
            residentList: [],
            personName: []
        }
    }

    componentDidMount(): void {
        this.getCondoUsers();
        this.GetAssignedUsers();
    }

    private getCondoUsers = (): void => {
        SyndicateServiceProvider.GetPropertyUsers(this.props.propertyId, USER_TYPES.admins).then((res: any) => {
            this.setState({ adminList: res.data.admins });
        })
        SyndicateServiceProvider.GetPropertyUsers(this.props.propertyId, USER_TYPES.employees).then((res: any) => {
            this.setState({ employeeList: res.data.employees });
        })
        SyndicateServiceProvider.GetPropertyUsers(this.props.propertyId, USER_TYPES.residents).then((res: any) => {
            this.setState({ residentList: res.data.residents });
        })
    }

    private GetAssignedUsers = (): void => {
        TicketProvider.GetTicketAssignments(this.props.ticketId).then((res: any) => {
            if (res.successfull) {
                let assigned: any[] = [];
                res.data.assignments.forEach((assignment: any) => {
                    let user = this.state.employeeList.find((employee: any) => employee.user.id === assignment.assigned_user_id);
                    if (user !== undefined) {
                        assigned.push(user);
                    }
                });
                this.setState({ personName: assigned });
            }
        })
    }

    private handleChange = (event: any) => {
        const {
            target: { value },
        } = event;
        this.setState({ personName: typeof value === 'string' ? value.split(',') : value }, () => {
            TicketProvider.AssignTicket(this.props.ticketId, this.state.personName.map(((p: any) => p.user.id))).then((res: any) => {
                console.log(res);
                console.log(this.state.personName.map(((p: any) => p.user.id)));
            })
        });
    };

    public render() {
        return (
            <div>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-chip-label">Assign</InputLabel>
                    <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={this.state.personName}
                        onChange={this.handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected: any) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value: any) => (
                                    <Chip key={value.user.id} label={value.user.first_name + " " + value.user.last_name} />
                                ))}
                            </Box>
                        )}
                        MenuProps={this.MenuProps}
                    >
                        <ListSubheader><Translations value={Dictionary.admin}></Translations></ListSubheader>
                        {this.state.adminList.map((admin: any) => (
                            <MenuItem
                                key={admin.user.id}
                                value={admin}
                            >
                                {admin.user.first_name + " " + admin.user.last_name + "   ---   " + admin.user.id}
                            </MenuItem>
                        ))}
                        <ListSubheader><Translations value={Dictionary.employee}></Translations></ListSubheader>
                        {this.state.employeeList.map((employee: any) => (
                            <MenuItem
                                key={employee.user.id}
                                value={employee}
                            >
                                {employee.user.first_name + " " + employee.user.last_name + "   ---   " + employee.user.id}
                            </MenuItem>
                        ))}
                        <ListSubheader><Translations value={Dictionary.occupant}></Translations></ListSubheader>
                        {this.state.residentList.map((resident: any) => (
                            <MenuItem
                                key={resident.user.id}
                                value={resident}
                            >
                                {resident.user.first_name + " " + resident.user.last_name + "   ---   " + resident.user.id}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl >
            </div >
        );
    }
}
