import AvailabilityPopupContent from 'components/Syndicate/Modules/Amenities/Availabilities/AvailabilityPopupContent';
import DeleteButton from 'GlobalComponents/Buttons/DeleteButton';
import PromiseButton from 'GlobalComponents/Buttons/PromiseButton';
import DimmedPopup from 'GlobalComponents/Containers/Popup/DimmedPopup';
import LoadingCircle from 'GlobalComponents/Loading/LoadingCircle';
import { iReservation } from 'Models/Reservation';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import ReservationPopupContent from '../Reservation/ReservationPopupContent';

export enum PopupType {
    RESERVATION,
    AVAILABILITY
}

interface IProps {
    timeInterval: number
    selectedAmenity: number
    checkReservationLength: Function
    addReservations: Function
    week: Date[]
    loading: boolean
    showPopup: boolean
    popupContent: PopupType
    closePopup: Function
    saveRes: Function
    deleteRes: Function
    editRes: Function
    curRes: iReservation
    scrolling: boolean
    onScroll: React.UIEventHandler<HTMLDivElement>
}

interface IState {
}

class CalendarWeek_PC extends React.Component<IProps, IState> {

    private days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    private rowIds = 0;
    scrollRef: React.RefObject<HTMLDivElement>;


    constructor(props: IProps) {
        super(props);
        this.scrollRef = React.createRef<HTMLDivElement>();
    }

    componentDidMount(): void {
    }

    componentDidUpdate(prevProps: IProps) {
        this.rowIds = 0;
        if (prevProps.selectedAmenity == -1) {
            this.scrollRef.current?.scrollIntoView();
        }
    }

    private emptyRows = (len: number, hours: number) => {
        var row = [];

        for (let j = 0; j < len; j++) {
            row.push(
                <div className={hours % 2 === 0 ? "tableCell" : "tableCell shadowed"} id={this.rowIds.toString()}></div>
            )
        }
    }

    private row = (day: number) => {
        var row = [];

        row.push(<div className="tableCell" key={-1} id={this.rowIds.toString()} data-rowType={"header"}></div>)
        this.rowIds++;

        for (let i = 0; i < 1440; i += this.props.timeInterval) {
            let hours = Math.floor((i) / 60);
            let min = i % 60;

            let res = this.props.checkReservationLength(day, hours, min);

            let size = "30px";
            if (res.len > 0) {
                size = (30 * res.len).toString() + "px";
            }

            if (res.len > 1) {
                for (let j = 1; j < res.len; j++) {
                    row.push(
                        <div className={Math.floor((i + (j * this.props.timeInterval)) / 60) % 2 === 0 ? "tableCell" : "tableCell shadowed"}
                            id={this.rowIds.toString()} data-rowType={"data"} style={{ height: "0px", border: "none" }}> </div> //{this.rowIds}
                    )

                    this.rowIds++;
                }
            }

            if (i === 405) {
                row.push(
                    <div ref={this.scrollRef} className={hours % 2 === 0 ? "tableCell" : "tableCell shadowed"} id={this.rowIds.toString()} key={i}
                        data-rowType={"data"} style={{ height: size }} data-day={this.days[day]} data-hour={hours} data-min={min}>
                        {this.props.addReservations(res.len, hours, min, res.next, res.res, day, i, this.rowIds)}
                    </div>
                )
            } else {
                row.push(
                    <div className={hours % 2 === 0 ? "tableCell" : "tableCell shadowed"} id={this.rowIds.toString()} key={i}
                        data-rowType={"data"} style={{ height: size }} data-day={this.days[day]} data-hour={hours} data-min={min}>
                        {this.props.addReservations(res.len, hours, min, res.next, res.res, day, i, this.rowIds)}
                    </div>
                )
            }



            this.rowIds++;

            if (res.len > 1) {
                for (let j = 1; j < res.len; j++) {
                    row.push(
                        <div className={Math.floor((i + (j * this.props.timeInterval)) / 60) % 2 === 0 ? "tableCell" : "tableCell shadowed"}
                            id={this.rowIds.toString()} data-rowType={"data"} style={{ height: "0px", border: "none" }}> </div> //{this.rowIds}
                    )

                    this.rowIds++;
                }
            }

            if (res.len > 1) {
                i += this.props.timeInterval * (res.len - 1);
            }
        }

        return row;
    }

    private timeString = (time: number) => {
        let mins = time % 60;
        let hours = Math.floor(time / 60);
        if (mins === 0) {
            return (
                <div className="tableCell" style={{ fontWeight: "bold" }}>
                    <span>{(hours % 12 === 0 ? 12 : hours % 12) + (hours > 12 || hours === 0 ? " PM" : " AM")}</span>
                </div>
            )
        } else {
            return (
                <div className="tableCell">
                    <span>

                    </span>
                </div>
            )
        }
    }

    private body = () => {
        var timeRows = [];
        var rows = [];

        timeRows.push(<div className="tableCell" key={-1}></div>);

        for (let i = 0; i < 1440; i += this.props.timeInterval) {
            timeRows.push(
                <React.Fragment key={i}>
                    {this.timeString(i)}
                </React.Fragment>
            )
        }

        for (let i = 0; i < 7; i++) {
            rows.push(
                <div className="tableColumn" key={i}>
                    {this.row(i).map((e: any) => {
                        return (e)
                    })}
                </div>
            )
        }

        return (
            <div className={this.props.scrolling ? "CalendarBodyContainer scrolling" : "CalendarBodyContainer"} style={this.props.loading ? { position: "relative", top: "-100%" } : {}}>
                <div className="tableTime">
                    <div className="tableColumn">
                        {timeRows}
                    </div>
                </div>
                <div className="tableBody">
                    {rows}
                </div>
            </div>
        )
    }

    private PopupContent = () => {
        switch (this.props.popupContent) {
            case PopupType.RESERVATION:
                return (
                    <ReservationPopupContent curRes={this.props.curRes} editRes={this.props.editRes} title={Dictionary.editReservation} />
                )
            case PopupType.AVAILABILITY:
                return (
                    <AvailabilityPopupContent curRes={this.props.curRes} editRes={this.props.editRes} title={Dictionary.Name} />
                )
        }
    }

    public render() {

        let buttons = (<React.Fragment>
            <DeleteButton onClick={() => this.props.deleteRes(this.props.curRes)} />
            <PromiseButton onClick={this.props.closePopup} basicText={Dictionary.cancel} loadingText={Dictionary.cancel} doneText={Dictionary.cancel} width="25%" />
            <PromiseButton onClick={() => this.props.saveRes(this.props.curRes)} basicText={Dictionary.save} loadingText={Dictionary.save} doneText={Dictionary.save} width="25%" />
        </React.Fragment>)

        return (
            <div className="CalendarWeekContainer" onScroll={this.props.onScroll}>
                {this.props.selectedAmenity === -1 ?
                    <div className="NoLocationContainer"><Translations value={Dictionary.PleaseSelectLocation}></Translations></div> :
                    <React.Fragment>
                        {this.props.loading ? <LoadingCircle blurEffect={true}></LoadingCircle> : null}
                        <div className="CalendarHeaderContainer" style={this.props.loading ? { position: "relative", top: "-100%" } : {}}>
                            <div className="tableHead">
                                <div className="tableRow">
                                    <div className="tableCell">
                                        <div className="cellWeekDay">{this.props.week[0].toLocaleDateString(undefined, { month: "short" })}</div>
                                        <div className="clockIcon"><ImageLoader name="ClockIcon" className="clockIcon"></ImageLoader></div>
                                    </div>
                                    {this.props.week ? this.props.week.map((week: Date) => {
                                        return (
                                            <div className='tableCell'>
                                                <div className="cellWeekDay" key={week.getDate() + "weekday"}>{week.toLocaleDateString(undefined, { weekday: "short" })}</div>
                                                <div className="cellDay" key={week.getDate() + "day"}>{week.toLocaleDateString(undefined, { day: "2-digit" })}</div>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                            </div>
                        </div>
                        {this.body()}
                        <DimmedPopup visible={this.props.showPopup} closePopup={this.props.closePopup} content={this.PopupContent()} title={Dictionary.editReservation} buttons={buttons}></DimmedPopup>
                    </React.Fragment>}
            </div>
        )
    }
}

export default CalendarWeek_PC