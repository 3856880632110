import iCondo from 'Models/Condo';
import * as React from 'react';
import Activities from 'Ressources/Activities';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import { pageStates } from '../SyndicatePage';

interface IProps {
    changeActivity: Function
    condoList: iCondo[]
    yourSyndicateClicked: Function
    condoItem: Function
    changeState: Function
}

interface IState {
}

class BaseOptions_PC extends React.Component<IProps, IState> {

    public render() {
        var imgCounter = 0;
        return (
            <React.Fragment>
                <div className="content">
                    <div className='subContent'>
                        <div className="SyndicateButtonContainer">
                            <button className="Syndicate_button" onClick={() => this.props.changeActivity(Activities.CreateSyndicate)}>
                                <h3><Translations value={Dictionary.createNewCoownerSyndicate}></Translations></h3>
                                <hr className="divider_solid"></hr>
                                <ImageLoader name="CreateSyndicateIcon" className="syndicateIcon"></ImageLoader>
                            </button>
                        </div>
                        <div className='SyndicateButtonContainer'>
                            <button className="Syndicate_button" onClick={() => this.props.changeState(pageStates.joinSyndicate)}>
                                <h3><Translations value={Dictionary.joinExistingSyndicate}></Translations></h3>
                                <hr className="divider_solid"></hr>
                                <ImageLoader name="JoinSyndicateIcon" className="syndicateIcon"></ImageLoader>
                            </button>
                        </div>
                    </div>

                    <div className="Your_syndicates_container">
                        <h3><Translations value={Dictionary.yourSyndicates}></Translations></h3>
                        <hr className="divider_solid"></hr>
                        {this.props.condoList.map((condo: iCondo, index: number) => (
                            <div key={condo.name} className="condo_item_container" onClick={() => this.props.yourSyndicateClicked(condo)}>{this.props.condoItem(condo, index, (imgCounter++ % 10))}</div>
                        ))}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default BaseOptions_PC