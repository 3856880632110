export default interface iMassInviteData {
    data: any[],
    id: number,
    status: MassInviteStatus,
    errors: any[],
    isInEditMode: boolean
}

export enum MassInviteStatus {
    Sent,
    Accepted,
    Error,
    NotSent,
    InUse
}