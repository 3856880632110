import iCondo from 'Models/Condo';
import * as React from 'react';
import MediaType from 'Ressources/MediaType';
import "../../SyndicatePage.scss"
import SyndicateServiceProvider from '../../SyndicateServiceProvider';
import SyndicateShareKey_PC from './SyndicateShareKey_PC';
import SyndicateShareKey_Phone from './SyndicateShareKey_Phone';

interface IProps {
    changeState: any
    currentCondo: iCondo
    mediaType: MediaType
}

interface IState {
    syndicateKey: string
}

class SyndicateShareKey extends React.Component<IProps, IState> {

    state = {
        syndicateKey: ""
    }

    componentDidMount = () => {
        SyndicateServiceProvider.GenerateAccessKey(this.props.currentCondo.id).then((res: any) => {
            if (res.successfull) {
                this.setState({ syndicateKey: res.data })
            }
        })
    }

    addKeyToClipboard = () => {
        navigator.clipboard.writeText(this.state.syndicateKey);
        return Promise.resolve(0);
    }

    public render() {
        if (this.props.mediaType === MediaType.Phone) {
            return (
                <SyndicateShareKey_Phone addKeyToClipboard={this.addKeyToClipboard} {...this.state} {...this.props}></SyndicateShareKey_Phone>
            )
        }
        if (this.props.mediaType === MediaType.PC || this.props.mediaType === MediaType.Tablet) {
            return (
                <SyndicateShareKey_PC addKeyToClipboard={this.addKeyToClipboard} {...this.state} {...this.props}></SyndicateShareKey_PC>
            )
        }
    }
}

export default SyndicateShareKey

