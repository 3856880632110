import LoadingCircle from 'GlobalComponents/Loading/LoadingCircle';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from 'Ressources/translations/Translations';


interface IProps {
    timeInterval: number
    selectedAmenity: number
    checkReservationLength: Function
    addReservations: Function
    week: Date[]
    loading: boolean
}

interface IState {
}

class CalendarWeek_Phone extends React.Component<IProps, IState> {

    private days = ["S", "M", "T", "W", "T", "F", "S"];
    private rowIds = 0;

    private emptyRows = (len: number, hours: number) => {
        var row = [];

        for (let j = 0; j < len; j++) {
            row.push(
                <div className={hours % 2 === 0 ? "tableCell" : "tableCell shadowed"} id={this.rowIds.toString()}></div>
            )
        }
    }

    componentDidUpdate(prevProps: IProps) {
        this.rowIds = 0;
    }

    private row = (day: number) => {
        var row = [];

        row.push(<div className="tableCell" key={-1} id={this.rowIds.toString()} data-rowType={"header"}></div>)
        this.rowIds++;

        for (let i = 0; i < 1440; i += this.props.timeInterval) {
            let hours = Math.floor((i) / 60);
            let min = i % 60;

            let res = this.props.checkReservationLength(day, hours, min);

            let size = "30px";
            if (res.len > 0) {
                size = (30 * res.len).toString() + "px";
            }

            if (res.len > 1) {
                for (let j = 1; j < res.len; j++) {
                    row.push(
                        <div className={Math.floor((i + (j * this.props.timeInterval)) / 60) % 2 === 0 ? "tableCell" : "tableCell shadowed"}
                            id={this.rowIds.toString()} data-rowType={"data"} style={{ height: "0px", border: "none" }}> </div> //{this.rowIds}
                    )

                    this.rowIds++;
                }
            }

            row.push(
                <div className={hours % 2 === 0 ? "tableCell" : "tableCell shadowed"} id={this.rowIds.toString()} key={i}
                    data-rowType={"data"} style={{ height: size }} data-day={this.days[day]} data-hour={hours} data-min={min}>
                    {this.props.addReservations(res.len, hours, min, res.next, res.res, day, i, this.rowIds)}
                </div>
            )

            this.rowIds++;

            if (res.len > 1) {
                for (let j = 1; j < res.len; j++) {
                    row.push(
                        <div className={Math.floor((i + (j * this.props.timeInterval)) / 60) % 2 === 0 ? "tableCell" : "tableCell shadowed"}
                            id={this.rowIds.toString()} data-rowType={"data"} style={{ height: "0px", border: "none" }}> </div> //{this.rowIds}
                    )

                    this.rowIds++;
                }
            }

            if (res.len > 1) {
                i += this.props.timeInterval * (res.len - 1);
            }
        }

        return row;
    }

    private timeString = (time: number) => {
        let mins = time % 60;
        let hours = Math.floor(time / 60);
        if (mins === 0) {
            return (
                <div className="tableCell" style={{ fontWeight: "bold" }}>
                    <span>{(hours % 12 === 0 ? 12 : hours % 12) + (hours > 12 || hours === 0 ? "pm" : "am")}</span>
                </div>
            )
        } else {
            return (
                <div className="tableCell">
                    <span>
                        {mins}
                    </span>
                </div>
            )
        }
    }

    private body = () => {
        var timeRows = [];
        var rows = [];

        timeRows.push(<div className="tableCell" key={-1}></div>);

        for (let i = 0; i < 1440; i += this.props.timeInterval) {
            timeRows.push(
                <React.Fragment key={i}>
                    {this.timeString(i)}
                </React.Fragment>
            )
        }

        for (let i = 0; i < 7; i++) {
            rows.push(
                <div className="tableColumn" key={i}>
                    {this.row(i).map((e: any) => {
                        return (e)
                    })}
                </div>
            )
        }

        return (
            <div className="CalendarBodyContainer" style={this.props.loading ? {position: "relative", top:"-100%"} : {}}>
                <div className="tableTime">
                    <div className="tableColumn">
                        {timeRows}
                    </div>
                </div>
                <div className="tableBody">
                    {rows}
                </div>
            </div>
        )
    }

    public render() {
        return (
            <div className="CalendarWeekContainer">
                {this.props.selectedAmenity === -1 ?
                    <div className="NoLocationContainer"><Translations value={Dictionary.PleaseSelectLocation}></Translations></div> :
                    <React.Fragment>
                        {this.props.loading ? <LoadingCircle blurEffect={true}></LoadingCircle> : null}
                        <div className="CalendarHeaderContainer" style={this.props.loading ? {position: "relative", top:"-100%"} : {}}>
                            <div className="tableHead">
                                <div className="tableRow">
                                    <div className="tableCell"><ImageLoader name="ClockIcon" className="clockIcon"></ImageLoader></div>
                                    {this.props.week.map((week: Date) => {
                                        return (
                                            <div className="tableCell" key={week.getDate()}>{week.toLocaleDateString(undefined, { weekday: "narrow", day: "2-digit", month: undefined })}</div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        {this.body()}
                    </React.Fragment>}
            </div>
        )
    }
}

export default CalendarWeek_Phone