import * as React from 'react';
import CalendarWeek from '../CalendarWeek/CalendarWeek';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import ImageLoader from 'Ressources/Images/ImageLoader';
import MediaType from 'Ressources/MediaType';
import { PopupType } from '../CalendarWeek/CalendarWeek_PC';

interface IProps {
    selectedAmenity: number
    selectedWeek: Date
    drawLocations: Function
    changeWeek: Function
    mediaType: MediaType
}

interface IState {
}

class Calendar_PC extends React.Component<IProps, IState> {

    public render() {
        return (
            <div className="CalendarContainer">
                <div className="LocationSelector">
                    <h2><Translations value={Dictionary.Locations}></Translations></h2>
                    {this.props.drawLocations()}
                </div>
                <div className="CalendarWeek">
                    <React.Fragment>
                        <ImageLoader name="ArrowLeft" className="arrowIcon" onClick={() => { this.props.changeWeek(false) }}></ImageLoader>
                        <CalendarWeek popupContent={PopupType.RESERVATION} timeInterval={15} {...this.props}></CalendarWeek>
                        <ImageLoader name="ArrowRight" className="arrowIcon" onClick={() => { this.props.changeWeek(true) }}></ImageLoader>
                    </React.Fragment>
                </div>
            </div>
        )
    }
}

export default Calendar_PC