import ArrowButton from 'GlobalComponents/Buttons/ArrowButton';
import iCondo from 'Models/Condo';
import iTicket, { PriorityType, Status } from 'Models/Ticket';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import MediaType from 'Ressources/MediaType';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import CreateTicket from './CreateTicket';
import './HelpDesk.scss'
import Ticket from './Ticket';
import TicketProvider from './TicketProvider';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import SelectMultipleWrapper from 'GlobalComponents/Inputs/SelectMultipleWrapper';
import AmenitySelect from 'GlobalComponents/Inputs/AmenitySelect';
import EditTicket from './EditTicket';
import { Divider } from '@material-ui/core';

enum PageState {
    Board,
    CreateTicket,
    EditTicket,
}

export interface IHelpDeskProps {
    navigationBarClosed: boolean
    mediaType: MediaType
    selectedProperty: iCondo
}

export interface IHelpDeskState {
    pageState: PageState
    tickets: iTicket[]
    createDateFilter: [Date, Date] | string
    updateDateFilter: [Date, Date] | string
    closedDateFilter: [Date, Date] | string
    priorityFilter: PriorityType[]
    amenityIdFilter: number
    selectedTicket: iTicket | undefined
    showFilters: boolean
}

export default class HelpDesk extends React.Component<IHelpDeskProps, IHelpDeskState> {

    priorityLabels = [Dictionary.low, Dictionary.medium, Dictionary.high, Dictionary.urgent]

    constructor(props: IHelpDeskProps) {
        super(props);

        this.state = {
            pageState: PageState.Board,
            tickets: [],
            createDateFilter: "",
            updateDateFilter: "",
            closedDateFilter: "",
            priorityFilter: [],
            amenityIdFilter: -1,
            selectedTicket: undefined,
            showFilters: true
        }
    }

    private GetTickets = () => {
        if (this.props.selectedProperty.id === -1) {
            return
        }

        let priorities = this.state.priorityFilter.map((priority: PriorityType) => PriorityType[priority])

        TicketProvider.GetTickets(
            this.props.selectedProperty.id,
            this.state.createDateFilter?.[0],
            this.state.createDateFilter?.[1],
            this.state.updateDateFilter?.[0],
            this.state.updateDateFilter?.[1],
            this.state.closedDateFilter?.[0],
            this.state.closedDateFilter?.[1],
            priorities,
            this.state.amenityIdFilter === -1 ? undefined : this.state.amenityIdFilter).then((res) => {
                if (res?.successfull) {
                    this.setState({ tickets: res.data.tickets })
                }
            })
    }

    componentDidUpdate = (prevProps: IHelpDeskProps) => {
        if (prevProps.navigationBarClosed !== this.props.navigationBarClosed) {
            if (this.props.navigationBarClosed) {
                let div = document.getElementsByClassName("HelpDeskContent")[0];
                div.classList.remove('open')
                div.classList.add('closed')
            }
            else {
                let div = document.getElementsByClassName("HelpDeskContent")[0];
                div.classList.remove('closed')
                div.classList.add('open')
            }
        }

        if (prevProps.selectedProperty !== this.props.selectedProperty) {
            this.GetTickets();
        }
    }

    private RenderRow = (rowType: Status) => {
        let tickets = this.state.tickets.filter((ticket: iTicket) => ticket.status === Status[rowType])
        let renderElements: JSX.Element[] = []

        tickets.map((ticket: iTicket) => {
            renderElements.push(
                <Ticket ticket={ticket} EditTicket={this.EditTicket}></Ticket>
            )
        })

        return renderElements;
    }

    private Board = () => {
        return (
            <React.Fragment>
                <table className='HelpDeskTable'>
                    <thead>
                        <tr>
                            <th className='text-center'><Translations value={Dictionary.open}></Translations></th>
                            <th className='text-center'><Translations value={Dictionary.inProgress}></Translations></th>
                            <th className='text-center'><Translations value={Dictionary.closed}></Translations></th>
                            <th className='text-center'><Translations value={Dictionary.onHold}></Translations></th>
                            <th className='text-center'><Translations value={Dictionary.invalid}></Translations></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className='tableRowContent'>
                                    {this.RenderRow(Status.OPEN)}
                                </div>
                            </td>
                            <td>
                                <div className='tableRowContent'>
                                    {this.RenderRow(Status.IN_PROGRESS)}
                                </div>
                            </td>
                            <td>
                                <div className='tableRowContent'>
                                    {this.RenderRow(Status.CLOSED)}
                                </div>
                            </td>
                            <td>
                                <div className='tableRowContent'>
                                    {this.RenderRow(Status.ON_HOLD)}
                                </div>
                            </td>
                            <td>
                                <div className='tableRowContent'>
                                    {this.RenderRow(Status.INVALID)}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='AddTicketButton' onClick={() => this.setState({ pageState: PageState.CreateTicket })}>
                    <ImageLoader className='AddTicketIcon' name='PlusIcon' />
                </div>
            </React.Fragment>
        )
    }

    private Filters = () => {
        return (
            <div className='Filters' style={{ width: "80%" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {this.state.showFilters ? <Divider style={{ width: "100%", marginTop: "16px", paddingTop: "2xp" }} /> : <div className='FilterEmptySpace' />}
                    <ImageLoader className='FilterIcon' name='FilterIcon' onClick={() => { this.setState({ showFilters: !this.state.showFilters }) }} />
                </div>
                {this.state.showFilters ?
                    <div className="FilterInputs">
                        <div className='DateFilter'>
                            <span>Created at </span>
                            <DateRangePicker value={this.state.createDateFilter} onChange={(dates: any) => this.setState({ createDateFilter: dates }, this.GetTickets)}></DateRangePicker >
                        </div>
                        <Divider orientation='vertical' style={{ height: "75%", paddingLeft: "2px" }} />
                        <div className='DateFilter'>
                            <span>Updated at </span>
                            <DateRangePicker value={this.state.updateDateFilter} onChange={(dates: any) => this.setState({ updateDateFilter: dates }, this.GetTickets)}></DateRangePicker >
                        </div>
                        <Divider orientation='vertical' style={{ height: "75%", paddingLeft: "2px" }} />
                        <div className='DateFilter'>
                            <span>Closed at </span>
                            <DateRangePicker value={this.state.closedDateFilter} onChange={(dates: any) => this.setState({ closedDateFilter: dates }, this.GetTickets)}></DateRangePicker >
                        </div>
                        <Divider orientation='vertical' style={{ height: "75%", paddingLeft: "2px" }} />
                        <div className='DateFilter'>
                            <span>Priority </span>
                            <SelectMultipleWrapper optionsText={this.priorityLabels} label={Dictionary.priority} name="PriorityFilter" options={PriorityType} handleChange={(value: any) => this.setState({ priorityFilter: value }, this.GetTickets)}></SelectMultipleWrapper>
                        </div>
                        <Divider orientation='vertical' style={{ height: "75%", paddingLeft: "2px" }} />
                        <div className='DateFilter'>
                            <span>Amenity </span>
                            <AmenitySelect {...this.props} allOption handleChange={(id: any) => { this.setState({ amenityIdFilter: id }, this.GetTickets) }} label={Dictionary.amenity} name="amenitySelect" />
                        </div>
                    </div> :
                    null}
            </div>
        )
    }

    private Back = (withRefresh: boolean) => {
        if (withRefresh) {
            this.GetTickets();
        }
        this.setState({ pageState: PageState.Board })
    }

    private EditTicket = (selectedTicket: iTicket) => {
        this.setState({ pageState: PageState.EditTicket, selectedTicket: selectedTicket });
    }

    private RenderContent = () => {
        switch (this.state.pageState) {
            case PageState.Board:
                return (
                    <React.Fragment>
                        <div className='Header'>
                            <h1><Translations value={Dictionary.helpDesk} /></h1>
                        </div>
                        {this.Filters()}
                        {this.Board()}
                    </React.Fragment>
                )
            case PageState.CreateTicket:
                return (
                    <React.Fragment>
                        <CreateTicket Back={this.Back} {...this.props}></CreateTicket>
                        <ArrowButton onClick={this.Back} text={Dictionary.back} left></ArrowButton>
                    </React.Fragment>
                )
            case PageState.EditTicket:
                return (
                    <React.Fragment>
                        <EditTicket Back={this.Back} {...this.props} {...this.state}></EditTicket>
                        <ArrowButton onClick={this.Back} text={Dictionary.back} left></ArrowButton>
                    </React.Fragment>
                )
        }

    }

    public render() {
        return (
            <div className='HelpDeskContent'>
                {this.RenderContent()}
            </div>
        );
    }
}
