import iCondo from 'Models/Condo';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from '../../../../Ressources/translations/Translations';
import { pageStates } from '../../SyndicatePage';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

// import Swiper core and required modules
import SwiperCore, {
    Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination]);

interface IProps {
    changeState: any
    currentCondo: iCondo
}

interface IState {
}

class SyndicateInviteOptions_Phone extends React.Component<IProps, IState> {


    public render() {

        return (
            <div className="subContainer unscrollable">
                <Swiper slidesPerView={1} spaceBetween={30} loop={true} pagination={{
                    "clickable": true
                }} className="mySwiper">
                    <SwiperSlide>
                        <div className="options mainOptions" >
                            <span><Translations value={Dictionary.inviteUsersJoinSyndicate} var={[this.props.currentCondo.name]}></Translations></span>
                            <div className="horizontalDivider"></div>
                            <div className="subOptions">
                                <div className="option clickable" onClick={() => this.props.changeState(pageStates.individualInvite)}>
                                    <ImageLoader name="IndividualInviteIcon" className="icon"></ImageLoader>
                                    <span><Translations value={Dictionary.individualInvite}></Translations></span>
                                </div>
                                <div className="verticalDivider"></div>
                                <div className="option clickable" onClick={() => this.props.changeState(pageStates.groupInvite)}>
                                    <ImageLoader name="GroupInviteIcon" className="icon"></ImageLoader>
                                    <span><Translations value={Dictionary.groupInvite}></Translations></span>
                                </div>
                            </div>
                            <div className="horizontalDivider"></div>
                            <div className="subOptions">
                                <div className="option clickable" onClick={() => this.props.changeState(pageStates.shareKey)}>
                                    <ImageLoader name="GiveKeyIcon" className="icon"></ImageLoader>
                                    <span><Translations value={Dictionary.shareSyndicateAccessKey}></Translations></span>
                                </div>
                                <div className="verticalDivider"></div>
                                <div className="option clickable" onClick={() => this.props.changeState(pageStates.manageListCoowners)}>
                                    <ImageLoader name="PersonListIcon" className="icon"></ImageLoader>
                                    <span><Translations value={Dictionary.manageListCoowners}></Translations></span>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="options mainOptions">
                            <span><Translations value={Dictionary.manageEmployees}></Translations></span>
                            <div className="horizontalDivider"></div>
                            <div className="subOptions">
                                <div className="option clickable" onClick={() => this.props.changeState(pageStates.addEmployee)}>
                                    <ImageLoader name="AddEmployeeIcon" className="icon"></ImageLoader>
                                    <span><Translations value={Dictionary.addEmployee}></Translations></span>
                                </div>
                                <div className="verticalDivider"></div>
                                <div className="option clickable" onClick={() => this.props.changeState(pageStates.manageListEmployees)}>
                                    <ImageLoader name="EmployeeListIcon" className="icon"></ImageLoader>
                                    <span><Translations value={Dictionary.manageListEmployees}></Translations></span>
                                </div>
                            </div>
                            <div className="horizontalDivider"></div>
                            <div className="subOptions">
                                <div className="option clickable" onClick={() => this.props.changeState(pageStates.manageListAdmin)}>
                                    <ImageLoader name="EditUserIcon" className="icon"></ImageLoader>
                                    <span><Translations value={Dictionary.editAdminList}></Translations></span>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>

            </div>
        )
    }
}

export default SyndicateInviteOptions_Phone