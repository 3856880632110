import iCondo from 'Models/Condo';
import * as React from 'react';
import Select from "react-dropdown-select";
import "./SyndicateSelector.scss"

interface IProps {
    image: any
    condoList: iCondo[]
    selectedProperty: iCondo
    changeSelectedProperty: Function
}

interface IState {
}

class SyndicateSelector extends React.Component<IProps, IState> {
    public render() {
        return (
            <div className="SelectorContainer">
                <img src={"data:image/jpeg;base64," + this.props.image} alt="No picture" className="SyndicatePic"></img>
                <Select className="Selector" options={this.props.condoList} color={"#696969"}
                    onChange={(val: any) => this.props.changeSelectedProperty({ value: val })} values={[this.props.selectedProperty]}
                    multi={false} labelField={"name"} valueField={"name"} separator></Select>
            </div>
        )
    }
}

export default SyndicateSelector