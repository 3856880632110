import * as React from 'react';
import ManagerProvider from '../ManagerProvider';
import iAmenity from 'Models/Amenity';
import iCondo from 'Models/Condo';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import "./AddLocation.scss";
import CalendarServiceProvider from 'components/Calendar/CalendarServiceProvider';
import ImageLoader from 'Ressources/Images/ImageLoader';
import RecurringEvents from './RecurringEvents';

enum Recurrence {
    daily,
    weekly,
    monthly,
    none
}

interface IProps {
    selectedProperty: iCondo
}

interface IState {
    desc: string,
    name: string,
    loc: string,
    locations: any[],
    selectedAmenity: number
}

class AddLocation extends React.Component<IProps, IState> {

    state = {
        desc: "",
        name: "",
        loc: "",
        locations: [],
        selectedAmenity: -1
    }

    private handleChange = (event: any) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value } as Pick<IState, keyof IState>);
    }

    private createAmenity = () => {
        let amenity: iAmenity = { amenity_id: this.props.selectedProperty.id, description: this.state.desc, location: this.state.loc, name: this.state.name }
        ManagerProvider.createAmenity(amenity).then((res: any) => {
            this.getAmenities();
        })
    }

    componentDidMount() {
        if (this.props.selectedProperty && this.props.selectedProperty.id !== -1) {
            this.getAmenities();
        }
    }

    componentDidUpdate(props: IProps) {
        if (this.props.selectedProperty && this.props.selectedProperty.id !== -1 && this.props.selectedProperty !== props.selectedProperty) {
            this.getAmenities();
        }
    }

    private getAmenities = () => {
        CalendarServiceProvider.getPropertyAmenities(this.props.selectedProperty.id).then((res: any) => {
            let loc = res.data != undefined ? res.data.amenities : [];
            this.setState({ locations: loc });
        })
    }

    private deleteAmenity = (e: any) => {
        ManagerProvider.deleteAmenity(e.currentTarget.getAttribute("data-amenityid")).then((res: any) => {
            this.getAmenities();
        })
    }

    private drawLocations = () => {
        if (this.state) {
            return this.state.locations.map((loc: any) => {
                return (
                    <div className={this.state.selectedAmenity === loc.amenity_id ? "amenity selected" : "amenity"} key={"row" + loc.amenity_id} onClick={() => this.setState({ selectedAmenity: loc.amenity_id })}>
                        <span>{loc.name}</span>
                        <div className="imgContainer" data-amenityid={loc.amenity_id} onClick={this.deleteAmenity}>
                            <ImageLoader name="DeleteIcon" className="DeleteIcon"></ImageLoader>
                        </div>
                    </div>
                )
            });
        }
    }

    public render() {
        return (
            this.state ?
                <div className="Container">
                    <div className="amenityContainer">
                        <div className="createAmenityContainer">
                            <label htmlFor="name"><Translations value={Dictionary.Name} /></label>
                            <input value={this.state.name} name={"name"} onChange={(val: any) => this.handleChange(val)}></input>
                            <label htmlFor="loc"><Translations value={Dictionary.Location} /></label>
                            <input value={this.state.loc} name={"loc"} onChange={(val: any) => this.handleChange(val)}></input>
                            <label htmlFor="desc"><Translations value={Dictionary.Description} /></label>
                            <input value={this.state.desc} name={"desc"} onChange={(val: any) => this.handleChange(val)}></input>
                            <button onClick={this.createAmenity}><Translations value={Dictionary.CreateAmenity} /></button>
                        </div>
                        <div className="amenityListContainer">
                            {this.drawLocations()}
                        </div>
                    </div>
                    <RecurringEvents selectedAmenity={this.state.selectedAmenity} />
                </div> : <div />
        )
    }
}

export default AddLocation