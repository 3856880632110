import * as React from 'react';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import "./LeftHighlightButton.scss"

interface IProps {
    basicText: Dictionary
    loadingText: Dictionary
    doneText: Dictionary
    onClick: any
    width?: string
    height?: string
    className?: string
}

interface IState {
    buttonState: iButtonState
}

enum iButtonState {
    base,
    loading,
    done,
}

class LeftHighlightButton extends React.Component<IProps, IState> {

    state = {
        buttonState: iButtonState.base
    }

    private onClick = () => {
        this.setState({ buttonState: iButtonState.loading });
        let pro = this.props.onClick();
        pro.then((res: any) => {
            this.setState({ buttonState: iButtonState.done });
        })
    }

    private onHover = () => {
        if (this.state.buttonState === iButtonState.done) {
            this.setState({ buttonState: iButtonState.base });
        }
    }

    private buttonText = () => {
        switch (this.state.buttonState) {
            case iButtonState.base:
                return (
                    <Translations value={this.props.basicText}></Translations>
                )
            case iButtonState.loading:
                return (
                    <Translations value={this.props.loadingText}></Translations>
                )
            case iButtonState.done:
                return (
                    <Translations value={this.props.doneText}></Translations>
                )
        }
    }

    public render() {
        let width = this.props.width ? this.props.width : "40%"
        let className = this.props.className === undefined ? "" : this.props.className
        return (
            <div className={"leftHighlightButton " + className} onClick={this.onClick} style={{ width: width, height: this.props.height }} onMouseEnter={this.onHover}><div className='buffer lineHighlight'></div><span>{this.buttonText()}</span><div className='buffer'></div></div>
        )
    }
}

export default LeftHighlightButton