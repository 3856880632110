import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import ForumComment from './ForumComment';
import ForumReply from './ForumReply';

interface IProps {
    content: any
    addComment: Function
}

interface IState {
    showComments: boolean
}

class ForumComments extends React.Component<IProps, IState> {

    state = {
        showComments: false
    }

    toggleComments = () => {
        this.setState({ showComments: true });
    }

    renderComments = () => {
        return (
            <React.Fragment>
                <div className="horizontalDivider"></div>
                <div className='ForumPostCommentContainer'>
                    {this.props.content.comments ? this.props.content.comments.map((comment: any) => {
                        return (
                            <ForumComment comment={comment}></ForumComment>
                        )
                    }) : null}
                </div>
                <ForumReply {...this.props}></ForumReply>
            </React.Fragment>

        )
    }

    public render() {
        return (
            <React.Fragment>
                <div className="horizontalDivider"></div>
                <div className='ForumPostInteractions'>
                    <div className='CommentInteraction' onClick={this.toggleComments}>
                        <ImageLoader name="CommentIcon" className='CommentIcon'></ImageLoader>
                        <div>Comment</div>
                    </div>

                </div>
                <div className='ForumPostComments'>
                    {this.state.showComments ? this.renderComments() : null}
                </div>
            </React.Fragment>
        )
    }
}

export default ForumComments