import iCondo from 'Models/Condo';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import MediaType from 'Ressources/MediaType';
import { Dictionary, Translations } from '../../../../Ressources/translations/Translations';
import { pageStates } from '../../SyndicatePage';
import "../../SyndicatePage.scss"
import SyndicateBaseOptions_PC from './SyndicateBaseOptions_PC';
import SyndicateBaseOptions_Phone from './SyndicateBaseOptions_Phone';

interface IProps {
    changeState: any
    currentCondo: iCondo
    mediaType: MediaType
}

interface IState {
}

class SyndicateBaseOptions extends React.Component<IProps, IState> {

    public render() {
        if (this.props.mediaType === MediaType.Phone) {
            return (
                <SyndicateBaseOptions_Phone {...this.props}></SyndicateBaseOptions_Phone>
            )
        }
        if (this.props.mediaType === MediaType.PC || this.props.mediaType === MediaType.Tablet) {
            return (
                <SyndicateBaseOptions_PC {...this.props}></SyndicateBaseOptions_PC>
            )

        }
    }
}

export default SyndicateBaseOptions