import * as React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ManagerProvider from '../ManagerProvider';
import iAmenity from 'Models/Amenity';
import iCondo from 'Models/Condo';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import "./RecurringEvents.scss";
import CalendarServiceProvider from 'components/Calendar/CalendarServiceProvider';
import ImageLoader from 'Ressources/Images/ImageLoader';
import DynamicRadioInput, { iRadioData } from 'GlobalComponents/Inputs/DynamicRadioInput';
import PromiseButton from 'GlobalComponents/Buttons/PromiseButton';
import { iReservationFetcher } from 'Models/Reservation';

enum Recurrence {
    daily,
    weekly,
    monthly,
    none
}

interface IProps {
    selectedAmenity: number
}

interface IState {
    startTime: Date,
    endTime: Date,
    recurrence: Recurrence
    recurringRes: any
}

class RecurringEvents extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        var curr = new Date();
        var startTime = new Date(curr.setDate(curr.getDate() - curr.getDay()));
        startTime.setMinutes(0);
        var endTime = new Date(startTime);
        endTime.setMinutes(45);

        this.state = {
            startTime: startTime,
            endTime: endTime,
            recurrence: Recurrence.none,
            recurringRes: [],
        }
    }

    private editRes = (e: any, startTime: boolean) => {
        let newTime = e.target.value.split(':');
        if (startTime) {
            let time = new Date(this.state.startTime)
            time.setHours(newTime[0]);
            time.setMinutes(newTime[1]);
            this.setState({ startTime: time })

        } else {
            let time = new Date(this.state.endTime)
            time.setHours(newTime[0]);
            time.setMinutes(newTime[1]);
            this.setState({ endTime: time })
        }

        return Promise.resolve(0);
    }

    public addTimeTemplate = (day: number) => {
        let startTime = this.state.startTime.getHours().toString().padStart(2, "0") + ":" + this.state.startTime.getMinutes().toString().padStart(2, "0");
        let endTime = this.state.endTime.getHours().toString().padStart(2, "0") + ":" + this.state.endTime.getMinutes().toString().padStart(2, "0");

        return (
            <div className={day + "Container"}>
                <label htmlFor={day + "timeStart"}>From</label>
                <input type="time" id={day + "timeStart"} onChange={(e: any) => this.editRes(e, true)} value={startTime} required step={900}></input>
                <label htmlFor={day + "timeEnd"}>To</label>
                <input type="time" id={day + "timeEnd"} onChange={(e: any) => this.editRes(e, false)} value={endTime} required step={900}></input>
                {/* <button onClick={(e) => this.addTime(e, day)}>Add new time</button> */}
            </div>
        )
    }

    private handleChange = (event: any) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value } as Pick<IState, keyof IState>);
    }

    private sendRecurringEvent = () => {
        let isMonthly = false;
        let recurrence = 0;
        switch (this.state.recurrence) {
            case (Recurrence.daily):
                recurrence = 1440;
                break;
            case (Recurrence.weekly):
                recurrence = 10080;
                break;
            case (Recurrence.monthly):
                isMonthly = true;
                recurrence = 43800;
                break;
        }
        return ManagerProvider.createRecurringEvent(this.props.selectedAmenity, this.state.startTime, this.state.endTime, isMonthly, recurrence).then((res: any) => {
            this.getReservations()
        })
    }

    componentDidMount() {
        if (this.props.selectedAmenity !== -1) {
            this.getReservations()
        }
    }

    componentDidUpdate(props: IProps) {
        if (this.props.selectedAmenity !== -1 && this.props.selectedAmenity !== props.selectedAmenity) {
            this.getReservations()
        }
    }

    private getReservations = () => {
        ManagerProvider.getReservations(this.props.selectedAmenity).then((res: any) => {
            this.setState({ recurringRes: res.data.reservations })
        })
    }

    private recurrenceOptions = () => {
        let options: iRadioData[] = [
            {
                value: Recurrence.daily,
                checked: false,
                text: "daily",
                onChange: () => this.setState({ recurrence: Recurrence.daily })
            },
            {
                value: Recurrence.weekly,
                checked: false,
                text: "weekly",
                onChange: () => this.setState({ recurrence: Recurrence.weekly })
            },
            {
                value: Recurrence.monthly,
                checked: false,
                text: "monthly",
                onChange: () => this.setState({ recurrence: Recurrence.monthly })
            },
        ]

        return (
            <DynamicRadioInput value={options} name="recurrence" error={false} />
        )
    }

    private deleteRecurringEvent = (e: any) => {
        CalendarServiceProvider.deleteReservation(e.currentTarget.getAttribute("data-resid")).then((res: any) => {
            this.getReservations()
        })
    }

    private existingRecurringEvents = () => {
        return this.state.recurringRes.map((res: any) => {            
            let startDate = new Date(res.start_time + ":00.000Z");
            if(this.state.startTime.getDate() === startDate.getDate()){
                let endDate = new Date(res.end_time + ":00.000Z");
                let startTime = startDate.getHours().toString().padStart(2, "0") + ":" + startDate.getMinutes().toString().padStart(2, "0");
                let endTime = endDate.getHours().toString().padStart(2, "0") + ":" + endDate.getMinutes().toString().padStart(2, "0");
                return (
                    <div className="recurringEventRow">
                        <input type="time" readOnly value={startTime}></input>
                        -
                        <input type="time" readOnly value={endTime}></input>
                        <div className="imgContainer" data-resid={res.reservation_id} onClick={this.deleteRecurringEvent}>
                            <ImageLoader name="DeleteIcon" className="DeleteIcon"></ImageLoader>
                        </div>
                    </div>
                )
            }            
        })
    }

    private handleSelect = (key:any) => {
        let startTime = this.state.startTime;
        let endTime = this.state.endTime;
        startTime.setSeconds(0);
        endTime.setSeconds(0);
        startTime.setDate(startTime.getDate() + (key - startTime.getDay()));
        endTime.setDate(endTime.getDate() + (key - endTime.getDay()));

        this.setState({startTime: startTime, endTime: endTime})
    }

    public render() {
        let days = [ 0, 1, 2, 3, 4, 5, 6]
        console.log(this.state.startTime);
        return (
            this.state ?
                <div className="Container">
                    <Tabs defaultActiveKey={0} id="tabDays" onSelect={this.handleSelect}>
                        {days.map((day: number, i: number) => (
                            <Tab eventKey={day} title={<Translations value={Dictionary.sunday + i} />}>
                                <h3><Translations value={Dictionary.addRecurringEvent} /></h3>
                                {this.addTimeTemplate(day)}
                                {this.recurrenceOptions()}
                                <PromiseButton onClick={this.sendRecurringEvent} basicText={Dictionary.send} loadingText={Dictionary.SendingEmail} doneText={Dictionary.Done}></PromiseButton>
                                <h3><Translations value={Dictionary.existingRecurringEvents} /></h3>
                                <div className="recurringEventContainer">
                                    {this.existingRecurringEvents()}
                                </div>
                            </Tab>
                        ))}
                    </Tabs>
                </div> : <div />
        )
    }
}

export default RecurringEvents