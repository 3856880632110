import axios from "axios";
import iAmenity from "Models/Amenity";
import { iReservationFetcher } from "Models/Reservation";

const path = 'https://connect.smartcondomanager.com/';//process.env.NODE_ENV !== 'production' ? 'https://connect.smartcondomanager.com/' : "/";

const ManagerProvider = {
    createAmenity: async function (amenity: iAmenity) {
        let payload = {
            "property_id": amenity.amenity_id,
            "name": amenity.name,
            "description": amenity.description,
            "location": amenity.location
        }

        return axios.post(path + "api/create-amenity", payload, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch(function (error: any) {
            console.log(error);
        });
    },
    deleteAmenity: async function (id: number) {
        let payload = {
            "amenity_id": id,
        }

        return axios.post(path + "api/delete-amenity", payload, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch(function (error: any) {
            console.log(error);
        });
    },
    createRecurringEvent: async function (selectedAmenity: number, startTime: Date, endTime: Date, isMonthly: boolean, recurrence: number) {
        let payload = {
            amenity_id: selectedAmenity,
            start_time: startTime.toISOString().substring(0, 16),
            end_time: endTime.toISOString().substring(0, 16),
            recurring: {
                end_date: "2099-01-01T00:00",
                monthly: isMonthly,
                period: recurrence
            }
        }

        return axios.post(path + "api/create-reservation", payload, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch(function (error: any) {
            console.log(error);
        });
    },
    deleteRecurringEvent: async function (reservationId: number, recurrenceDate: Date) {
        let payload = {
            reservation_id: reservationId,
            recurrence_date: recurrenceDate.toISOString().substring(0, 16),
        }

        return axios.post(path + "api/remove-deleted-recurrence", payload, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch(function (error: any) {
            console.log(error);
        });
    },
    getReservations: async function (amenityId: number) {
        let payload = {
            "amenity_id": amenityId
        }
        return axios.post(path + "api/get-recurring-reservations", payload, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch(function (error: any) {
            console.log(error);
        });
    },
}

export default ManagerProvider