import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import MediaType from 'Ressources/MediaType';
import CalendarWeek from 'components/Calendar/CalendarWeek/CalendarWeek';
import { PopupType } from 'components/Calendar/CalendarWeek/CalendarWeek_PC';

interface IProps {
    selectedAmenity: number
    mediaType: MediaType
}

interface IState {
    selectedWeek: Date
}

class AmenitiesAvailabilities extends React.Component<IProps, IState> {

    state = {
        selectedWeek: new Date()
    }

    private changeWeek = (advance: boolean) => {
        let newWeek = new Date(this.state.selectedWeek);
        newWeek.setDate(advance ? newWeek.getDate() + 7 : newWeek.getDate() - 7);

        this.setState({ selectedWeek: newWeek });
    }

    public render() {
        return (
            <div className="CalendarContainer">
                <div className="CalendarWeek">
                    <React.Fragment>
                        <ImageLoader name="ArrowLeft" className="arrowIcon" onClick={() => { this.changeWeek(false) }}></ImageLoader>
                        <CalendarWeek popupContent={PopupType.AVAILABILITY} timeInterval={15} {...this.props} selectedWeek={this.state.selectedWeek}></CalendarWeek>
                        <ImageLoader name="ArrowRight" className="arrowIcon" onClick={() => { this.changeWeek(true) }}></ImageLoader>
                    </React.Fragment>
                </div>
            </div>
        )
    }
}

export default AmenitiesAvailabilities