import * as React from 'react';
import "./Header.scss"
import Activities from '../../Ressources/Activities';
import SettingsContext, { Languages } from '../SettingsContext';
import ImageLoader, { Extensions } from '../../Ressources/Images/ImageLoader';
import SignupServiceProvider from 'components/Login/signupForm/SignupServiceProvider';
import SyndicateSelector from 'GlobalComponents/SyndicateSelector';
import iCondo from 'Models/Condo';
import MediaType from 'Ressources/MediaType';
import Header_PC from './Header_PC';
import Header_Phone from './Header_Phone';
import { Buffer } from 'buffer';


interface IProps {
    changeActivity: Function
    condoList: iCondo[]
    selectedProperty: iCondo
    changeSelectedProperty: Function
    mediaType: MediaType
    closeNavigationMenu: any
}

interface IState {
    picture: any
    condoPicture: any
}

class Header extends React.Component<IProps, IState> {

    state = {
        picture: "",
        condoPicture: ""
    }

    getPicture = () => {
        return (
            <img src={"data:image/jpeg;base64," + this.state.picture} alt="No picture" className="ProfilePic"></img>
        )
    }

    componentDidMount = () => {
        let username = localStorage.getItem('username');
        SignupServiceProvider.getPictureEmail(username ?? "").then((e: any) => {
            if (e) {
                const buf = Buffer.from(e.data, 'binary').toString('base64');
                this.setState({ picture: buf })
            }
        })

        if (this.props.selectedProperty) {
            SignupServiceProvider.getPictureProperty(this.props.selectedProperty.name).then((e: any) => {
                if (e) {
                    const buf = Buffer.from(e.data, 'binary').toString('base64');
                    this.setState({ condoPicture: buf })
                }
            })
        }
    }

    componentDidUpdate = (props: IProps) => {
        if (this.props.selectedProperty && this.props.selectedProperty !== props.selectedProperty) {
            SignupServiceProvider.getPictureProperty(this.props.selectedProperty.name).then((e: any) => {
                if (e) {
                    const buf = Buffer.from(e.data, 'binary').toString('base64');
                    this.setState({ condoPicture: buf })
                }
            })
        }
    }

    public render() {
        if (this.props.mediaType === MediaType.PC) {
            return (
                <Header_PC getPicture={this.getPicture} {...this.props} {...this.state} />
            )
        }
        else if (this.props.mediaType === MediaType.Phone || this.props.mediaType === MediaType.Tablet) {
            return (
                <Header_Phone changeActivity={this.props.changeActivity} getPicture={this.getPicture} />
            )
        }
    }
}

export default Header

Header.contextType = SettingsContext;