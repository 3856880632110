import iCondo from 'Models/Condo';
import * as React from 'react';
import "../../SyndicatePage.scss"
import MediaType from 'Ressources/MediaType';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import ImageLoader from 'Ressources/Images/ImageLoader';
import DynamicInput, { Size } from 'GlobalComponents/Inputs/DynamicInput';
import CalendarServiceProvider from 'components/Calendar/CalendarServiceProvider';
import ManagerProvider from 'components/ManagerTools/ManagerProvider';
import PromiseButton from 'GlobalComponents/Buttons/PromiseButton';
import iAmenity from 'Models/Amenity';
import AmenitiesAvailabilities from './Availabilities/AmenitiesAvailabilities';

interface IProps {
    handleChange: any
    changeState: any
    currentCondo: iCondo
    mediaType: MediaType
}

interface IState {
    desc: string,
    name: string,
    loc: string,
    newAmenityName: string,
    locations: any[]
    modifyAvailability: boolean
    selectedAmenity: number
}

class createAmenities extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            desc: "",
            name: "",
            loc: "",
            newAmenityName: "",
            locations: [],
            modifyAvailability: false,
            selectedAmenity: -1
        }
    }

    componentDidMount() {
        if (this.props.currentCondo && this.props.currentCondo.id !== -1) {
            this.getAmenities();
        }
    }

    componentDidUpdate(props: IProps) {
        if (this.props.currentCondo && this.props.currentCondo.id !== -1 && this.props.currentCondo !== props.currentCondo) {
            this.getAmenities();
        }
    }

    private createAmenity = () => {
        let amenity: iAmenity = { amenity_id: this.props.currentCondo.id, description: this.state.desc, location: this.state.loc, name: this.state.name }
        ManagerProvider.createAmenity(amenity).then((res: any) => {
            this.getAmenities();
        })
    }

    private deleteAmenity = (amenityId: number) => {
        ManagerProvider.deleteAmenity(amenityId).then((res: any) => {
            this.getAmenities();
        })
    }

    private getAmenities = () => {
        CalendarServiceProvider.getPropertyAmenities(this.props.currentCondo.id).then((res: any) => {
            let loc = res.data != undefined ? res.data.amenities : [];
            this.setState({ locations: loc });
        })
    }

    private drawLocations = () => {
        if (this.state) {
            return this.state.locations.map((loc: any) => {
                return (
                    <React.Fragment>
                        <div className="Amenity" key={"row" + loc.amenity_id}>
                            <span style={{ width: "90%", fontSize: "30px", overflow: "hidden", textOverflow: "ellipsis" }}>{loc.name}</span>
                            <div className="BtnsContainer">
                                <ImageLoader name="ClockIcon" className="AmenityBtn" onClick={() => this.setState({ modifyAvailability: true, selectedAmenity: loc.amenity_id })} />
                                <ImageLoader name="DeleteIcon" className="AmenityBtn" onClick={() => this.deleteAmenity(loc.amenity_id)} />
                            </div>
                        </div>
                        <div className="horizontalDivider full"></div>
                    </React.Fragment>
                )
            });
        }
    }

    private drawAmenities = () => {
        return (
            <React.Fragment>
                <span>Manage amenities</span>
                {/* <span><Translations value={Dictionary.inviteUsersJoinSyndicate} var={[this.props.currentCondo.name]}></Translations></span> */}
                <div className="horizontalDivider full"></div>
                <div className="SubContainer">
                    <div className="title">
                        <ImageLoader name="GenericCondoIcon0" className="individualIcon"></ImageLoader>
                    </div>
                    <div className="InputContainer" style={{ flexDirection: "row", justifyContent: "center" }}>
                        <div className="AmenityListContainer">
                            {this.drawLocations()}
                        </div>
                        <div className='verticalDivider full'></div>
                        <div className='CreateAmenityContainer'>
                            <span>Create a new amenity</span>
                            <DynamicInput inputName={Dictionary.Name} type="text" handleChange={(e: any) => { this.setState({ name: e.target.value }) }} value={this.state.name} inputStateName={"name"} size={Size.medium} />
                            <DynamicInput inputName={Dictionary.Location} type="text" handleChange={(e: any) => { this.setState({ loc: e.target.value }) }} value={this.state.loc} inputStateName={"location"} size={Size.medium} />
                            <DynamicInput inputName={Dictionary.Description} type="text" handleChange={(e: any) => { this.setState({ desc: e.target.value }) }} value={this.state.desc} inputStateName={"description"} size={Size.medium} />
                            <PromiseButton className='CreateAmenityButton' basicText={Dictionary.CreateAmenity} loadingText={Dictionary.sending} doneText={Dictionary.created} onClick={this.createAmenity}></PromiseButton>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    private drawAvailabilities = () => {
        return (
            <div className="SubContainer" style={{ flexDirection: "row", justifyContent: "center" }}>
                <AmenitiesAvailabilities {...this.props} {...this.state}></AmenitiesAvailabilities>
            </div>
        )
    }

    public render() {
        return (
            <div className="IndividualInviteContainer">
                <React.Fragment>
                    {this.state.modifyAvailability ?
                        this.drawAvailabilities() :
                        this.drawAmenities()}
                </React.Fragment>
            </div>
        )
    }
}

export default createAmenities