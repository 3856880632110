import iPageStates from 'Models/PageStates';
import React from 'react';
import MediaType from 'Ressources/MediaType';
import './App.css';
import ActivityContainer from './components/ActivityContainer';
import SettingsContext from './GlobalComponents/SettingsContext';
import { Languages } from './GlobalComponents/SettingsContext'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import UserSignupForm from 'components/Login/signupForm/UserSignupForm';
import Login from 'components/Login/Login';

interface IProps { }

interface IState {
    language: Languages
    toggleLanguage: Function
    mediaType: MediaType
}


class App extends React.Component<IProps, IState> {

    public toggleLanguage = (val: Languages) => {
        this.setState({ language: val })
    }

    constructor(props: IProps) {
        super(props);

        if (localStorage.getItem('pageStates') === null)
            localStorage.setItem('pageStates', JSON.stringify({ activityContainer: "2", syndicatePage: "" } as iPageStates))
    }

    state = {
        language: Languages.EN,
        toggleLanguage: this.toggleLanguage,
        mediaType: MediaType.PC
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
        this.getMediaType(window.innerWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    getMediaType = (width: number) => {
        if (width <= 500) {
            this.setState({ mediaType: MediaType.Phone });
        }
        else if (width <= 1080) {
            this.setState({ mediaType: MediaType.Tablet });
        }
        else {
            this.setState({ mediaType: MediaType.PC });
        }
    }

    handleWindowSizeChange = () => {
        this.getMediaType(window.innerWidth);
    };

    private exitForm = () => {
        var loc = window.location.href
        var index = loc.indexOf("/invite");
        window.location.replace(loc.substring(0, index))
    }

    componentDidCatch(error: any, errorInfo: any) {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // development build code
            console.log("Error on dev");
        } else {
            // production build code
            localStorage.clear();
        }
    }

    public render() {
        return (
            <React.StrictMode>
                <Routes>
                    <Route path='/*' element={<ActivityContainer {...this.state} />}></Route>
                    <Route path='/login' element={<ActivityContainer {...this.state} />}></Route>
                    <Route path='/invite/*' element={<UserSignupForm exitForm={this.exitForm} withUserKey />}></Route>
                    <Route path='/password-reset/*' element={<Login changeActivity={() => { }} {...this.state} resettingPassword />}></Route>
                </Routes>
            </React.StrictMode>
        );
    }
}

export default App;
