import PromiseButton from 'GlobalComponents/Buttons/PromiseButton';
import DynamicInput, { Size } from 'GlobalComponents/Inputs/DynamicInput';
import * as React from 'react';
import ImageLoader, { Extensions } from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from '../../Ressources/translations/Translations';
import "./Login.scss"

interface IProps {
    handleChange: Function
    userName: string
    password: string
    login: Function
    startSignUpForm: React.MouseEventHandler<HTMLButtonElement>
    wrongLogin: boolean
}

interface IState {
}

class Login_Phone extends React.Component<IProps, IState> {

    public render() {
        return (
            <React.Fragment>
                <div id="TopMenuContainer">
                    <div id="TopMenuContent">
                        <ImageLoader name="Logo" className="TopMenuLogo"></ImageLoader>
                    </div>
                </div>
                <div id="BodyContainer">
                    <div className="loginForm">
                        <div className="InputContainers">
                            <DynamicInput size={Size.large} inputName={Dictionary.email} handleChange={this.props.handleChange} type="email" inputStateName="userName" value={this.props.userName} />
                            <DynamicInput size={Size.large} inputName={Dictionary.password} handleChange={this.props.handleChange} type="password" inputStateName="password" value={this.props.password} />
                            <div className='errorMessageContainer'>
                                {this.props.wrongLogin ? <label className='errorMessage'>{<Translations value={Dictionary.wrongEmailPassword}></Translations>}</label> : null}
                            </div>
                        </div>
                        <PromiseButton width={"30%"} height={"30%"} className='loginButton' onClick={this.props.login} basicText={Dictionary.login} loadingText={Dictionary.send} doneText={Dictionary.login} />
                    </div>
                    <div id="SignUpContainer">
                        <h2><Translations value={Dictionary.dontHaveAnAccount} /></h2>
                        <label><Translations value={Dictionary.getStartedFast} /></label>
                        <PromiseButton width={"30%"} height={"30%"} className='loginButton' onClick={this.props.startSignUpForm} basicText={Dictionary.signUp} loadingText={Dictionary.send} doneText={Dictionary.signUp} />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Login_Phone