export default interface iTicket {
    amenity_id: string,
    assignments: any[],
    date_closed: Date,
    date_created: Date,
    date_updated: Date,
    details: string,
    files: any[],
    id: number,
    notes: string[],
    priority: string,
    property_id: number,
    status: string,
    summary: string,
    unit_id: number,
    user_id: number
}

export enum PriorityType {
    LOW,
    NORMAL,
    HIGH,
    URGENT
}

export enum Visibility {
    PRIVATE,
    PUBLIC
}

export enum Status {
    OPEN,
    ON_HOLD,
    IN_PROGRESS,
    CLOSED,
    INVALID,
}