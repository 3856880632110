import SignupServiceProvider from 'components/Login/signupForm/SignupServiceProvider'
import ImageUploader from 'GlobalComponents/ImageUploader/ImageUploader'
import React from 'react'

interface IProps {
}

interface IState {
    img: string
}

export default class ProfilePic extends React.Component<IProps, IState> {
    imgRef: React.RefObject<ImageUploader>;

    constructor(props: any) {
        super(props);
        this.imgRef = React.createRef<ImageUploader>();
    }
    state = {
        img: ""
    }

    private send = () => {
        if (this.imgRef.current != null) {
            let img = this.imgRef.current.getImage();
            SignupServiceProvider.sendImage(img).then((e: any) => {
                let imge = "data:image/jpeg;base64," + btoa(new Uint8Array(e.data).reduce((data, byte) => data + String.fromCharCode(byte), '',),);
                this.setState({ img: imge });
                if (e && e.data === "Picture set") {
                    console.log("set");
                }
            })
        }
    }

    public render() {
        return (
            <div>
                <div>
                    <ImageUploader ref={this.imgRef} lastPic={this.state.img}></ImageUploader>
                </div>
                <div>
                    <button onClick={this.send}>SEND</button>
                </div>
                <img src={this.state.img}></img>
            </div>
        )
    }
}