import iCondo from 'Models/Condo';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from '../../../../Ressources/translations/Translations';
import { pageStates } from '../../SyndicatePage';

interface IProps {
    changeState: any
    currentCondo: iCondo
    renderAdmins: Function
}

interface IState {
}

class SyndicateAdministratorsList_PC extends React.Component<IProps, IState> {

    public render() {
        return (
            <div className="subContainer">
                <div className="options big">
                    <span><Translations value={Dictionary.inviteUsersJoinSyndicate} var={[this.props.currentCondo.name]}></Translations></span>
                    <div className="horizontalDivider full"></div>
                    <div className="subOptions">
                        <div className="options input">
                            <div className="title">
                                <ImageLoader name="EditUserIcon" className="icon"></ImageLoader>
                                <span><Translations value={Dictionary.editAdminList} var={[this.props.currentCondo.name]}></Translations></span>
                            </div>
                            <div className="usersContainer">
                                {this.props.renderAdmins()}
                            </div>
                        </div>
                        <div className="verticalDivider"></div>
                        <div className="options navigation">
                            <div className="option clickable" onClick={() => this.props.changeState(pageStates.addEmployee)}>
                                <ImageLoader name="AddEmployeeIcon" className="icon"></ImageLoader>
                                <span><Translations value={Dictionary.addEmployee}></Translations></span>
                            </div>
                            <div className="horizontalDivider"></div>
                            <div className="option clickable" onClick={() => this.props.changeState(pageStates.manageListEmployees)}>
                                <ImageLoader name="EmployeeListIcon" className="icon"></ImageLoader>
                                <span><Translations value={Dictionary.manageListEmployees}></Translations></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SyndicateAdministratorsList_PC