import iTicket from 'Models/Ticket';
import * as React from 'react';

export interface ITicketProps {
    ticket: iTicket
    EditTicket: Function
}

export interface ITicketState {
}

export default class Ticket extends React.Component<ITicketProps, ITicketState> {
    constructor(props: ITicketProps) {
        super(props);

        this.state = {
        }
    }

    public render() {
        return (
            <div className='TicketContainer' onClick={() => this.props.EditTicket(this.props.ticket)}>
                <div>{this.props.ticket.details}</div>
            </div>
        );
    }
}
