enum Activities {
    Home,
    Calendar,
    Login,
    Syndicate,
    CreateSyndicate,
    Management,
    Accounting,
    Reservations,
    Documents,
    Communications,
    HelpDesk,
}

export default Activities