import * as React from 'react';
import MediaType from 'Ressources/MediaType';
import { Dictionary, Translations } from 'Ressources/translations/Translations';
import SyndicateJoinSyndicate_PC from './SyndicateJoinSyndicate_PC';

interface IProps {
    changeState: Function
    handleChange: Function
    syndicateKey: string
    mediaType: MediaType
}

interface IState {
}

class SyndicateJoinSyndicate extends React.Component<IProps, IState> {

    private sendSyndicateKey = () => {
        // return SyndicateServiceProvider.sendMassEmail(this.props.currentCondo.id, [this.props.email]).then((res: any) => {
        //     console.log(res);
        // })
        return Promise.resolve();
    }

    public render() {
        if (this.props.mediaType === MediaType.Phone) {
            return (
                <SyndicateJoinSyndicate_PC sendSyndicateKey={this.sendSyndicateKey} {...this.props}></SyndicateJoinSyndicate_PC>
            )
        }
        if (this.props.mediaType === MediaType.PC || this.props.mediaType === MediaType.Tablet) {
            return (
                <SyndicateJoinSyndicate_PC sendSyndicateKey={this.sendSyndicateKey} {...this.props}></SyndicateJoinSyndicate_PC>
            )

        }
    }
}

export default SyndicateJoinSyndicate