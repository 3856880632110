let translationFR: any = [
    "Cet email est déjà utilisé",
    "Mot de passe invalide",
    "Impossible de créer votre compte, veuillez patienter une minute et réessayer",
    "Le mot de passe doit comporter au moins 8 caractères",
    "Le mot de passe doit contenir un nombre",
    "Le mot de passe doit contenir un caractère spécial",
    "Téléphone invalide. Doit être composé de 10 à 20 chiffres",
    "Un seul fichier peut être téléchargé à la fois",
    "Seuls les fichiers Excel sont autorisés",
    "Ce nom de syndicat existe déjà",
]

export default translationFR