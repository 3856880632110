import * as React from 'react';
import Activities from '../../Ressources/Activities';
import LoginProvider from '../../GlobalComponents/ServiceProvider';
import UserSignupForm from './signupForm/UserSignupForm';
import { Translations, Dictionary } from '../../Ressources/translations/Translations';
import SettingsContext from '../../GlobalComponents/SettingsContext';
import MediaType from 'Ressources/MediaType';
import Login_PC from './Login_PC';
import Login_Phone from './Login_Phone_Tablet';

interface IProps {
    changeActivity: Function
    mediaType: MediaType
    resettingPassword?: boolean
}

interface IState {
    startSignUpForm: boolean,
    userName: string,
    password: string,
    logged: boolean,
    wrongLogin: boolean,
    token: string
}

class Login extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        let token = "";
        if (props.resettingPassword) {
            token = window.location.pathname.replace("/password-reset/", '');
        }

        this.state = {
            startSignUpForm: false,
            logged: false,
            userName: "",
            password: "",
            wrongLogin: false,
            token: token
        }
    }

    componentDidMount = () => {
        var email = localStorage.getItem('username');
        var pass = localStorage.getItem('password');
        if (email !== null && pass !== null) {
            this.setState({ userName: email, password: pass }, this.login);
        }
        document.addEventListener("keyup", this.enterKeyLogin);
    }

    private startSignUpForm = () => {
        this.setState({ startSignUpForm: !this.state.startSignUpForm })
        return Promise.resolve(0)
    }

    private exitSignUpForm = (completed: boolean) => {
        this.setState({ startSignUpForm: !this.state.startSignUpForm })
        if (completed)
            this.props.changeActivity(Activities.Home)
    }

    private enterKeyLogin = (event: KeyboardEvent) => {
        if (event.key == "Enter") {
            this.login();
        }
    }

    private login = () => {
        LoginProvider.Login(this.state.userName, this.state.password).then((successfull: any) => {
            if (successfull) {
                this.props.changeActivity(Activities.Home);
            } else {
                this.setState({ wrongLogin: true });
            }
        })
    }

    private handleChange = (event: any) => {
        const { target: { name, value } } = event
        this.setState({ [name]: value, wrongLogin: false } as Pick<IState, keyof IState>);
    }

    private landingContent = () => {
        if (this.props.mediaType === MediaType.PC) {
            return (
                <Login_PC handleChange={this.handleChange} {...this.state} {...this.props}
                    login={this.login} startSignUpForm={this.startSignUpForm} />
            )
        }
        else if (this.props.mediaType === MediaType.Phone || this.props.mediaType === MediaType.Tablet) {
            return (
                <Login_Phone handleChange={this.handleChange} userName={this.state.userName} wrongLogin={this.state.wrongLogin}
                    password={this.state.password} login={this.login} startSignUpForm={this.startSignUpForm} />
            )
        }

    }

    public render() {
        return (
            !this.state.startSignUpForm ?
                this.landingContent() :
                <UserSignupForm exitForm={this.exitSignUpForm}></UserSignupForm>
        )
    }
}

export default Login

Login.contextType = SettingsContext;