import * as React from 'react';


export enum Extensions {
    PNG = ".png",
    SVG = ".svg",
}

interface IProps {
    name: string
    extension?: Extensions
    className: string
    onClick?: React.MouseEventHandler<HTMLImageElement>
}

interface IState {
}

class ImageLoader extends React.Component<IProps, IState> {

    public render() {
        var ext = this.props.extension ? this.props.extension : Extensions.SVG;
        var image = require("./" + this.props.name + ext);
        var click = this.props.onClick ? this.props.onClick : () => { };

        return (
            <img src={image} alt={this.props.name} className={this.props.className} onClick={click}></img>
        )
    }
}

export default ImageLoader