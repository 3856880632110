import iPost from 'Models/Post';
import * as React from 'react';
import TicketLeaveCommentSection from './TicketLeaveCommentSection';
import TicketPost from './TicketPost';
import TicketProvider from './TicketProvider';

export interface ITicketCommentsLoaderProps {
    ticketId: number
}

export interface ITicketCommentsLoaderState {
    posts: iPost[]
}

export default class TicketCommentsLoader extends React.Component<ITicketCommentsLoaderProps, ITicketCommentsLoaderState> {
    constructor(props: ITicketCommentsLoaderProps) {
        super(props);

        this.state = {
            posts: []
        }
    }

    componentDidMount(): void {
        this.GetPosts();
    }

    private GetPosts = () => {
        TicketProvider.GetPostTicket(this.props.ticketId).then((res: any) => {
            this.setState({ posts: res.data.posts });
        });
    }

    private renderPosts = () => {
        let posts: JSX.Element[] = [];
        this.state.posts.map((post: iPost) => {
            posts.push(<TicketPost post={post} />)
        })
        return posts;
    }

    public render() {
        return (
            <div>
                <div className="PostsContainer">
                    {this.renderPosts()}
                </div>
                <TicketLeaveCommentSection {...this.props} GetPosts={this.GetPosts} />
            </div>
        );
    }
}
