import iCondo from 'Models/Condo';
import PageStates from 'Models/PageStates';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import MediaType from 'Ressources/MediaType';
import "./BaseOptions.scss"
import { pageStates } from '../SyndicatePage';
import BaseOptions_PC from './BaseOptions_PC';
import BaseOptions_Phone from './BaseOptions_Phone';

interface IProps {
    changeActivity: Function
    condoList: iCondo[]
    mediaType: MediaType
    yourSyndicateClicked: Function
    changeState: Function
}

interface IState {
}

class BaseOptions extends React.Component<IProps, IState> {

    private condoItem = (condo: iCondo, index: number, imgCounter: number) => {
        return (
            <React.Fragment>
                <ImageLoader name={"GenericCondoIcon" + imgCounter} className="icon_condo"></ImageLoader>
                <div className="condo_desc_container">
                    <u>{index + ". " + condo.name}</u>
                    {/* <label>{condo.address}</label> */}
                </div>
            </React.Fragment>
        )
    }

    public render() {
        if (this.props.mediaType === MediaType.Phone) {
            return (
                <BaseOptions_Phone condoItem={this.condoItem} {...this.props}></BaseOptions_Phone>
            )
        }
        if (this.props.mediaType === MediaType.PC || this.props.mediaType === MediaType.Tablet) {
            return (
                <BaseOptions_PC condoItem={this.condoItem} {...this.props}></BaseOptions_PC>
            )

        }
    }
}

export default BaseOptions