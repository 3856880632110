import AdminInfo from 'components/Syndicate/sub-components/AdminInfo';
import { pageStates } from 'components/Syndicate/SyndicatePage';
import SyndicateServiceProvider, { USER_TYPES } from 'components/Syndicate/SyndicateServiceProvider';
import ArrowButton from 'GlobalComponents/Buttons/ArrowButton';
import iCondo from 'Models/Condo';
import { iAdmin } from 'Models/User';
import * as React from 'react';
import { Dictionary } from 'Ressources/translations/Translations';
import UsersTable from './UsersTable';

export interface IManageUsersProps {
    currentCondo: iCondo
    changeState: Function
}

export interface IManageUsersState {
    admins: iAdmin[]
    residents: iAdmin[]
    employees: iAdmin[]
}

export default class ManageUsers extends React.Component<IManageUsersProps, IManageUsersState> {
    constructor(props: IManageUsersProps) {
        super(props);

        this.state = {
            admins: [],
            residents: [],
            employees: []
        }


    }

    componentDidMount = () => {
        SyndicateServiceProvider.GetPropertyUsers(this.props.currentCondo.id, USER_TYPES.residents).then((res: any) => {
            console.log(res);
            this.setState({ residents: res.data.residents as Array<iAdmin> })
        });
        SyndicateServiceProvider.GetPropertyUsers(this.props.currentCondo.id, USER_TYPES.admins).then((res: any) => {
            console.log(res);
            this.setState({ admins: res.data.admins as Array<iAdmin> })
        });
        SyndicateServiceProvider.GetPropertyUsers(this.props.currentCondo.id, USER_TYPES.employees).then((res: any) => {
            console.log(res);
            this.setState({ employees: res.data.employees as Array<iAdmin> })
        });
    }

    public render() {

        return (
            <React.Fragment>
                <UsersTable {...this.state}></UsersTable>
            </React.Fragment>
        );
    }
}
