import iCondo from 'Models/Condo';
import * as React from 'react';
import MediaType from 'Ressources/MediaType';
import "../../SyndicatePage.scss"
import SyndicateInviteOptions_PC from './SyndicateInviteOptions_PC';
import SyndicateInviteOptions_Phone from './SyndicateInviteOptions_Phone';

interface IProps {
    changeState: any
    currentCondo: iCondo
    mediaType: MediaType
}

interface IState {
}

class SyndicateInviteOptions extends React.Component<IProps, IState> {

    public render() {
        if (this.props.mediaType === MediaType.Phone) {
            return (
                <SyndicateInviteOptions_Phone {...this.props}></SyndicateInviteOptions_Phone>
            )
        }
        if (this.props.mediaType === MediaType.PC || this.props.mediaType === MediaType.Tablet) {
            return (
                <SyndicateInviteOptions_PC {...this.props}></SyndicateInviteOptions_PC>
            )

        }
    }
}

export default SyndicateInviteOptions