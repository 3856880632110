let translationEN: any = [
    "Email is already in use",
    "Password is invalid",
    "Could not create your account, please wait a minute and try again",
    "Password must be at least 8 characters long",
    "Password must contain a number",
    "Password must contain a special character",
    "Phone invalid. Must be composed of 10 to 20 digits",
    "Only one file can be uploaded at a time",
    "Only Excel files are allowed here",
    "This syndicate name already exists",

]

export default translationEN