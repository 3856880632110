import PromiseButton from 'GlobalComponents/Buttons/PromiseButton';
import DynamicInput from 'GlobalComponents/Inputs/DynamicInput';
import { Size } from 'GlobalComponents/Inputs/DynamicRadioInput';
import iCondo from 'Models/Condo';
import * as React from 'react';
import ImageLoader from 'Ressources/Images/ImageLoader';
import { Dictionary, Translations } from '../../../../Ressources/translations/Translations';
import { pageStates } from '../../SyndicatePage';
import SyndicateServiceProvider from '../../SyndicateServiceProvider';

interface IProps {
    handleChange: any
    changeState: any
    email: string
    currentCondo: iCondo
    sendInvite: Function
}

interface IState {
}

class SyndicateIndividualInvite_Phone extends React.Component<IProps, IState> {

    public render() {
        return (
            <div className="subContainer">
                <div className="options">
                    <span><Translations value={Dictionary.inviteUsersJoinSyndicate} var={[this.props.currentCondo.name]}></Translations></span>
                    <div className="horizontalDivider"></div>
                    <div className="options big">
                        <div className="options input">
                            <div className="title">
                                <ImageLoader name="IndividualInviteIcon" className="individualIcon"></ImageLoader>
                                <span><Translations value={Dictionary.individualInvite}></Translations></span>
                            </div>
                            <div className="inputContainer">
                                <DynamicInput inputName={Dictionary.EnterTheEmailHere} size={Size.large} type="email" handleChange={this.props.handleChange} value={this.props.email} inputStateName={"email"}></DynamicInput>
                                <PromiseButton onClick={this.props.sendInvite} basicText={Dictionary.send} loadingText={Dictionary.SendingEmail} doneText={Dictionary.Done}></PromiseButton>
                            </div>
                        </div>
                        <div className="horizontalDivider"></div>
                        <div className="subOptions small navigation">
                            <div className="option clickable" onClick={() => this.props.changeState(pageStates.groupInvite)}>
                                <ImageLoader name="GroupInviteIcon" className="icon"></ImageLoader>
                                <span><Translations value={Dictionary.groupInvite}></Translations></span>
                            </div>
                            <div className="verticalDivider"></div>
                            <div className="option clickable" onClick={() => this.props.changeState(pageStates.shareKey)}>
                                <ImageLoader name="GiveKeyIcon" className="icon"></ImageLoader>
                                <span><Translations value={Dictionary.shareSyndicateAccessKey}></Translations></span>
                            </div>
                            <div className="verticalDivider"></div>
                            <div className="option clickable" onClick={() => this.props.changeState(pageStates.manageListCoowners)}>
                                <ImageLoader name="PersonListIcon" className="icon"></ImageLoader>
                                <span><Translations value={Dictionary.manageListCoowners}></Translations></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SyndicateIndividualInvite_Phone