import axios from "axios";
import LoginProvider from "GlobalComponents/ServiceProvider";

export default class TicketProvider extends LoginProvider {

    static async CreateTicket(propertyId: number, summary: string, priority: string, details: string) {
        let res: any = await axios.post(this.path + 'api/create-ticket', {
            property_id: propertyId,
            summary: summary,
            details: details,
            priority: priority
        }, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
    }

    static async GetTickets(propertyId: number, createStart: Date | string,
        createEnd: Date | string, updateStart: Date | string,
        updateEnd: Date | string, closedStart: Date | string,
        closedEnd: Date | string, priorities: string[],
        amenityId?: number) {
        let res: any = await axios.post(this.path + 'api/get-tickets', {
            property_id: propertyId,
            create_start: createStart,
            create_end: createEnd,
            update_start: updateStart,
            update_end: updateEnd,
            closed_start: closedStart,
            closed_end: closedEnd,
            priorities: priorities,
            amenity_id: amenityId
        }, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
    }

    static async EditTicket(ticketId: number, priority?: string, status?: string) {
        let res: any = await axios.post(this.path + 'api/update-ticket', {
            ticket_id: ticketId,
            priority: priority,
            status: status
        }, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
    }

    static async AssignTicket(ticketId: number, userIds: number[]) {
        let res: any = await axios.post(this.path + 'api/edit-assignments', {
            ticket_id: ticketId,
            user_ids: userIds,
        }, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
    }

    static async GetTicketAssignments(ticketId: number) {
        let res: any = await axios.post(this.path + 'api/get-assignments', {
            ticket_id: ticketId,
        }, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
    }

    static async CreatePostTicket(ticketId: number, content: string) {
        let res: any = await axios.post(this.path + 'api/create-post', {
            ticket_id: ticketId,
            content: content,
            property_id: 3,
        }, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
    }

    static async GetPostTicket(ticketId: number) {
        let res: any = await axios.post(this.path + 'api/get-posts', {
            ticket_id: ticketId,
        }, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
    }
}