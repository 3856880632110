import axios from "axios";
import LoginProvider from "GlobalComponents/ServiceProvider";

export default class UnitsProvider extends LoginProvider {
    static async GetUnits(propertyId: number) {
        let res: any = await axios.post(this.path + 'api/get-user-units', {
            property_id: propertyId,
        }, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
    }

    static async CreateUnit(propertyId: number, number: string, votingPercent: number) {
        let res: any = await axios.post(this.path + 'api/create-unit', {
            property_id: propertyId,
            number: number,
            voting_percent: votingPercent
        }, {
            withCredentials: false,
            headers: {
                'Cache-Control': "no-store",
                "Authorization": `Bearer ` + localStorage.getItem('token'),
            }
        }).catch((res: any) => {
            return { successfull: false };
        });

        if (res && res.status == 200) {
            return { successfull: true, data: res.data };
        }
    }
}